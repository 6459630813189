<template >
  <div>
    <br>
    <div v-if="isRender">
      <vs-navbar :collapse="false" v-model="active" class="fixed-top menu-static"
                 ref="parentSidebar"
                 text-color="rgba(255,255,255,.6)"
                 active-text-color="rgba(255,255,255,1)"
                 :color="`#8CC152`" >

        <div slot="title">
          <vs-navbar-title>
            <vs-row vs-justify="center">
              <vs-col  vs-justify="center" vs-align="center" vs-lg="6" vs-sm="6" vs-xs="6">
                <div style="margin-top: 6px">
                  <vs-select
                      autocomplete
                      class="selectExample"
                      v-model="code"
                      style="width: 250px"
                  >
                    <vs-select-item :key="index" :value="item.value" :text="item.text"
                                    v-for="(item,index) in codeList"/>
                  </vs-select>
                </div>
              </vs-col>
              <!--
                                            <vs-col  vs-justify="center" vs-align="center" align="center" vs-lg="6" vs-sm="6" vs-xs="6">
                                                <div style="margin-top: 6px">
                                                    <img src="/img/aib_logo.png" style="height: 35%; width: 35%"   />
                                                </div>
                                            </vs-col>
              -->
            </vs-row>
          </vs-navbar-title>
        </div>

        <!--
                <a href="#star">
                  test</a>
        -->
        <vs-navbar-item index="0">
          <a href="#">통합 분석</a>
        </vs-navbar-item>


        <vs-navbar-item index="1">
          <a href="#balance">
            Balance Summary</a>
        </vs-navbar-item>

        <vs-navbar-item index="2">
          <a href="#species">종분석</a>
        </vs-navbar-item>

        <vs-navbar-item index="3">
          <a href="#phylum">Phylum 분석</a>
        </vs-navbar-item>

        <vs-navbar-item index="4">
          <a href="#age">나이별 다양성 분석</a>
        </vs-navbar-item>

        <vs-navbar-item index="7" >
          <router-link to="/lacto">상세 분석</router-link>
        </vs-navbar-item>

        <vs-navbar-item index="5" v-if="showUserSetting">
          <router-link to="/scoring">Scoring Table</router-link>
        </vs-navbar-item>

        <vs-navbar-item index="6" v-if="showUserSetting" >
          <router-link to="/users">사용자 관리</router-link>
        </vs-navbar-item>

        <vs-navbar-item index="7">
          <a href="/v1/logout">로그아웃</a>
        </vs-navbar-item>
        <vs-spacer></vs-spacer>
      </vs-navbar>
    <br>
      <aib></aib>
      <br><br><br><br><br><br>
      <v-row>
        <v-col cols="12" >
          <v-row align="center" justify="center" >
          </v-row>
          <v-row align="center" justify="center" >
            <v-card
                class="mx-auto"
                max-width="200"
                width="150"
                tile
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-6 font-weight-bold dash-font" style="text-align : left;font-size: 15px!important;">Total</div>
                  <v-list-item-subtitle class="dash-font" style="text-align: right; color: #000; opacity: 0.8; font-size: 3rem;font-weight: 900">{{ aibTotal.avg }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
            <v-card
                class="mx-auto"
                max-width="200"
                width="150"
                tile
            >
              <v-list-item three-line>
                <v-list-item-content>
                  <div class="overline mb-6 font-weight-bold dash-font" style="text-align : left;font-size: 15px !important;">{{code}}</div>
                  <v-list-item-subtitle class="dash-font" style="text-align: right; color: #000; opacity: 0.8; font-size: 3rem;font-weight: 900">{{ aibTotal.user }}</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-card>
          </v-row>
        </v-col>
      </v-row>

      <br><br>
      <vs-row class="mh" align="center" justify="space-around" direction="column" vs-w="12">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center"  w="6">
          <v-chart :options="microbesPieChartData.avg"  theme='shine' />
          <v-chart :options="microbesPieChartData.user"  theme='shine' />
        </vs-col>
      </vs-row>
      <a id="balance"></a>
      <br><hr>
      <v-container>
        <v-row>
          <v-col cols="12" class="ml-3">
            <v-row align="center" justify="center" style="position:absolute;top:80px;left:46%;transform: translate(-50%, -50%)">
              <h4><b>Balance Index</b></h4>
            </v-row>
          </v-col>
        </v-row>
        <v-row>
          <balance></balance>
        </v-row>
      </v-container>

      <growth ref="growth" :code="code" ></growth>

      <a id="species"></a>
      <species ref="species" :is-home="true" ></species>

      <a id="phylum"></a>
      <phylum ref="phylum" :code="code" is-dash></phylum>

      <div id="age"></div>
      <by-age ref="diversity" :code="code" ></by-age>

      <fab
          :position="'bottom-right'"
          :bg-color="'#0f4299'"
          :actions="fabActions"
          :main-icon="'menu_open'"
          main-tooltip="report menu"
          @sync="sync"
          @inputScore="inputScore"
          @doctorComment="comment"
          @report="report"
      >
      </fab>
      <vs-row class="mh" justify="space-around" direction="column" vs-w="12">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center"  w="5">
          <div style="width:1040px;height:600px;bottom:251px;padding:40px;border:1px solid #ccc;">
            <div id="comment"></div>
            <h3><b>Comment</b></h3>
            종합 의견을 작성해 주세요.(리포트 생성시 필수)
            <hr class="hr1">
            <editor
                ref="doctorEditor"
                :initialValue="editorText"
                :options="editorOptions"
                height="400px"
                initialEditType="wysiwyg"
                previewStyle="tab"
            />
            <div style="margin-top:10px">
              <v-btn
                  :loading="loading"
                  :disabled="loading"
                  color="rgb(140, 193, 82)"
                  @click="saveDoctorComment"
              >
                save
                <v-icon right dark
                >
                  mdi-content-save
                </v-icon>
              </v-btn>
              <v-tooltip
                  v-model="show"
                  top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                  >
                  </v-btn>
                </template>
                <span>{{message}}</span>
              </v-tooltip>
            </div>
          </div>
        </vs-col>
      </vs-row>
      <div style="height:100px;">
      </div>
      <vs-row class="mh" justify="space-around" direction="column" vs-w="12">
        <vs-col vs-type="flex" vs-justify="center" vs-align="center"  w="5">
          <div style="width:1040px;height:600px;bottom:251px;padding:40px;border:1px solid #ccc;">
            <h3><b>Summary</b></h3>
            summary를 작성해 주세요.(리포트 생성시 필수)
            <hr class="hr1">
            <editor
                ref="summaryEditor"
                :initialValue="summaryText"
                :options="editorOptions"
                height="400px"
                initialEditType="wysiwyg"
                previewStyle="tab"
            />
            <div style="margin-top:10px">
              <v-btn
                  :loading="loadingSum"
                  :disabled="loadingSum"
                  color="rgb(140, 193, 82)"
                  @click="saveSummary"
              >
                save
                <v-icon right dark
                >
                  mdi-content-save
                </v-icon>
              </v-btn>
              <v-tooltip
                  v-model="show"
                  top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                  >
                  </v-btn>
                </template>
                <span>{{message}}</span>
              </v-tooltip>
            </div>
          </div>
        </vs-col>
      </vs-row>
    </div>
    <div v-else>
        <v-row justify="center">
          <v-dialog
              v-model="dialog"
              persistent
              max-width="380px"
          >
            <v-card>
              <v-card-title>
              </v-card-title>
              <v-card-text>
                <v-container>
                  <v-row>
                    <v-col cols="12">
                      <vs-select
                          autocomplete
                          class="selectExample"
                          v-model="code"
                          style="width: 300px"
                          label="SAMPLE ID 를 선택해 주세요.*"
                      >
                        <vs-select-item :key="index" :value="item.value" :text="item.text"
                                        v-for="(item,index) in codeList"/>
                      </vs-select>
                    </v-col>
                  </v-row>
                </v-container>
                <small>*필수 선택 입니다.</small>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
    </div>
    <div style="height:100px;">
    </div>
    <notifications group="noti"  :position="['bottom', 'center']" />
  </div>
</template>
<script>
import axios from 'axios'
import Aib from '@/components/aib'
import balance from '@/components/dash/balance'
import theme from '../theme/shine.project.json'
import species from '../components/Species.vue'
import phylum from '../components/Phylum.vue'
import growth from '../components/Growth.vue'
import byAge from '../components/Diversity.vue'
import fab from 'vue-fab'
import '../theme/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor'
import 'tui-color-picker/dist/tui-color-picker.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';
import { mapActions } from 'vuex'


export default {
  components: {
    Aib,
    balance,
    species,
    phylum,
    growth,
    byAge,
    fab,
    editor: Editor,
  },

  data() {
    return {
      dialog: false,
      isRender : false,
      show : false,
      message : "저장이 완료 되었습니다.",
      loader: null,
      loading: false,
      loadingSum: false,
      loadingSummary: false,
      editorText: this.$store.state.sample.comment,
      summaryText: this.$store.state.sample.summary,
      editorOptions: {
        hideModeSwitch: true,
        plugins: [[colorSyntax]],
      },
      fabActions: [
        {
          name: 'sync',
          icon: 'sync',
          tooltip:"설문조사동기화"
        },
        {
          name: 'inputScore',
          icon: 'device_thermostat',
          tooltip:"질병점수입력"
        },
        {
          name: 'doctorComment',
          icon: 'comment',
          tooltip: "Doctor’s Comment"
        },
        {
          name: 'report',
          icon: 'assignment',
          tooltip: "Report 보기"
        }
      ],
      oldCode : '',
      numberOfGenusUser : 0,  // 통합 차트, 사용자 보유 균수
      numberOfGenusAvg: 0,  // 통합 차트, 측정 보유 총 균수
      showUserSetting: false,
      active : false,
      activeItem : 0,
      isAutoResize : true,
      theme : theme,
      codeList : [],
      lastUpdate : Date(),
      tabIndex: 0,
    }
  },
  watch : {
    loader1 () {
      const l = this.loader
      this[l] = !this[l]

      setTimeout(() => {
        this[l] = false;
        alert("save")
      }, 3000)

      this.loader = null
    }
  },
  methods: {
    ...mapActions(['getSample', 'setDoctorComment', 'setSummary']),
    inputScore(){
      location.href="#score";
    },
    comment(){
      location.href="#comment";
    },
    report(){
      let html = this.$refs.doctorEditor.invoke('getHTML');
      if(html.length <= 1){
        this.callNoti("Doctor's Comment 작성 후 저장해 주세요.", "error");
        return;
      }
      console.log(" this.$store.state.report.starChart.adjustedStarScore.length ", this.$store.state.report.starChart.adjustedStarScore.length, this.$store.state.report.starChart.adjustedStarScore)

      let len = this.$store.state.report.starChart.adjustedStarScore.length;
      if(this.overlayLoading){
        this.callNoti("필수 데이터가 아직 로드 되지 않았습니다. \n 잠시 후에 다시 시도해 주세요.", "warn");
        return;
      }else {
        for( let i=0; i < len; i++){
          if(isNaN(this.$store.state.report.starChart.adjustedStarScore[i])){
            this.callNoti("필수 데이터가 아직 로드 되지 않았습니다. \n 잠시 후에 다시 시도해 주세요.", "warn");
            return;
          }
        }
      }
      this.callNoti("리포트 페이지로 이동합니다.", "success", 3000);
      this.overlayLoading = true;

      //로딩창이 실행 될 수 있도록 약간의 lazy 적용
      setTimeout(()=> {
        //나이별 리포트 페이지 분기
        let age = this.$store.state.sample.age || 0 ;
        switch(true){
          case (age <= 1):
            this.$router.push('/report_baby');
            break;
          case (age <= 8):
            this.$router.push('/report');
            break;
          case (age >= 9 && age <= 19):
            this.$router.push('/report_teen');
            break;
          default :
            this.$router.push('/report_adult');
            break;
        }
      }, 10)
    },
    async sync() {
      axios.get(`/v1/survey/sync`).then(res => {
        if(res.data.code === 200){
          this.$store.dispatch('getCodeList');
          this.$store.dispatch('getSample');
          this.callNoti("동기화 처리 완료 ");
          this.callNoti("참고) *분석데이터 등록 이후 설문조사 작성이 뒤늦게 작성된 경우만 동기화 처리");
          return ;
        }

      }).catch( e => {
        console.error(e);
        this.callNoti("동기화 처리 실패");
      })
    },
    // 닥터 의견 저장
    async saveDoctorComment(){
      this.loader = "loading";
      const l = this.loader
      this[l] = !this[l];
      this.loader = true;

      let html = this.$refs.doctorEditor.invoke('getHTML');
      if(html === null || html === undefined || html.length <= 1) {
        this.callNoti("입력한 데이터가 없습니다.");
        this.loader = false;
        this[l] = false;
        return;
      }
      await this.setDoctorComment(html);

      this.callNoti("저장 되었습니다.");

      this.loader = false;
      this[l] = false;

    },
    async saveSummary(){
      this.loaderSum = "loading";
      const l = this.loaderSum
      this[l] = !this[l];
      this.loaderSum = true;

      let html = this.$refs.summaryEditor.invoke('getHTML');
      if(html === null || html === undefined || html.length <= 1) {
        this.callNoti("입력한 데이터가 없습니다.");
        this.loaderSum = false;
        this[l] = false;
        return;
      }
      await this.setSummary(html);

      this.callNoti("저장 되었습니다.");

      this.loader = false;
      this[l] = false;

    },
    callNoti(msg, level){

      this.$notify({
        type: level,
        group: 'noti',
        title: '알림',
        text: msg
      });

    },
    linkClass(idx) {
      if (this.tabIndex === idx) {
        return ['bg-primary', 'text-light']
      } else {
        return ['bg-light', 'text-info']
      }
    },
    init() {
      this.totUsers = 0;
      this.totTvUsers = 0;
      this.totSportsUsers = 0;
    },
    getCodeList(){
      this.$store.commit('setOverlayLoading', true);
      axios.get('/v1/code').then( res => {
        this.$store.commit('setOverlayLoading', false);
        if(res.data.code === 200) {
          let data = res.data.data;
          data.forEach((ele) => {
            this.codeList.push({text: `${ele.code}/${ele.flag}/${ele.name}`, value: ele.code })
          })
          this.$store.state.codeList = this.codeList;
        }
      }).catch( e => {
        console.error(e);
      })
    },
    allComponentsRender(){
      this.getSample().then( ()=> {
        this.$refs.doctorEditor.invoke('setHTML', this.$store.state.sample.comment);
        this.$refs.summaryEditor.invoke('setHTML', this.$store.state.sample.summary);
        this.$store.state.focusPageNm  = "home";
      })
    }
  }, // end method
  async mounted (){
    //TODO Admin 권한 사용만 사용 ... 실제 Backend 에서도 권한을 적용해야 함.
    if(this.$store.state.user.level === 0){
      this.showUserSetting = true;
    }
    this.getCodeList();

    // 초기 Code 가 설정 되지 않았을때 사용
    if(!this.$store.state.sample.code){
      this.dialog = true;
    }else{
      this.isRender = true;
      this.allComponentsRender();
    }
  },
  created () {
  },
  computed : {
    code : {
      get() {
        console.log("#user1 Main  Get Code:%s, Old Code: %s", this.$store.state.code, this.$store.state.oldCode)
        return this.$store.state.code
      },
      async set(code) {
        this.dialog = false;
        this.isRender = true;
        console.log("#user1 Main Set Code:%s , Store:%s, Old:%s", code, this.$store.state.code, this.$store.state.oldCode)
        this.$store.commit('setCode', code);
        this.$store.state.oldCode = this.$store.state.code
        this.allComponentsRender();
        //}
      }
    },
    microbesPieChartData() {
      return this.$store.state.microbesPieChartData;
    },
    overlayLoading() {
      return this.$store.state.overlayLoading;
    },
    aibTotal(){
      return this.$store.state.aibTotal;
    }

  }

}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Inconsolata|Oswald');
@font-face {
  font-family: 'NanumSquareRound';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/NanumSquareRound.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.dash-font {
  font-family: -apple-system11111, BlinkMacSystemFont,  'NanumSquareRound', "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

hr.hr1 {
  border: 5px solid green;
  border-radius: 5px;
}
.custom-loader {
  animation: loader 1s infinite;
  display: flex;
}
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>