import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Login from '../components/Login.vue'
import MainBoard from '../components/MainBoard'
import store from '../store/index.js'
import axios from 'axios'
import { USER, IS_AUTH } from '@/store/storeConstants'

Vue.use(VueRouter)

const requireAuth = () => async (from, to, next) => {

    console.log("#ga get Auth >> ", to.path, from.path, store.getters.getIsAuth, store.getters.getMemId);

    if ( store.getters.getIsAuth ) {
        console.log("#ga Auth")
        return next();
    }else {
        // Store 값 유실 시 서버 세션 확인
        if(await checkAuth()) {
            console.log("#ga reCheckAuth")
            return next(from.path);
        }

    }
    next('/')
}

const routes = [
    {
        path: '/',
        name: 'Login',
        component: Login
    },
    {
        path: '/home',
        component: Home,  // Tatal Chart
        alias : ['/phylum', '/age', '/balance', '/species', '/comment', '/score' ],
        name: 'main',
        hidden : true,
        meta: { title: 'Dashboard', icon: 'dashboard', affix: true },
        beforeEnter : requireAuth()
    },
    {
        path: '/scoring',
        name: 'main_scoring',
        hidden : true,
        component: function () {
            return import('../components/ScoringTable.vue')
        },
        beforeEnter : requireAuth()

    },
    {
        path: '/lacto',
        name: 'main_lacto',
        hidden : true,
        component: function () {
            return import('../components/LactoDetail.vue')
        },
        beforeEnter : requireAuth()

    },
    {
        path: '/users',
        name: 'users_setting',
        hidden : true,
        component: function () {
            return import('../components/UserSetting.vue')
        },
        beforeEnter : requireAuth()

    },
    {
        path: '/reportV2',
        name: 'reportV2',
        hidden : true,
        component: function () {
            return import('../components/ReportV2.vue')
        },
        //beforeEnter : requireAuth()

    },
    {
        path: '/report',
        name: 'report',
        hidden : true,
        component: function () {
            return import('../components/Report.vue')
        },
        beforeEnter : requireAuth()

    },
    {
        path: '/report_adult',
        name: 'report_adult',
        hidden : true,
        component: function () {
            return import('../components/Report_adult.vue')
        },
        beforeEnter : requireAuth()

    },
    {
        path: '/report_teen',
        name: 'report_teen',
        hidden : true,
        component: function () {
            return import('../components/Report_teen.vue')
        },
        beforeEnter : requireAuth()

    },
    {
        path: '/report_baby',
        name: 'report_baby',
        hidden : true,
        component: function () {
            return import('../components/Report_baby.vue')
        },
        beforeEnter : requireAuth()

    },
    {
        path: '/main',
        component: MainBoard,  // Menu Template
        name: "main_board",
        children: [
            {
                path: "dashBoard",
                name: "dashBoard",
                hidden : true,
                component: function () {
                    return import('../components/dash/Dashboard.vue')
                },
            },
            {
                path: 'lactoDash',
                name: 'lactoDash',
                hidden : true,
                component: function () {
                    return import('../components/dash/LactoDetailDash.vue')
                }
            },
            {
                path: "survey",
                name: "survey",
                component: function () {
                    return import('../components/dash/survey.vue')
                },
            },
            {
                path: "foodTable",
                name: "foodTable",
                component: function () {
                    return import('../components/FoodTable.vue')
                },
                props: { initBar: true }
            },
            {
                path: "sleep",
                name: "sleep",
                hidden : true,
                component: function () {
                    return import('../components/Sleep.vue')
                },
            },
            {
                path: "star",
                name: "star",
                hidden : true,
                component: function () {
                    return import('../components/dash/starChart.vue')
                },
            },
            {
                path: "byage",
                name: "byage",
                hidden : true,
                component: function () {
                    return import('../components/dash/byageDiversity.vue')
                },
            },
            {
                path: "byagePhylum",
                name: "byagePhylum",
                hidden : true,
                component: function () {
                    return import('../components/dash/byagePhylum.vue')
                },
            },
            {
                path: "byageGenus",
                name: "byageGenus",
                hidden : true,
                component: function () {
                    return import('../components/dash/byageGenus.vue')
                },
            },
            {
                path: "byageSpecies",
                name: "byageSpecies",
                hidden : true,
                component: function () {
                    return import('../components/dash/byageSpecies.vue')
                },
            },
            {
                path: "beneficialBacteria",
                name: "beneficialBacteria",
                hidden : true,
                component: function () {
                    return import('../components/dash/BeneficialBacteria.vue')
                },
            },
            {
                path: "harmfulBacteria",
                name: "harmfulBacteria",
                hidden : true,
                component: function () {
                    return import('../components/dash/HarmfulBacteria.vue')
                },
            },
            {
                path: "opportunisticInfectiousBacteria",
                name: "opportunisticInfectiousBacteria",
                hidden : true,
                component: function () {
                    return import('../components/dash/OpportunisticInfectiousBacteria.vue')
                },
            },
            {
                path: "balance",
                name: "balance",
                hidden : true,
                component: function () {
                    return import('../components/dash/balance.vue')
                },
            },
            {
                path: 'scoring',
                name: 'main_score',
                hidden : true,
                props: { isTotalView : false },
                component: function () {
                    return import('../components/ScoringTable.vue')
                },
            },
            {
                path: 'aib',
                name: 'aib',
                hidden : true,
                component: function () {
                    return import('../components/aib.vue')
                },
            },
            {
                path: 'species',
                name: 'species',
                hidden : true,
                component: function () {
                    return import('../components/Species.vue')
                },
            },
            {
                path: 'piePhylum',
                name: 'piePhylum',
                hidden : true,
                component: function () {
                    return import('../components/Phylum.vue')
                },
            },
            {
                path: "personalAnalysis",
                name: "personalAnalysis",
                hidden : true,
                component: function () {
                    return import('../components/dash/PersonalAnalysis.vue')
                },

            },
            {
                path: "trandSampleCompare",
                name: "trandSampleCompare",
                hidden : true,
                component: function () {
                    return import('../components/dash/TrandSampleCompare.vue')
                },

            },
            {
                path: "hashTagAnalysis",
                name: "hashTagAnalysis",
                hidden : true,
                component: function () {
                    return import('../components/dash/HashTagAnalysis.vue')
                },

            },
            {
                path: "hashTagEditTable",
                name: "hashTagEditTable",
                hidden : true,
                component: function () {
                    return import('../components/dash/HashTagEditTable.vue')
                },

            },


        ]
    }
]

const router = new VueRouter({
//    mode: 'history',
    scrollBehavior: function (to) {
        console.log("#ga scrollBehavior1 -> ", to , to.path)
        if (to.hash) {
            return {
                selector: to.hash
            }
        } else {
            return { x: 0, y: 0 }
        }

    },
    routes,
})



/*
router.beforeEach(async (to, from, next) => {

    console.log("get Auth >> ", to.path, from.path, store.getters.getIsAuth, store.getters.getMemId);

    if ( store.getters.getIsAuth ) {
        return next();
    }else {
       if(await checkAuth()) next({path: '/home'});
    }

    if ( to.path === '/' && from.path === '/') return next();

    next({
        path: '/',
        query: {redirect: '/'},
    })
})
*/
// TODO :: Router 에서 Action 수행 코드 제거 개선 필요
async function checkAuth() {
    try {

        let resp = await axios.get('/v1/checkAuth');
        console.log("resp > ", resp);

        if(resp.data.code === 200 ) {
            store.commit(IS_AUTH, true) // 인증 성공
            store.commit(USER, resp.data.user)

//            store.commit, true)
            return true;
        }
        return false;
    } catch(e){
        console.error(e)
        return false;
    }
}

export default router
