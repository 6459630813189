<template>
  <div style="text-align: center">
    <div v-if="isDash">
      <p>  &nbsp; <br><hr> <br>
    </div>
    <vs-row vs-justify="center">
      <vs-col  vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
        <div style="padding-top: 50px">
          <h4><b>Phylum 분석</b></h4>
          <br><br>
        </div>
      </vs-col>
      <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
        <div>
          <v-chart :options="avgPieData"  theme='shine' />
        </div>
      </vs-col>
      <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
        <div>
          <v-chart :options="userPieData"  theme='shine' />
        </div>
      </vs-col>
    </vs-row>

    <br><br>
    <vs-row vs-justify="center">
      <vs-col  vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
        <div>
          <h4><b>Enterotype</b></h4>
          <br><br>
        </div>
      </vs-col>
      <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
        <div>
          <v-chart :options="bindexChartData.avg"  theme='shine' />
        </div>
      </vs-col>
      <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
        <div>
          <v-chart :options="bindexChartData.user"  theme='shine' />
        </div>
      </vs-col>
    </vs-row>

  </div>
</template>
// striped
<script>

export default {
  props: {
    data : Object,
    code : String,
    isDash : Boolean
  },
  data() {
    return {
      // Note 'isActive' is left out and will not appear in the rendered table
      fields: this.initFields,
      items: this.initItems,
      avgBindexChartData: {},
      userBindexChartData: {}

    }
  },
  computed : {
    userPieData : function() {
      return this.$store.state.phylum.userPieData;
    },
    avgPieData : function() {
      return this.$store.state.phylum.avgPieData;
    },
    bindexChartData: function () {
      return this.$store.state.enterotypePieChart;
    }
  }
}
</script>
