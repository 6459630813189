<template>
  <div>
    <v-row>
      <v-col cols="12" class="ml-3">
        <v-row align="center" justify="center" style="position:absolute;top:50%;left:42%;transform: translate(-50%, -50%);">
        </v-row>
      </v-col>
    </v-row>
    <v-container>
      <v-layout>
        <v-flex d-flex justify-center mb-6 style="margin-left:-90px">
          <v-chart  :options="balance.totalBalanceGaugeData" ref="total" theme='shine' :initOptions="{ width: 700, height: 600 }" />
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex md6>
          <v-chart  :options="balance.gutBalanceGaugeData" ref="total" theme='shine' :initOptions="chartSize" />
        </v-flex>
        <v-flex md6>
          <v-chart  :options="balance.immunityBalanceGaugeData" ref="total" theme='shine' :initOptions="chartSize" />
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex md6>
          <v-chart  :options="balance.obesityBalanceGaugeData" ref="total" theme='shine' :initOptions="chartSize" />
        </v-flex>
        <v-flex md6>
          <v-chart  :options="balance.atopyBalanceGaugeData" ref="total" theme='shine' :initOptions="chartSize" />
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex md6>
          <v-chart  :options="balance.adultBalanceGaugeData" ref="total" theme='shine' :initOptions="chartSize" />
        </v-flex>
        <v-flex md6>
          <v-chart  :options="balance.femaleBalanceGaugeData" ref="total" theme='shine' :initOptions="chartSize" />
        </v-flex>
      </v-layout>
      <v-layout row>
        <v-flex md6>
          <v-chart  :options="balance.etcBalanceGaugeData" ref="total" theme='shine' :initOptions="chartSize" />
        </v-flex>
        <v-flex md6>
          <v-chart  :options="balance.ageBalanceGaugeData" ref="total" theme='shine' :initOptions="chartSize" />
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>
// striped
<script>

export default {
  props: {
    data : Object,
    code : String
  },
  data() {
    return {
      chartSize : { width: 500, height: 500 }
    }
  },
  methods: {
  },
  computed : {
    balance(){
      return this.$store.state.balance;
    },
    sample() {
      return this.$store.state.sample;
    }
  }
}
</script>
