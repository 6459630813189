<template >

  <div ref="aib"><br>
    <v-container >
        <v-flex d-flex justify-center mt-auto>
          <h4><b>AiB Chart 1</b></h4>
        </v-flex>
      <v-flex d-flex justify-center mt-auto>
        보편적 분류기준에 따른 분포 분석
      </v-flex>
    </v-container>
    <v-layout>
      <v-flex d-flex justify-start mb-6>
        <v-chart :options="aibChart1" ref="polar" theme='shine' :initOptions="chartSize"/>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex d-flex justify-start mb-6>
      </v-flex>
    </v-layout>
    <br><br><br>
    <br><br><br>
    <v-container fluid grid-list-xl >
      <v-flex d-flex justify-center mt-auto>
        <h4><b>AiB Chart 2</b></h4>
      </v-flex>
      <v-flex d-flex justify-center mt-auto>
        Phylum(문) Level의 분포 분석
      </v-flex>
    </v-container>
    <v-layout>
      <v-flex d-flex justify-start mb-6>
        <v-chart :options="aibChart2" ref="polar" theme='shine' :initOptions="chartSize"/>
      </v-flex>
    </v-layout>
    <br><br><br>
    <br><br>
    <v-layout v-if="true">
      <v-flex d-flex justify-center mb-6>
        <v-simple-table style='width:1100px'>
          <template v-slot:default>
            <thead>
            <tr>
              <th class="text-center font-weight-black">
<!--                <v-btn
                  color="primary"
                  depressed
                  elevation="1"
                  outlined
                  raised
                  small
                  @click="isToExponential = !isToExponential"
              >{{ (isToExponential)?"지수보기":"실수보기" }}</v-btn>-->
              </th>
              <th class="text-center font-weight-medium text-sm-body-2" style="color:#4788e2">
                Bacteroidetes
              </th>
              <th class="text-center font-weight-medium text-sm-body-2" style="color:#e5b124">
                Firmicutes
              </th>
              <th class="text-center font-weight-medium text-sm-body-2" style="color:#8e89dd">
                Proteobacteria
              </th>
              <th class="text-center font-weight-medium text-sm-body-2" style="color:#27b688">
                Actinobacteria
              </th>
              <th class="text-center font-weight-medium text-sm-body-2" style="color:#89b66d">
                Verrucomicrobia
              </th>
              <th class="text-center font-weight-medium text-sm-body-2" style="color:#EF515FFF">
                Fusobacteria
              </th>
              <th class="text-center font-weight-medium text-sm-body-2" style="color:#999fab">
                기타
              </th>
            </tr>
            </thead>
            <tbody v-if="isToExponential">
            <tr
                v-for="item in phylumExcessIndexList"
                :key="item.TYPE"
            >
<!--              <td :style="'color:'+item.data[0].itemStyle.color">{{ item.name }}</td>
              <td class="text-center">{{ er.parseFloat(item.excessIndex).toExponential(0)}}</td>
              -->
              <td class="text-center">{{item.title}}</td>
              <td class="text-right">{{item.bacteroidetes}}</td>
              <td class="text-right">{{item.firmicutes}}</td>
              <td class="text-right">{{item.proteobacteria}}</td>
              <td class="text-right">{{item.actinobacteria}}</td>
              <td class="text-right">{{item.verrucomicrobia}}</td>
              <td class="text-right">{{item.fusobacteria}}</td>
              <td class="text-right">{{item.etc}}</td>
            </tr>
            </tbody>
            <tbody v-else>
            <tr
                v-for="item in phylumExcessIndexList"
                :key="item.TYPE"
            >
              <!--              <td :style="'color:'+item.data[0].itemStyle.color">{{ item.name }}</td>
                            <td class="text-center">{{ er.parseFloat(item.excessIndex).toExponential(0)}}</td>
                            -->
              <td class="text-center">{{ (item.TYPE === "avgData")?"평균":"과잉" }}</td>
              <td class="text-right">{{Math.log10(item.bacteroidetes).toExponential(0)}}</td>
              <td class="text-right">{{item.firmicutes.toExponential(0)}}</td>
              <td class="text-right">{{item.proteobacteria.toExponential(0)}}</td>
              <td class="text-right">{{item.actinobacteria.toExponential(0)}}</td>
              <td class="text-right">{{item.verrucomicrobia.toExponential(0)}}</td>
              <td class="text-right">{{item.fusobacteria.toExponential(0)}}</td>
              <td class="text-right">{{item.etc.toExponential(0)}}</td>
            </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-flex>
    </v-layout>
    <br><br><br>
    <v-container fluid grid-list-xl >
      <v-flex d-flex justify-center mt-auto>
        <h4><b>AiB Chart 3</b></h4>
      </v-flex>
      <v-flex d-flex justify-center mt-auto>
        Phylum(문) Level의 요약 분석
      </v-flex>
    </v-container>

    <v-layout>
      <v-flex d-flex justify-start mb-6>
        <v-chart :options="aibChart3" ref="polar" theme='shine' :initOptions="chartSize"/>
      </v-flex>
    </v-layout>
  </div>
</template>
<script>


  export default {
    components: {
    },

    data () {
      return {
        chartSize : {
          width: 1200,
          height: 500
        },
        isToExponential : true,
        excessIndexButtonStr : "실수보기"

      }
    },
    methods: {
      chartResize() {
        try {
          let resizeWidth = this.$refs.aib.clientWidth;
          console.log("resize ~ " , this.$refs.aib);
          this.chartSize.width = resizeWidth;
          //resizeWidth = (resizeWidth > 1300 )?1300:resizeWidth;
/*

          this.polar.init.width = resizeWidth;
          this.totalLegendPhylum.init.width = resizeWidth;
          this.total.init.width = resizeWidth;
          this.totalPhylum.init.width = resizeWidth;
*/
          console.log("resize ~ " , resizeWidth, window.innerWidth)
        } catch (e) {
          console.log("resize", e);
        }
      }
    },
    mounted (){
      window.addEventListener('resize', this.chartResize);
    },
    created () {
    },
    beforeDestroy () {
      window.removeEventListener('resize', this.chartResize);
    },
    updated () {
      this.chartResize();
    },
    computed: {
      code () {
        return this.$store.state.code
      },
      aibChart1() {
        return this.$store.state.aibChart1;
      },
      aibChart2() {
        return this.$store.state.aibChart2;
      },
      aibChart3() {
        return this.$store.state.aibChart3;
      },
      phylumExcessIndexList() {
        return this.$store.state.phylumExcessIndexList;
      }
    }
}
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css?family=Inconsolata|Oswald');
@font-face {
  font-family: 'NanumSquareRound';
  src: url('https://cdn.jsdelivr.net/gh/projectnoonnu/noonfonts_two@1.0/NanumSquareRound.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

.dash-font {
  font-family: -apple-system11111, BlinkMacSystemFont,  'NanumSquareRound', "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
}

hr.hr1 {
  border: 5px solid green;
  border-radius: 5px;
}
 .custom-loader {
   animation: loader 1s infinite;
   display: flex;
 }
@-moz-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-webkit-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@-o-keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}
</style>