// Bootstrap4 + FontAwesome Icon
export default {
    table: {
        tableClass:
            "ui blue selectable unstackable celled table table-hover vuetable gut_table",
        tableWrapper: 'gut_table',
        tableHeaderClass: '',
        tableBodyClass: ' small align-middle text-left',
        //tableClass: 'table table-bordered table-hover',
        loadingClass: 'loading',
        ascendingIcon: 'fa fa-chevron-up',
        descendingIcon: 'fa fa-chevron-down',
        ascendingClass: 'sorted-asc',
        descendingClass: 'sorted-desc',
        sortableIcon: 'fa fa-sort',
        detailRowClass: 'vuetable-detail-row',
        handleIcon: 'fa fa-bars text-secondary',
        renderIcon(classes) {
            return `<i class="${classes.join(' ')}"></span>`
        }
    },
    pagination: {
        wrapperClass: 'pagination float-right',
        activeClass: 'active',
        disabledClass: 'disabled',
        pageClass: 'page-link',
        linkClass: 'page-link',
        paginationClass: 'pagination',
        paginationInfoClass: 'float-left',
        dropdownClass: 'form-control',
        icons: {
            first: 'fas fa-angle-double-left',
            prev: 'fas fa-angle-left',
            next: 'fas fa-angle-right',
            last: 'fas fa-angle-double-right',
        }
    }
}