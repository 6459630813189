<template>
  <div style="text-align: center">
    <div v-if="(heightChartData.isGrowth)">


      <p>  &nbsp; <br><hr> <br>
      <vs-row vs-justify="center">
        <vs-col  vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
          <div>
            <br><br>
          </div>
        </vs-col>
        <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
          <div>
            <h4><b>키 성장 도표</b></h4>
            <v-chip
                class="ma-2"
                color="orange"
                label
                outlined
            >
              {{ "백분위수: "  + heightChartData.heightPercentile }}
            </v-chip>
            <br>
            <v-chart  :options="heightChartData"
                      :init-options="{
                            width: 600,
                            height : 500
                            }"
                      theme='shine' />
            <!--                  <h5 v-else>성인은 지원하지 않습니다.</h5> -->
          </div>
        </vs-col>
        <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
          <div>
            <h4><b>체중 성장 도표</b></h4>
            <v-chip
                class="ma-2"
                color="orange"
                label
                outlined
            >
              {{ "백분위수: "  + weightChartData.weightPercentile }}
            </v-chip>
            <br>
            <v-chart :options="weightChartData" :init-options="{
                  width: 600,
                  height : 500
              }" theme='shine' />
            <!--                <h5 v-else>성인은 지원하지 않습니다.</h5> -->
          </div>
        </vs-col>
      </vs-row>


      <v-container>
        <v-layout>
          <v-flex d-flex justify-center mb-6 style="margin-left:0px;margin-top: 200px">
            <h3><b>식단</b></h3>
          </v-flex>
        </v-layout>
        <v-layout v-if="isFoodList">
          <v-flex d-flex justify-center mb-6 style="margin-left:0px; margin-top: -100px">
            <food-table></food-table>
          </v-flex>
        </v-layout>
      </v-container>


      <br><br>
      <vs-row vs-justify="center">
        <vs-col  vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
          <div>
            <br><br>
            <br><br>
          </div>
        </vs-col>
        <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
          <div>
            <h4><b>식이취향(식재료)</b></h4>
            <br>
            <v-chart :options="tastesList.type1"  theme='shine' />
          </div>
        </vs-col>
        <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
          <div>
            <h4><b>식이취향(식재료)</b></h4>
            <br>
            <v-chart :options="tastesList.type2"  theme='shine' />
          </div>
        </vs-col>
      </vs-row>
      <br><br>
      <vs-row vs-justify="center">
        <vs-col  vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12">
          <div>
            <br><br>
            <br><br>
          </div>
        </vs-col>
        <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
          <div>
            <br><br>
            <div>
              <h4><b>수면 차트</b></h4>
              <br>
              <v-chart :options="sleepChartData"  theme='shine' />
            </div>
            <br><br>
          </div>
        </vs-col>
      </vs-row>
      <div id="score"></div>
      <v-container>
        <v-layout>
          <v-flex d-flex justify-center mb-6 style="margin-left:0px;margin-top: 50px">
            <h4><b>별차트(가제)</b></h4>
          </v-flex>
        </v-layout>
        <v-layout row>
          <v-flex md1>
            <v-text-field
                v-model="disease"
                :rules="rules"
                @change="drawStartChart()"
                label="Disease Score"
            ></v-text-field>
          </v-flex>
          <v-flex md9>
            <v-chart :options="starChartData.chart"  :initOptions="{
            width: 1100, height : 500 }" theme='shine' />
          </v-flex>
        </v-layout>
      </v-container>

      <a id="star"></a>
      <v-container>
        <v-layout>
          <v-flex d-flex justify-center mb-6 style="margin-left:0px;margin-top: 200px">
            <h4><b><p>별차트(배경)  </p></b></h4>
          </v-flex>
        </v-layout>
        <v-layout>
          <v-flex d-flex justify-center mb-6 style="margin-left:0px;margin-left: -230px">
            <v-chart  :options="starChartData.bgChart"  :initOptions="{
            width: 1100,
            height : 500
          }" theme='shine' />
          </v-flex>
        </v-layout>
      </v-container>
    </div>
  </div>
</template>
// striped
<script>
import FoodTable from '@/components/FoodTable'
import { mapActions } from 'vuex'

export default {
  components: { FoodTable },
  props: {
    data : Object,
    code : String
  },
  data() {
    return {
      rules: [
        v => !!v || '0 이상 10 이하의 값은 필수 입니다.',
        v => (v >=0 && v < 11) || '0 이하 10 이상의 점수는 적용 할 수 없습니다.',
      ],
      isFoodList: false
    }
  },
  methods: {
    ...mapActions( ['reloadStartChart'] ),

    /**
     * 별차트 다시 생성
     */
    async drawStartChart() {
      console.log("#now3 draw %s", this.$store.state.sample.disease)
      await this.reloadStartChart();
    },

  },
  mounted () {
    // foodtable 의 경우 많은 양 데이터 렌더링과 빠른 속도로 처리 될 경우 정상적인 랜더링을 하지
    // 못하는 경우가 있어 다음과 같이 지연 처리함, 단일 로드시에는 문제 없음을 확인
    setTimeout(() => {
      this.isFoodList = true;
    }, 5000);
  },
  computed : {
    heightChartData(){
      return this.$store.state.heightChartData;
    },
    weightChartData(){
      return this.$store.state.weightChartData;
    },
    tastesList(){
      return this.$store.state.tastesList;
    },
    sleepChartData(){
      return this.$store.state.sleepChartData;
    },
    starChartData(){
      return this.$store.state.starChart;
    },
    disease : {
      set(val){
        console.log("#now3 %s", val)
        this.$store.state.sample.disease = val;
      },
      get(){
        return this.$store.state.sample.disease;
      }
    }



  }
}
</script>