<template>
    <div style="text-align: center">
        <input type='hidden' :name='code'>
         <div v-if="isHome">
           <br><br><br><br><br><br><br><br><hr><br><br>
         </div>
        <h3><p class="font-weight-bold">종분석</p></h3>
        <vs-row vs-justify="center">
            <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                    <div>
                        <h5>
                            <p class="font-weight-bold">장건강</p>
                        </h5>
                    </div>
                     <div>
                        <v-chart :options="barLineChart.gut"  theme='shine' />
                    </div>
            </vs-col>
            <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                    <div>
                        <h5>
                            <p class="font-weight-bold">Index Chart</p>
                        </h5>
                    </div>
                    <div>
                        <v-chart :options="indexChart.gut"  theme='shine' />
                    </div>
            </vs-col>
            <vs-col  vs-justify="center" vs-align="center" vs-lg="10" vs-sm="10" vs-xs="12">
                <div>
                    <h3>

                    </h3>
                </div>
                <div>
                    <vuetable ref="gut"
                              :fields="species.fields"
                              :api-mode="false"
                              :css="css.table"
                              :row-class="species.onRowClass"
                              :sortOrder="species.sortOrderForGut"
                              :data-manager="dataManager"
                    ></vuetable>
                </div>
            </vs-col>
        </vs-row>
        <br><br>
        <vs-row vs-justify="center">
            <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                <div>
                    <h5>
                        <p class="font-weight-bold">장-면역</p>
                    </h5>
                </div>
                <div>
                    <v-chart :options="barLineChart.immunity"  theme='shine' />
                </div>
            </vs-col>
            <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                <div>
                    <h5>
                        <p class="font-weight-bold">Index Chart</p>
                    </h5>
                </div>
                <div>
                    <v-chart :options="indexChart.immunity"  theme='shine' />
                </div>
            </vs-col>
            <vs-col  vs-justify="center" vs-align="center" vs-lg="10" vs-sm="10" vs-xs="12">
                <div>
                    <h3>

                    </h3>
                </div>
                <div>
                    <vuetable ref="immunity"
                              :fields="species.fields"
                              :api-mode="false"
                              :css="css.table"
                              :row-class="species.onRowClass"
                              :sortOrder="species.sortOrderForImmunity"
                              :data-manager="dataManager"
                    ></vuetable>
                </div>
            </vs-col>
        </vs-row>


        <br><br>
        <vs-row vs-justify="center">
            <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                <div>
                    <h5>
                        <p class="font-weight-bold">당뇨 예방</p>
                    </h5>
                </div>
                <div>
                    <v-chart :options="barLineChart.obesity"  theme='shine' />
                </div>
            </vs-col>
            <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                <div>
                    <h5>
                        <p class="font-weight-bold">Index Chart</p>
                    </h5>
                </div>
                <div>
                    <v-chart :options="indexChart.obesity"  theme='shine' />
                </div>
            </vs-col>
            <vs-col  vs-justify="center" vs-align="center" vs-lg="10" vs-sm="10" vs-xs="12">
                <div>
                    <h3>

                    </h3>
                </div>
                <div>
                    <vuetable ref="obesity"
                              :fields="species.fields"
                              :api-mode="false"
                              :css="css.table"
                              :row-class="species.onRowClass"
                              :sortOrder="species.sortOrderForObesity"
                              :data-manager="dataManager"
                    ></vuetable>
                </div>
            </vs-col>
        </vs-row>

        <br><br>
        <vs-row vs-justify="center">
            <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                <div>
                    <h5>
                        <p class="font-weight-bold">장-피부</p>
                    </h5>
                </div>
                <div>
                    <v-chart :options="barLineChart.atopy"  theme='shine' />
                </div>
            </vs-col>
            <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                <div>
                    <h5>
                        <p class="font-weight-bold">Index Chart</p>
                    </h5>
                </div>
                <div>
                    <v-chart :options="indexChart.atopy"  theme='shine' />
                </div>
            </vs-col>
            <vs-col  vs-justify="center" vs-align="center" vs-lg="10" vs-sm="10" vs-xs="12">
                <div>
                    <h3>

                    </h3>
                </div>
                <div>
                    <vuetable ref="atopy"
                              :fields="species.fields"
                              :api-mode="false"
                              :css="css.table"
                              :row-class="species.onRowClass"
                              :sortOrder="species.sortOrderForAtopy"
                              :data-manager="dataManager"
                    ></vuetable>
                </div>
            </vs-col>
        </vs-row>

        <br><br>
        <vs-row vs-justify="center">
            <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                <div>
                    <h5>
                        <p class="font-weight-bold">장-간</p>
                    </h5>
                </div>
                <div>
                    <v-chart :options="barLineChart.adult"  theme='shine' />
                </div>
            </vs-col>
            <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                <div>
                    <h5>
                        <p class="font-weight-bold">Index Chart</p>
                    </h5>
                </div>
                <div>
                    <v-chart :options="indexChart.adult"  theme='shine' />
                </div>
            </vs-col>
            <vs-col  vs-justify="center" vs-align="center" vs-lg="10" vs-sm="10" vs-xs="12">
                <div>
                    <h3>

                    </h3>
                </div>
                <div>
                    <vuetable ref="adult"
                              :fields="species.fields"
                              :api-mode="false"
                              :css="css.table"
                              :row-class="species.onRowClass"
                              :sortOrder="species.sortOrderForAdult"
                              :data-manager="dataManager"
                    ></vuetable>
                </div>
            </vs-col>
        </vs-row>

        <br><br>
        <vs-row vs-justify="center">
            <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                <div>
                    <h5>
                        <p class="font-weight-bold">장-뇌</p>
                    </h5>
                </div>
                <div>
                    <v-chart :options="barLineChart.female"  theme='shine' />
                </div>
            </vs-col>
            <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                <div>
                    <h5>
                        <p class="font-weight-bold">Index Chart</p>
                    </h5>
                </div>
                <div>
                    <v-chart :options="indexChart.female"  theme='shine' />
                </div>
            </vs-col>
            <vs-col  vs-justify="center" vs-align="center" vs-lg="10" vs-sm="10" vs-xs="12">
                <div>
                    <h3>

                    </h3>
                </div>
                <div>
                    <vuetable ref="female"
                              :fields="species.fields"
                              :api-mode="false"
                              :css="css.table"
                              :sortOrder="species.sortOrderForFemale"
                              :row-class="species.onRowClass"
                              :data-manager="dataManager"
                    ></vuetable>
                </div>
            </vs-col>
        </vs-row>


        <br><br>
        <vs-row vs-justify="center">
            <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                <div>
                    <h5>
                        <p class="font-weight-bold">기타</p>
                    </h5>
                </div>
                <div>
                    <v-chart :options="barLineChart.etc"  theme='shine' />
                </div>
            </vs-col>
            <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
                <div>
                    <h5>
                        <p class="font-weight-bold">Index Chart</p>
                    </h5>
                </div>
                <div>
                    <v-chart :options="indexChart.etc"  theme='shine' />
                </div>
            </vs-col>
            <vs-col  vs-justify="center" vs-align="center" vs-lg="10" vs-sm="10" vs-xs="12">
                <div>
                    <h3>

                    </h3>
                </div>
                <div>
                    <vuetable ref="etc"
                              :fields="species.fields"
                              :api-mode="false"
                              :css="css.table"
                              :multi-sort="true"
                              :row-class="species.onRowClass"
                              :sortOrder="species.sortOrderForEtc"
                              :data-manager="dataManager"
                    ></vuetable>
                </div>
            </vs-col>
        </vs-row>
      <v-overlay :value="overlayLoading">
        <v-progress-circular
            indeterminate
            size="64"
        ></v-progress-circular>
      </v-overlay>
    </div>
</template>
// striped
<script>
    import Vuetable from 'vuetable-2'
    import CssConfig from '../theme/vueTableCssBootstrap4.js'

    export default {
      components: {
        Vuetable,
      },
      props: {
        isHome: Boolean,
        data: Object,
        initItems: Array,
        sortBy: String,
        sortDesc: Boolean,
      },
      data () {
        return {
          css: CssConfig
        }
      },
      methods: {
        //...mapActions(['speciesAction']),
        updateSpecies() {

        },
        dataManager (s, p) {
          let tableData = {};
          try {
            tableData = this.tableData[s[0].key];
          }catch (e){
            return tableData;
          }
          if (s.length > 0) {
            //console.log('key', s[0].key);
            tableData.sort(function (a, b) {
              let bindoA = parseInt(a.bindo)
              let bindoB = parseInt(b.bindo)

              if (s[0].direction === 'desc') {
                return bindoA > bindoB ? -1 : bindoA < bindoB ? 1 : 0
              }

              return bindoA < bindoB ? -1 : bindoA > bindoB ? 1 : 0

            })
          }
          return {
            pagination: p,
            data: tableData,
          }
        },
        reloadTable(){
          console.log("getSample!!!!!!!!!: Species ")
          try{
            Object.keys(this.species.tableData).forEach( key => {
              this.$refs[key].reload();
            })
            this.overlayLoading = false;
          }catch (e){
            return;
          }
        }
      },
      watch : {
        indexChart: function() {
          this.overlayLoading = false;
          this.reloadTable();
        }
      },
      mounted () {
        this.overlayLoading = true;
        //this.reloadTable()
      },
      computed: {
        species: function () {
          return this.$store.state.species
        },
        indexChart: function () {
          return this.$store.state.speciesIndexChart;
        },
        tableData: function () {
          return this.$store.state.speciesTableData;
        },
        barLineChart: function () {
          return this.$store.state.speciesBarLineChart;
        },
        code: function () {
          return this.$store.state.code;
        },
        overlayLoading: function() {
          return this.$store.state.overlayLoading;
        }
      }
    }
</script>


<style >
table.vuetable {
        font-size: 15px !important;
        color : black;
    }
</style>
