<template>
  <v-app id="inspire">
    <v-main>
      <span class="bg"></span>
      <v-container
          class="fill-height"
          fluid
      >
        <v-row
            align="center"
            justify="center"
        >
          <v-col
              cols="12"
              sm="8"
              md="4"
          >
            <div class="card">
              <div class="card-header">
                <div>
                  <img src="../../public/img/aib_logo.png" height="50%"
                       width="50%"/>
                </div>
                <br>
                <div style="text-align: center">
                  <h3 class="subheading">장내 미생물 분석 시스템</h3>

                </div>
                <div class="d-flex justify-content-end social_icon">
                  <span><i class="fas fa-chart-bar"></i></span>
                  <!--   <span><i class="fas fa-user-lock"></i></span>-->
                </div>
              </div>
              <div class="card-body">
                <form action="/v1/auth">
                  <div class="input-group form-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><v-icon >mdi-account</v-icon></span>
                    </div>
                    <input autocomplete="new-password" type="text"  class="form-control imeOff" placeholder="username" v-model="email" >

                  </div>
                  <div class="input-group form-group">
                    <div class="input-group-prepend">
                      <span class="input-group-text"><v-icon >mdi-key</v-icon></span>
                    </div>
                    <input autocomplete="new-password" type="password" class="form-control" placeholder="password" v-model="pwd" @keyup="keySubmit" style="ime-mode:inactive;">
                  </div>
<!--                  <div class="row align-items-center remember">
                    <input type="checkbox" autocomplete="on">Remember Me
                  </div>-->
                  <div align="right">
                    <v-btn
                        class="ma-2"
                        :loading="loading"
                        :disabled="loading"
                        @click="submit"
                        color="#FFC312" >
                      Login
                    </v-btn>
                  </div>
                  <v-alert
                      :value="alert"
                      color="pink"
                      dark
                      border="top"
                      icon="mdi-key"
                      transition="scale-transition"
                  > 로그인에 실패 하였습니다.
                  </v-alert>
                </form>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { validationMixin } from 'vuelidate'
import { required, maxLength, email } from 'vuelidate/lib/validators'
import { mapActions, mapGetters } from 'vuex'

export default {
  mixins: [validationMixin],

  validations: {
    name: {required, maxLength: maxLength(10)},
    email: {required, email},
    select: {required},
    checkbox: {
      checked (val) {
        return val
      },
    },
  },

  data: () => ({
    name: '',
    email: '',
    pwd: '',
    loading: false,
    alert: false,
    select: null,
    items: [
      'Item 1',
      'Item 2',
      'Item 3',
      'Item 4',
    ],
    checkbox: false,
    valid: false,
    firstname: '',
    lastname: '',
    nameRules: [
      v => !!v || 'Name is required',
      v => v.length <= 10 || 'Name must be less than 10 characters',
    ],

    emailRules: [
      v => !!v || 'E-mail is required',
      v => /.+@.+/.test(v) || 'E-mail must be valid',
    ],

  }),

  computed: {
    ...mapGetters(['getIsAuth']),
  },

  methods: {
    ...mapActions(['LOGIN']),
    async keySubmit(){
        if (window.event.keyCode == 13){
            this.submit();
        }
    },
    async submit () {

      this.$v.$touch();
      this.loading = true;
      let param = {id: this.email, password: this.pwd}
      let resp = await this.LOGIN(param);
      this.loading = false;
      if (resp.data.code === 200) {
        console.log("no", this.$router.push({ path : "/main", hash: '' }));
      } else {
        this.alert = true;
        setTimeout(() => {
          this.alert = false;
        }, 1300)
      }

    },
    clear () {
      this.$v.$reset()
      this.name = ''
      this.email = ''
      this.select = null
      this.checkbox = false
    },
  },
}
</script>
<style>

.bg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-position: center center;
  background-repeat: no-repeat;
  background-image: url('../../public/img/report.jpg');
  background-size: cover;
  background-color: #E1DEDB;
  transform: scale(1);
}

/* Made with love by Mutiullah Samim*/

/*@import url('https://fonts.googleapis.com/css?family=Numans');*/

html, body {
  background-repeat: no-repeat;
  height: 100%;
  font-family: 'Numans', "Malgun Gothic";
}

.container {
  height: 100%;
  align-content: center;
}

.card {
  height: 350px;
  margin-top: auto;
  margin-bottom: auto;
  width: 400px;
  background-color: rgba(87, 184, 70, 0.9) !important;
}

.social_icon span {
  font-size: 60px;
  margin-left: 10px;
  color: #FFC312;
}

.social_icon span:hover {
  color: #00ff00;
  cursor: pointer;
}

.card-header h3 {
  color: white;
}

.social_icon {
  position: absolute;
  right: 20px;
  top: -45px;
}

.input-group-prepend span {
  width: 50px;
  background-color: #FFC312;
  color: black;
  border: 0 !important;
}

input:focus {
  outline: 0 0 0 0 !important;
  box-shadow: 0 0 0 0 !important;

}

.imeOff { ime-mode:disabled }

.remember {
  color: white;
}

.remember input {
  width: 20px;
  height: 20px;
  margin-left: 15px;
  margin-right: 5px;
}

.login_btn {
  color: black;
  background-color: #FFC312;
  width: 100px;
}

.login_btn:hover {
  color: black;
  background-color: white;
}

.links {
  color: white;
}

.links a {
  margin-left: 4px;
}
</style>