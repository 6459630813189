export const MEM_ID = 'MEM_ID'
export const USER = 'USER' //사용자 정보
export const ERROR_STATE = 'ERROR_STATE'
export const IS_AUTH = 'IS_AUTH'
export const LOGIN = 'LOGIN'   // 로그인
export const LOGOUT = 'LOGOUT'   // 로그아웃
export const CHECK_AUTH = 'CHECK_AUTH' // 인증 확인
export const NOTIFICATION = 'NOTIFICATION' // 알림
export const SESSION_GETTER = 'SESSION_GETTER' // 세션 가져오기
export const LACTO_BIFI = 'Bifidobacterium' // 유산균 분석 데이터 가져 오기
export const LACTO_DETAIL = 'LACTO_DETAIL' // 유산균 분석 데이터 가져 오기

export const ALERT = 'alert' // 경보
export const NOT_VALID_EMAIL_MSG = "이메일 형식의 ID가 아닙니다."
export const NOT_VAIID_PASSWORD_MSG = "PASSWORD 입력이 잘 못 되었습니다."