<template>
  <div style="text-align: center">
    <p>  &nbsp; <br> <hr> <br>

    <br><br>
    <vs-row vs-justify="center">
      <vs-col vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12" style="border-bottom:10px">
        <div>
          <h4><b>나이별 다양성 분석</b></h4>
        </div>
      </vs-col>
      <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
        <div>
          <v-chart :options="byAgeDiversity"  />
        </div>
        Absence Ratio: {{ byAgeDiversity.absenceRatio || 0}}% ({{byAgeDiversity.absenceZeroCnt}}/{{ byAgeDiversity.absenceTotalCnt}})
      </vs-col>
      <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
        <div>
          <v-chart :options="freqDistributionListChart"  theme='shine' />
        </div>
      </vs-col>
    </vs-row>
    <vs-row vs-justify="center">
      <vs-col vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12" style="border-bottom:10px; margin:20px">
        <div>
          <h5><b>Species</b></h5>
          <div style="margin:20px">
            <vs-select style="float:none; margin:0 auto"
                       width="300px"
                       autocomplete
                       @input="getByAgeDiversitySpecies"
                       class="center-block"
                       v-model="selectSpecies"
            >
              <vs-select-item :key="index" :value="item.value" :text="item.text"
                              v-for="(item,index) in selectList.speciesList"/>
            </vs-select>
          </div>
        </div>
      </vs-col>
      <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
        <div>
          <v-chart :options="byAgeDiversityBySpecies"  />
        </div>
        Absence Ratio: {{ byAgeDiversityBySpecies.absenceRatio || 0}}% ({{byAgeDiversityBySpecies.absenceZeroCnt}}/{{ byAgeDiversityBySpecies.absenceTotalCnt}})
      </vs-col>
      <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
        <div>
          <v-chart :options="freqDistributionListBySpeciesChart"  theme='shine' />
        </div>
      </vs-col>
    </vs-row>
    <vs-row vs-justify="center">
      <vs-col vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12" style="border-bottom:10px; margin:20px">
        <div>
          <h5><b>Phylum</b></h5>
          <div style="margin:20px">
            <vs-select style="float:none; margin:0 auto"
                       width="250px"
                       autocomplete
                       @input="getByAgeDiversityPhylum"
                       class="center-block"
                       v-model="selectPhylum"
            >
              <vs-select-item :key="index" :value="item.value" :text="item.text"
                              v-for="(item,index) in selectList.phylumList"/>
            </vs-select>
          </div>
        </div>
      </vs-col>
      <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
        <div>
          <v-chart :options="byAgeDiversityByPhylum"  />
        </div>
        Absence Ratio: {{ byAgeDiversityByPhylum.absenceRatio || 0}}% ({{byAgeDiversityByPhylum.absenceZeroCnt}}/{{ byAgeDiversityByPhylum.absenceTotalCnt}})
      </vs-col>
      <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
        <div>
          <v-chart :options="freqDistributionListByPhylumChart"  theme='shine' />
        </div>
      </vs-col>
    </vs-row>
    <vs-row vs-justify="center">
      <vs-col vs-justify="center" vs-align="center" vs-lg="12" vs-sm="12" vs-xs="12" style="border-bottom:10px; margin:20px">
        <div>
          <h5><b>Genus</b></h5>
          <div style="margin:20px">
            <vs-select style="float:none; margin:0 auto"
                       width="200px"
                       autocomplete
                       @input="getByAgeDiversityGenus"
                       class="center-block"
                       v-model="selectGenus"
            >
              <vs-select-item :key="index" :value="item.value" :text="item.text"
                              v-for="(item,index) in selectList.genusList"/>
            </vs-select>
          </div>
        </div>
      </vs-col>
      <vs-col  vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
        <div>
          <v-chart :options="byAgeDiversityByGenus"  />
        </div>
        Absence Ratio: {{ byAgeDiversityByGenus.absenceRatio || 0}}% ({{byAgeDiversityByGenus.absenceZeroCnt}}/{{ byAgeDiversityByGenus.absenceTotalCnt}})
      </vs-col>
      <vs-col vs-justify="center" vs-align="center" vs-lg="5" vs-sm="5" vs-xs="12">
        <div>
          <v-chart :options="freqDistributionListByGenusChart"  theme='shine' />
        </div>
      </vs-col>
    </vs-row>
    <br><br>
  </div>
</template>
// striped
<script>
import { mapActions } from 'vuex';

export default {
  props: {
    code : String
  },
  name : 'Diversity',
  data() {
    return {
      selectSpecies: 'acetethylicum',
      selectGenus: 'Abiotrophia',
      selectPhylum: 'Actinobacteria',
    }
  },
  methods: {
    ...mapActions(['getByAgeDiversity', 'getByAgeDiversityPhylum', 'getByAgeDiversityGenus', 'getByAgeDiversitySpecies', 'getSelectList']),

  },
  mounted()  {
    this.$store.state.focusPageNm = "diversity";
    this.getSelectList();
    this.getByAgeDiversity();
    this.getByAgeDiversitySpecies(this.selectSpecies);
    this.getByAgeDiversityPhylum(this.selectPhylum);
    this.getByAgeDiversityGenus(this.selectGenus);
  },
  computed: {
    byAgeDiversity(){
      return this.$store.state.byAgeDiversity;
    },
    freqDistributionListChart(){
      return this.$store.state.freqDistributionListChart;
    },
    byAgeDiversityBySpecies(){
      return this.$store.state.byAgeDiversityBySpecies;
    },
    freqDistributionListBySpeciesChart(){
      return this.$store.state.freqDistributionListBySpeciesChart;
    },
    byAgeDiversityByPhylum(){
      return this.$store.state.byAgeDiversityByPhylum;
    },
    freqDistributionListByPhylumChart(){
      return this.$store.state.freqDistributionListByPhylumChart;
    },
    byAgeDiversityByGenus(){
      return this.$store.state.byAgeDiversityByGenus;
    },
    freqDistributionListByGenusChart(){
      return this.$store.state.freqDistributionListByGenusChart;
    },
    selectList(){
      return this.$store.state.selectList;
    }
  }
}
</script>
