<template>

  <dialog-drag v-if="doctorDialog" class="dialog" id="dialog-2" title="Comment" :options="dialogOpts"  @drag-end="drag">
    <v-container>
      <v-layout>
        <v-flex  lg12 sm12 xs12>
          <div style="width:600px;height:460px;bottom:10px;padding:40px;border:1px solid #ccc;">
            <h5> Comment 를 작성해 주세요.(리포트 생성시 필수) </h5>
            <hr class="hr1">
            <editor
                ref="commentEditor"
                :initialValue="htmlStr"
                :options="editorOptions"
                height="300px"
                initialEditType="wysiwyg"
                previewStyle="tab"
            />
            <v-card-actions>


              <v-spacer></v-spacer>
              <v-btn
                  :loading="loadingSum"
                  :disabled="loadingSum"
                  color="rgb(140, 193, 82)"
                  @click="save"
              >
                save
                <v-icon right dark
                >
                  mdi-content-save
                </v-icon>
              </v-btn>
              <v-btn
                  :loading="loadingSum"
                  :disabled="loadingSum"
                  color="rgb(140, 193, 82)"
                  @click="close"
              >
                close
                <v-icon right dark
                >
                  mdi-close
                </v-icon>
              </v-btn>
            </v-card-actions>
            <div style="margin-top:2px;margin-left: 220px">
              <v-tooltip
                  v-model="show"
                  top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                  >
                  </v-btn>
                </template>
                <span>{{ msg }}</span>
              </v-tooltip>
            </div>
          </div>

        </v-flex>
      </v-layout>
    </v-container>
  </dialog-drag>
</template>

<script>
import { mapActions } from 'vuex'
import DialogDrag from 'vue-dialog-drag'
import '../../theme/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor'
import 'tui-color-picker/dist/tui-color-picker.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';

export default {
  components: {
    editor: Editor,
    DialogDrag
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      isDraw : false,
      loadingSum: false,
      show : false,
      msg : "",
      editorOptions: {
        hideModeSwitch: true,
        plugins: [[colorSyntax]],
      },
      dialogOpts: {
        width: '650px',
        height: '480px',

        buttonPin: false,
        buttonClose: false
      },
    }
  },
  methods : {
    ...mapActions(['getSample', 'setDoctorComment']),

    async save(){
      this.loaderSum = "loading";
      const l = this.loaderSum
      this[l] = !this[l];
      this.loaderSum = true;

      let html = this.$refs.commentEditor.invoke('getHTML');
      if(html === null || html === undefined || html.length <= 1) {
        this.callNoti("입력한 데이터가 없습니다.", false);
        this.loaderSum = false;
        this[l] = false;
        return;
      }
      await this.setDoctorComment(html);

      this.callNoti("저장 되었습니다.", true);

      this.loader = false;
      this[l] = false;

    },
    close(){
      this.$store.commit('setDoctorDialog', false);
    },
    open(){
      this.$store.commit('setDoctorDialog', true);
    },
    callNoti(msg, isSucc=false){
      this.show = true;
      this.msg = msg;
      setTimeout( ()=>{
        this.show = false
        if(isSucc) this.close();
      }, 1000);
/*
      this.$notify({
        type: level,
        group: 'noti',
        title: '알림',
        text: msg
      });
*/
    },
    drag (id){
      console.log(id)
      this.dialogOpts.x = id.x;
    },
    setHtml(htmlStr) {
      this.$refs.commentEditor.invoke('setHTML', htmlStr);
    },
    load(id){
      console.log(id)
      id.x = 200;
      id.top = 200;
    },
    autoSaveMemory() {
      let html = this.$refs.commentEditor.invoke('getHTML');
      this.$store.state.backupDoctorHtml = html;
    },
    changeXPosition () {
      this.dialogOpts.x = Math.abs(window.innerWidth - parseInt(this.dialogOpts.width));
      this.autoSaveMemory();
    },
    changeYPosition () {
      this.autoSaveMemory();
      if(this.doctorDialog){
        let id;
        id = setTimeout(()=> {
          this.close();
          clearTimeout(id);
          id = setTimeout(()=> {
            clearTimeout(id);
            this.open();
          },0)
        },0)
        this.dialogOpts.y = Math.abs(window.pageYOffset);
      }
    },
  },
  mounted () {
    window.addEventListener('resize', this.changeXPosition);
    window.addEventListener("scroll", this.changeYPosition);
    this.changeXPosition();
    this.changeYPosition();
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.changeXPosition);
    window.removeEventListener("scroll", this.changeYPosition);
    let html = this.$refs.commentEditor.invoke('getHTML');
    this.$store.state.sample.comment = html;
  },
  computed : {
    doctorDialog () {
      return this.$store.state.dashBoard.doctorDialog;
    },
    htmlStr : {
      get(){
        if(this.$store.state.backupDoctorHtml !== undefined &&
            this.$store.state.backupDoctorHtml.length > 0 )
        {
          return this.$store.state.backupDoctorHtml;
        }

        return this.$store.state.sample.comment;
      }
    }
  }
}
</script>

<style scoped>
.dialog {
  background-color: #f6f6f6;
}
</style>