<template>
  <v-app id="inspire">
    <div class="app-container" v-if="isRender">
      <DialogEditor title="summary" ></DialogEditor>
      <DialogEditorDoctor title="doctor" ></DialogEditorDoctor>
      <toolbar @toggleNavigationBar="drawer = !drawer"/>
      <navigation :toggle="drawer"/>
      <v-main>
        <breadcrumbs />
        <router-view :key="$route.fullPath"></router-view>
        <v-overlay :value="overlayLoading">
          <v-progress-circular
              indeterminate
              size="64"
          ></v-progress-circular>
        </v-overlay>
      </v-main>



    </div>
    <div v-else>
      <v-row justify="center">
        <v-dialog
            v-model="isFirstSelectedPopup"
            persistent
            max-width="380px"
        >
          <v-card>
            <v-card-title>
            </v-card-title>
            <v-card-text>
              <v-container>
                <v-row>
                  <v-col cols="12">
                    <vs-select
                        autocomplete
                        class="selectExample"
                        v-model="code"
                        style="width: 300px"
                        label="SAMPLE ID 를 선택해 주세요.*"
                    >
                      <vs-select-item :key="index" :value="item.value" :text="item.text"
                                      v-for="(item,index) in codeList"/>
                    </vs-select>
                  </v-col>
                </v-row>
              </v-container>
              <small>*필수 선택 입니다.</small>
            </v-card-text>
          </v-card>
        </v-dialog>
      </v-row>
    </div>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import DialogEditor from '@/components/dash/DialogEditor'
import DialogEditorDoctor from '@/components/dash/DialogEditorDoctor'
export default {

  components: {
    DialogEditor,
    DialogEditorDoctor,
    toolbar: () => import('./dash/Toolbar'),
    navigation: () => import('./dash/NavigationDrawer'),
    breadcrumbs: () => import('./dash/Breadcrumbs'),
    //pageFooter: () => import('./dash/PageFooter'),

  },
  props: {
    source: String,
  },

  data: () => ({
    isRender: false,
    drawer: true,
    direction: 'top',
    fling: false,
    hover: false,
    tabs: null,
    top: false,
    right: false,
    bottom: false,
    left: true,
    transition: 'slide-y-reverse-transition',
    showUserSetting: false
  }),
  methods : {
    ...mapActions(['getSelectList', 'getCodeList']),
    comment(){
      location.href="#comment";
    },
    inputScore(){
      location.href="#score";
    },
  },
  mounted() {
    //this.getSelectList();
  },
  computed: {
    ...mapGetters(['getIsAuth','overlayLoading']),
    codeList() {
      return this.$store.state.codeList;
    },
    isFirstSelectedPopup: {
      get() {
        return this.$store.state.isFirstSelectedPopup;
      },
      set(data) {
        this.$store.state.isFirstSelectedPopup = data;
      }
    },
    code : {
      get() {
        return this.$store.state.code
      },
      set(code) {
        this.isRender = true;
        //TODO 샘플 코드 처리 리펙토링 필요
        this.$store.commit('setCode', code);
        this.$store.state.oldCode = code;
        this.$store.state.sample.code = code;

        // 코드가 변경 된 후 관련 차트 테이터를 가져 올때 까지 처리를 기다려야 함.
        //this.$store.commit("setOverlayLoading", true);
        //this.$router.push({ path : "/main/dashboard", hash: '' })
        this.$store.dispatch('getSample', '').then( () => {
          this.$router.push({ path : "/main/dashboard", hash: '' })
          this.$store.commit('setIsFirstSelectedPopup', true);
          //this.$store.commit("setOverlayLoading", false);
          console.log("[Done][first layer getSample]");
        });
      }
    },
  },
  created () {
    this.$store.commit('setCommentDialog', false);
    //this.getCodeList();

    //TODO Admin 권한 사용만 사용 ... 실제 Backend 에서도 권한을 적용해야 함.
    if(this.$store.state.user.level === 0){
      this.showUserSetting = true;
    }

    // 초기 Code 가 설정 되지 않았을때 사용
    if(!this.$store.state.sample.code){
      this.getCodeList();
      this.isFirstSelectedPopup = true;
      this.isRender = false;
    }else{
      this.isFirstSelectedPopup = false;
      this.isRender = true;
    }
    // this.$vuetify.theme.dark = true
  },
}
</script>
<style scoped>
  /* This is for documentation purposes and will not be needed in your application */
#create .v-speed-dial {
  position: absolute;
}

#create .v-btn--floating {
  position: relative;
}

</style>