import '@babel/polyfill'
import 'mutationobserver-shim'
import Vue from 'vue'
import App from './App.vue'
//import Login from './components/Login.vue'
import router from './router'
import store from './store'
//import VueNativeSock from 'vue-native-websocket'

/*import 'vue-search-select/dist/VueSearchSelect.css'*/

import ECharts from 'vue-echarts/components/ECharts'

import 'echarts/lib/chart/bar'
import 'echarts/lib/chart/line'
import 'echarts/lib/chart/pie'
import 'echarts/lib/chart/scatter'
import 'echarts/lib/chart/effectScatter'
import 'echarts/lib/chart/gauge'
import 'echarts/lib/chart/radar'
import 'echarts/lib/chart/candlestick'
import 'echarts/lib/chart/boxplot'
import 'echarts/lib/component/title'
import 'echarts/lib/component/legend'
import 'echarts/lib/component/toolbox'
import 'echarts/lib/component/tooltip'
import 'echarts/lib/component/markLine'
import 'echarts/lib/component/markArea'
import 'echarts/lib/component/markPoint'
import 'echarts/lib/component/graphic'
import Vuesax from 'vuesax'

import 'vuesax/dist/vuesax.css' //Vuesax styles
import 'material-icons/iconfont/material-icons.css';
import 'bootstrap/dist/css/bootstrap.css'
import Notifications from 'vue-notification'

//import 'echarts/lib/component/se'
import customShine from './theme/shine.project.json'

import vuetify from './plugins/vuetify';

if (process.env.NODE_ENV !== 'production') {
  Vue.config.devtools = true
}

Vue.config.productionTip = false


ECharts.registerTheme("shine", customShine.theme)

Vue.component('v-chart', ECharts);

Vue.use(Vuesax, {
    // options here
})

Vue.use(require('vue-moment'));
Vue.use(Notifications)

new Vue({
  router,
  store,
  vuetify,
  render: function (h) { return h(App) }
}).$mount('#app')
