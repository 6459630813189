<template>

  <dialog-drag v-if="summaryDialog" class="dialog" id="dialog-1" title="Summary" :options="dialogOpts" @drag-end="drag">
    <v-container>
      <v-layout>
        <v-flex  lg12 sm12 xs12>
          <div style="width:600px;height:470px;bottom:10px;padding:40px;border:1px solid #ccc;">
            <h5> Summary 를 작성해 주세요.(리포트 생성시 필수) </h5>
            <hr class="hr1">
            <editor
                ref="summaryEditor"
                :initialValue="htmlStr"
                :options="editorOptions"
                height="300px"
                initialEditType="wysiwyg"
                previewStyle="tab"
            />
            <v-card-actions>
              <v-icon>
                mdi-tag
              </v-icon>
              <v-text-field
                  label="테그 입력"
                  placeholder="ex) 당뇨,비만,과체중"
                  v-model="tag"
              > </v-text-field>
              <v-spacer></v-spacer>
              <v-btn
                  :loading="loadingSum"
                  :disabled="loadingSum"
                  color="rgb(140, 193, 82)"
                  @click="save"
              >
                save
                <v-icon right dark
                >
                  mdi-content-save
                </v-icon>
              </v-btn>
              <v-btn
                  :loading="loadingSum"
                  :disabled="loadingSum"
                  color="rgb(140, 193, 82)"
                  @click="close"
              >
                close
                <v-icon right dark
                >
                  mdi-close
                </v-icon>
              </v-btn>
            </v-card-actions>
            <div style="margin-top:2px;margin-left: 220px">
              <v-tooltip
                  v-model="show"
                  top
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-btn
                      icon
                      v-bind="attrs"
                      v-on="on"
                  >
                  </v-btn>
                </template>
                <span>{{ msg }}</span>
              </v-tooltip>
            </div>
          </div>

        </v-flex>
      </v-layout>
    </v-container>
  </dialog-drag>
</template>

<script>
import { mapActions } from 'vuex'
import DialogDrag from 'vue-dialog-drag'
import '../../theme/codemirror.css';
import '@toast-ui/editor/dist/toastui-editor.css';
import { Editor } from '@toast-ui/vue-editor'
import 'tui-color-picker/dist/tui-color-picker.css';
import colorSyntax from '@toast-ui/editor-plugin-color-syntax';

export default {
  components: {
    editor: Editor,
    DialogDrag
  },
  props: {
    title: {
      type: String,
      required: true
    },
    subTitle: {
      type: String,
      required: false
    }
  },
  data() {
    return {
      backupSummaryHtml : "",
      isDraw : false,
      loadingSum: false,
      show : false,
      msg : "",
      editorOptions: {
        hideModeSwitch: true,
        plugins: [[colorSyntax]],
        style : { fontSize: '20px'},
      },
      dialogOpts: {
        width: '650px',
        height: '480px',
        y : 57,
        x : 0,
        buttonPin: false,
        buttonClose: false
      },
    }
  },
  methods : {
    ...mapActions(['getSample', 'setDoctorComment', 'setSummary']),
    async save(){
      this.loaderSum = "loading";
      const l = this.loaderSum
      this[l] = !this[l];
      this.loaderSum = true;

      let html = this.$refs.summaryEditor.invoke('getHTML');
      if(html === null || html === undefined || html.length <= 1 ) {
        this.callNoti("입력한 데이터가 없습니다.", false);
        this.loaderSum = false;
        this[l] = false;
        return;
      }

      if(!_checkTag(this.tag)){
        this.callNoti("테그 입력시 단어의 끝에 ','(쉼표)가 입력 되어야 합니다.", false);
        this.loaderSum = false;
        this[l] = false;
        return;
      }

      await this.setSummary( { html: html, tag: this.tag } );

      this.callNoti("저장 되었습니다.", true);

      this.loader = false;
      this[l] = false;

      //Tag 에 마지막 데이터는 꼭 ,(쉼표)로 끝나야 함.
      function _checkTag(str){
        if(str && str.length > 0){
          let strArr = str.split("");
          return strArr[str.length-1] === ",";
        }

        return true;
      }

    },
    close(){
      this.clearBackSummary();
      this.$store.commit('setSummaryDialog', false);
    },
    clearBackSummary() {
      if(this.$store.state.sample.backupSummaryHtml !== undefined && this.$store.state.sample.backupSummaryHtml.length > 0 ) {
       this.$store.state.sample.backupSummaryHtml = '';
      }
    },
    open(){
      this.$store.commit('setSummaryDialog', true);
    },
    callNoti(msg, isSucc=false){
      this.show = true;
      this.msg = msg;
      setTimeout( ()=>{
        this.show = false
        if(isSucc) this.close();
      }, 1000);
/*
      this.$notify({
        type: level,
        group: 'noti',
        title: '알림',
        text: msg
      });
*/
    },
    setHtml(htmlStr) {
      this.$refs.summaryEditor.invoke('setHTML', htmlStr);
    },
    drag (id){
      console.log(id)
      this.dialogOpts.x = id.x;
    },
    changeXPosition () {
      console.log("??? " + Math.abs(window.innerWidth - parseInt(this.dialogOpts.width)) );
      this.dialogOpts.x = Math.abs(window.innerWidth - parseInt(this.dialogOpts.width));
      this.autoSaveMemory();
    },
    autoSaveMemory() {
      let html = this.$refs.summaryEditor.invoke('getHTML');
      this.$store.state.backupSummaryHtml = html;
      console.log("!!! auto " + this.$store.state.backupSummaryHtml)
    },
    changeYPosition () {
      this.autoSaveMemory();
      if(this.summaryDialog){
        let id;
        id = setTimeout(()=> {
          this.close();
          clearTimeout(id);
          id = setTimeout(()=> {
            clearTimeout(id);
            this.open();
          },0)
        },0)
        this.dialogOpts.y = Math.abs(window.pageYOffset);
      }

    }

  },
  mounted () {
    console.log("this.dialogOpts.y ", this.dialogOpts.y )
    window.addEventListener('resize', this.changeXPosition);
    window.addEventListener("scroll", this.changeYPosition);
    this.changeXPosition();
    this.changeYPosition();
  },
  beforeDestroy () {
    window.removeEventListener('resize', this.changeXPosition);
    window.removeEventListener("scroll", this.changeYPosition);
    let html = this.$refs.summaryEditor.invoke('getHTML');
    this.$store.state.sample.summary = html;
  },
  computed : {
    summaryDialog () {
      return this.$store.state.dashBoard.summaryDialog;
    },
    tag: {
      get() {
        return this.$store.state.sample.tag;
      },
      set(tagStr) {
        this.$store.state.sample.tag = tagStr;
      }
    },
    htmlStr : {
      get(){
        if(this.$store.state.backupSummaryHtml !== undefined &&
            this.$store.state.backupSummaryHtml.length > 0 )
        {
          return this.$store.state.backupSummaryHtml;
        }

        return this.$store.state.sample.summary;
      }
    }
  }
}
</script>

<style scoped>
.dialog {
  background-color: #f6f6f6;
}
</style>