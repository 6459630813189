<template>
  <v-app id="inspire">
    <div class="app-container">
        <!--    <router-link to="/dashboard" >dashboard</router-link>
            <router-link to="/dashboard/total" >total</router-link>
            <router-link to="/dashboard/scoring" >scoring</router-link>-->
        <router-view :key="$route.fullPath" />
    </div>
  </v-app>
</template>

<script>
export default {
  name: 'App',
  mounted () {
    console.log("Mounted App ")
  },

  created () {
    console.log("Created App ")
  }

}
</script>