import Vue from 'vue'
import Vuex from 'vuex'
import { USER, IS_AUTH, ERROR_STATE, LOGIN, CHECK_AUTH } from './storeConstants'
import axios from 'axios'
import EventBus from '@/components/EventBus'
import qs from 'qs'

Vue.use(Vuex)


const getters = {
  getMemId : state => state.memId,
  getErrorState : state => state.errorState,
  getIsAuth: state => state.isAuth,
  overlayLoading: state => state.overlayLoading,
}

export default new Vuex.Store({
  state: {
    dashBoard : {
      noCommentList : [],
      noSummeryList : [],
      doctorDialog : false,
      summaryDialog : false
    },
    //로그인 후 처음 선택하는 코드 팝업 실행 여부
    isFirstSelectedPopup : true,
    overlayLoading : false,
    selectedIndex : 0,
    menuGroupActiveIndex :[],
    aibTotal : {
      user : 0,
      avg  : 0
    },
    microbesPieChartData: {
      avg: {},
      user: {}
    },
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: 'https://demos.creative-tim.com/material-dashboard/assets/img/sidebar-1.jpg',
    drawer: null,
    user: '',
    errorState: '',
    isAuth: false,
    code : "",
    isAdult : true,
    oldCode : '',
    codeList : [],
    totalRespData : {},
    FOOD_URL: 'https://analytics.aibiotics.kr/img/food/',
    starScore : {
      defaultValue : 4,
      surveyScore: {

      },
      chart : {
        origin : {},
        bg : {}
      },
      chartScore: {},
      index : {
        gut: 0,
        immunity : 0,
        obesity: 0,
      },
      diversity : {
        userScore : 0,
        maxScore: 0,
        minScore: 0
      }
    },
    starChart: {
    },
    report : {
      starChart: {
        adjustedStarScore : [],
      },
      disease: 0
    },
    lactoDetail : {},
    speciesIndexChart : {},
    speciesTableData : {},
    speciesBarLineChart : {},
    species: {
      code : '',
      sortOrderForGut: [
        { field: 'bindo', direction: 'desc', key : 'gut' }
      ],
      sortOrderForImmunity: [
        { field: 'bindo', direction: 'desc', key : 'immunity' }
      ],
      sortOrderForObesity: [
        { field: 'bindo', direction: 'desc', key : 'obesity' }
      ],
      sortOrderForAtopy: [
        { field: 'bindo', direction: 'desc', key : 'atopy' }
      ],
      sortOrderForAdult: [
        { field: 'bindo', direction: 'desc', key : 'adult' }
      ],
      sortOrderForEtc: [
        { field: 'bindo', direction: 'desc', key : 'etc' }
      ],
      sortOrderForFemale: [
        { field: 'bindo', direction: 'desc', key : 'female' }
      ],
      onRowClass(dataItem) {
        //if(dataItem.microbes != null && dataItem.microbes === 'BAD')  return 'bg-light text-danger';
        if(dataItem.isBad)  return 'bg-light text-danger';
      },
      fields : [ 'Phylum',  'Genus', 'Specice',
        {
          name: 'comment',
          title : '효과 및 관련성',
          titleClass: 'text-center',
          dataClass: 'align-middle',
        },
        {
          name: 'bindo',
          title : '빈도',
          sortField : 'bindo',
          dataClass: 'align-middle text-center',
        },
        {
          name: 'result',
          title : '결과',
          width : '128px',
          titleClass: 'text-center',
          dataClass: 'align-middle text-center',
        },
        {
          name: 'microbes',
          visible: false
        },

      ],
    },
    balance : {
      gutBalanceGaugeData: {},
      immunityBalanceGaugeData: {},
      obesityBalanceGaugeData: {},
      atopyBalanceGaugeData: {},
      adultBalanceGaugeData: {},
      femaleBalanceGaugeData: {},
      etcBalanceGaugeData: {},
      ageBalanceGaugeData: {},
      totalBalanceGaugeData: {}
    },
    survey : {},
    sleepChartData: {},
    heightChartData: {},
    weightChartData: {},
    menu: {
      foodList : ["백미", "잡곡", "즉석", "죽", "된장국","콩나물국","고깃국","미역국","김치찌개","된장찌개","부대찌개","해물탕","콩나물","진미채","산나물","감자볶음","새우볶음","제육볶음","햄_소세지_볶음","장조림","고구마순","연근조림","멸치조림","배추김치","깍두기","열무김치","갓김치","쌈채소","샐러드","브로콜리","겉절이","쌈장","간장_와사비","고추장","케첩","소불고기","삼겹살","생선구이","계란후라이","짜장면","볶음밥","카레","라면","떡","케이크","빵","고구마"],
      foodTableList: [],
      initialSelected: [],
    },
    tastesList: {
      type1: {},
      type2: {}
    },
    aibChart1: {},
    aibChart2: {},
    aibChart3: {},

    //나이별 다양성
    byAgeDiversity: {},
    freqDistributionListChart: {},
    byAgeDiversityBySpecies: {},
    freqDistributionListBySpeciesChart: {},
    byAgeDiversityByPhylum: {},
    freqDistributionListByPhylumChart: {},
    byAgeDiversityByGenus: {},
    freqDistributionListByGenusChart: {},

    // 연령 분포에서 사용
    selectList : {
      phylumList : [],
      genusList : [],
      speciesList : []
    },

    focusPageNm : "none",
    phylum : {
      userPieData: {},
      avgPieData:{}
    },
    reportPhylum :{},
    reportGenus :{},
    enterotypePieChart : {},
    sample : {},
    selectedDiversity : {
      genus : '',
      phylum : '',
      species : ''
    },
    phylumExcessIndexList : [],
    backupSummaryHtml : '',
    backupDoctorHtml : ''
  },
  mutations: {
    setIsFirstSelectedPopup(state, data){
      state.isFirstSelectedPopup = data;
    },
    setDoctorDialog(state, data){
      state.dashBoard.doctorDialog = data;
    },
    setSummaryDialog(state, data){
      state.dashBoard.summaryDialog = data;
    },
    setNoCommentList(state, data){
      state.dashBoard.noCommentList = data;
    },
    setNoSummaryList(state, data){
      state.dashBoard.noSummeryList = data;
    },
    setDiversitySelectPhylum(state, data){
      state.selectedDiversity.phylum = data;
    },
    setDiversitySelectSpecies(state, data){
      state.selectedDiversity.species = data;
    },
    setDiversitySelectGenus(state, data){
      state.selectedDiversity.genus = data;
    },
    setSelectedIndex(state, data){
      state.selectedIndex = data;
    },
    setMenuGroupActiveIndex(state, data){
      state.menuGroupActiveIndex = data
    },
    setOverlayLoading(state, data){
      state.overlayLoading = data;
    },
    setPiePhylum (state, data) {
      state.phylum = data;
    },
    setAibTotal(state, data){
      state.aibTotal.user = data.user;
      state.aibTotal.avg = data.avg;
    },
    setAib1Chart(state, data){
      state.aibChart1 = data;
    },
    setAib2Chart(state, data){
      state.aibChart2 = data;
    },
    setAib3Chart(state, data){
      state.aibChart3 = data;
    },
    setMicrobesPieChartData(state, data) {
      state.microbesPieChartData.avg = data.avg;
      state.microbesPieChartData.user = data.user;
    },
    setCodeList(state, list){
      state.codeList = list;
    },
    setTastesList (state, data){
      state.tastesList.type1 = data.tastesList1;
      state.tastesList.type2 = data.tastesList2;
    },
    setMenu (state, data){
      state.menu.initialSelected = data.initialSelected;
      state.menu.foodTableList = data.foodTableList;
    },
    setMenuInitialSelected (state, data){
      state.menu.initialSelected = data;
    },
    setMenuFoodTableList(state, data){
      state.menu.foodTableList = data;
    },
    setSleep (state, data){
      state.sleepChartData = data ;
    },
    setAdult (state, isAdult){
      state.isAdult = isAdult;
    },
    selectList (state, data){
      state.selectList = data;
    },
    setByAgeDiversity (state, data){
      state.byAgeDiversity = data;
    },
    setFreqDistributionListChart (state, data){
      state.freqDistributionListChart = data;
    },
    setByAgeDiversityBySpecies (state, data){
      state.byAgeDiversityBySpecies = data;
    },
    setFreqDistributionListBySpeciesChart (state, data){
      state.freqDistributionListBySpeciesChart = data;
    },
    setByAgeDiversityByPhylum (state, data){
      state.byAgeDiversityByPhylum = data;
    },
    setFreqDistributionListByPhylumChart (state, data){
      state.freqDistributionListByPhylumChart = data;
    },
    setByAgeDiversityByGenus (state, data){
      state.byAgeDiversityByGenus = data;
    },
    setFreqDistributionListByGenusChart (state, data){
      state.freqDistributionListByGenusChart = data;
    },


    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    [USER] ( state, user ) {
      state.user = user
    },
    [ERROR_STATE] (state, errorState) {
      state.errorState = errorState
    },
    [IS_AUTH] (state, isAuth) {
      state.isAuth = isAuth
    },
    setCode(state, code) {
      state.code = code
    },
    //TODO 추후 항목별 데이터 분리 필요
    setLactoDetail(state, lactoDetail){
      state.lactoDetail = lactoDetail;
    },
    setStartChartData(state, chartData){
      state.report.starChart = chartData;
    },
    setStartChart(state, chartObj){
      state.starChart = chartObj;
    },
    setDisease(state, score){
      state.report.disease = score || 0;
    },
    setSpecies(state, data){
      state.species.code = data.code;
      state.species.tableData = data.tableData;
      state.species.indexChart = data.indexChart;
      state.species.barLineChart = data.barLineChart;
    },
    setSpeciesIndexChart(state, data) {
      state.speciesIndexChart = data;
    },
    setSpeciesTableData(state, data) {
      state.speciesTableData = data;
    },
    setSpeciesBarLineChart(state, data) {
      state.speciesBarLineChart = data;
    },
    setSurvey(state, data){
      state.survey = data;
    },
    setReportPhylum(state, data){
      state.reportPhylum = data;
    },
    setReportGenus(state, data){
      state.reportGenus = data;
    },
    setEnterotypePieChart(state, data){
      state.enterotypePieChart = data;
    },
    setBalance(state, data){
      state.balance = data;
    },
    setSample(state, data){
      state.backupDoctorHtml = '';
      state.backupSummaryHtml = '';
      state.sample = data;
    },
    setBackupDoctorHtml(state, data) {
      state.backupDoctorHtml = data;
    },
    setStarDiversity(state, data){
      state.starScore.diversity = data;
    },
    setStarSurveyScore(state, data){
      state.starScore.surveyScore = data;
    },
    // 키 성장 그래프 데이터
    setHeightChartData(state, data){
      state.heightChartData = data;
    },
    // 몸무게 성장 그래프 데이터
    setWeightChartData(state, data){
      state.weightChartData = data;
    },
    setPhylumExcessIndex(state, data){
      state.phylumExcessIndexList = data;
    }

  },
  actions: {
    hashSearch: async (store, searchData) => {
      console.log(searchData)
      try {
        let resp = await axios.post(`/v1/hash/search`, searchData);
        //console.log("[hashSearch] %s", JSON.stringify(resp));
        if (resp.data.data.isSucc) {
          return resp.data.data;
        } else {
          alert("검색 조건에 문제가 있습니다. 다시 한번 확인해 주세요.");
        }
        return {};

      } catch (e){
        console.log("[hashSearch] err=%s", e);
        alert("검색에 실패 하였습니다.");
        return {};
      }
    },
    personalSearch: async (store, searchData) => {
      console.log(searchData)
      try {
        let resp = await axios.post(`/v1/personal/search`, searchData);
        //console.log("[hashSearch] %s", JSON.stringify(resp));
        if (resp.data.data.isSucc) {
          return resp.data.data;
        } else {
          alert("처리중 오류가 발생 하였습니다. 다시 시도 해 주세요.");
        }
        return {};

      } catch (e){
        console.log("[hashSearch] err=%s", e);
        alert("검색에 실패 하였습니다.");
        return {};
      }
    },
    getHashTagList: async (store) => {
      let tagList = [];
      try {
        let resp = await axios.get(`/v1/hash/tagList`);
        tagList = resp.data.data || [];
        //tagList.push("샘플군 외 모든 대상(대조군)")
      }catch (e){
        console.error("[getHashTagList]", e);
        alert("해시테그 리스트를 가져 오는데 실패 하였습니다.");
        return [];
      }
      return tagList;
    },
    //Phylum 과잉 지수
    getPhylumExcessIndex : async (store) => {
      try {
        let resp = await axios.get(`/v1/excess-index/phylum/${store.state.code}`);
        //console.log("[getPhylumExcessIndex] %s", JSON.stringify(resp));

        if(resp && resp.data.code === 200){
          let user = resp.data.data[0]
          let avg = resp.data.data[1]
          let logAvg = {}
          Object.keys(user).reduce( (acc, key) => {
            if(key === "TYPE" || key === "title") return acc;
            user[key] = Math.log10(user[key]).toFixed(2) || 0;
            avg[key] = Math.log10(avg[key]).toFixed(2) || 0;
            logAvg[key] = (user[key]/avg[key]).toFixed(2) || 0
          }, 0)

          resp.data.data.push(logAvg);
          logAvg.title = "과잉지수";
          //console.log("kkkkk %s ", JSON.stringify(logAvg) ) ;

          store.commit('setPhylumExcessIndex', resp.data.data)
        }else {
          store.commit('setPhylumExcessIndex', [])
          alert("Phylum 과잉 지수 데이터를 가져오는데 실패 하였습니다.");
        }

      } catch (e) {
        console.error("[getPhylumExcessIndex] errMsg=%s", e);
        //TODO 공통 Notification 생성 필요
        alert("Phylum 과잉 지수 데이터를 가져오는데 실패 하였습니다.");
      }
    },
    //code List main 진입 시 한번만 호출 되어야 함.
    getCodeList: async (store) => {

      axios.get('/v1/code').then( res => {
        let codeList = [];
        if(res.data.code === 200) {
          console.log("/v1/code :: [%d]", res.data.code)
          let data = res.data.data;

          let noCommentList = [];
          let noSummaryList = [];
          data.forEach((ele) => {
            codeList.push({text: `${ele.code}/${ele.flag}/${ele.name}/${ele.regDt || 'none'}`, value: ele.code })
            if( !ele.commentLen && ele.commentLen <= 0) noCommentList.push(ele);
            if( !ele.summaryLen && ele.summaryLen <= 0) noSummaryList.push(ele);
          })
          console.log(" noCommentList=%s, noSummaryList=%s", noCommentList.length, noSummaryList.length);
          store.commit("setCodeList", codeList);
          store.commit("setNoCommentList", noCommentList);
          store.commit("setNoSummaryList", noSummaryList);
          store.dispatch('getSample');
        }
      }).catch( e => {
        console.error(e);
      })

    },
    [LOGIN] : async ( store, { id, password } ) => {

      const params = new URLSearchParams();
      params.append('emailID',  id);
      params.append('pwd', password);

      let resp;
      try {

        let AuthHeaders = {
          'Content-Type': 'application/x-www-form-urlencoded',
        }

        resp = await axios.post('/v1/auth', params, AuthHeaders);
        if(resp.data.code === 200) {
          store.commit(IS_AUTH, true) // 인증 성공
          store.commit(USER, resp.data.user)//ID 입력
          store.dispatch('getSelectList'); //인증 성공시 종별 Select box 리스트 미리 읽어 오기
        }
        console.log("resp > ", resp);
        return resp;

      }catch(e){
        console.error(e)
        store.commit(USER, "") //ID 입력
        store.commit(IS_AUTH, false) // 인증 실패
        //toast('인증 실패', 'error')
        return false
      }
    },
    [CHECK_AUTH] : async ( store,  ) => {
      let resp;
      try {

        resp = await axios.post('/v1/checkAuth');
        if(resp.data.code === 200 ) {
          store.commit(IS_AUTH, true) // 인증 성공
        }
        console.log("resp > ", resp);
        return resp;

      }catch(e){
        console.error(e)
        store.commit(IS_AUTH, false) // 인증 실패
        return false
      }
    },
    lactoDetail : async (store ) => {
      try{
        /*
                let bifi = await axios.get("/v1/age/diversity/genus/Bifidobacterium");
                let lacto = await axios.get("/v1/age/diversity/genus/Lactobacillus");
                let detailData = await axios.get(`/v1/lacto/detail/${store.state.code}`);
        */

        let promise = await Promise.all([
          axios.get("/v1/age/diversity/genus/Bifidobacterium"),
          axios.get("/v1/age/diversity/genus/Lactobacillus"),
          axios.get(`/v1/lacto/detail/${store.state.code}`)
        ]);

        let lactoDetail = {
          diversity : {
            bifi : (promise[0].data.code == 200)?await getLactoGenusChartData(store, promise[0].data.data):{},
            lacto : (promise[1].data.code == 200)?await getLactoGenusChartData(store, promise[1].data.data):{}
          },
          speciesDetail : {
            bifi : await getLactoSpeciesDetailChart(promise[2].data.data.Bifidobacterium, 'bifi'),
            lacto : await getLactoSpeciesDetailChart(promise[2].data.data.Lactobacillus, 'lacto')
          }
        }

        store.commit('setLactoDetail', lactoDetail);

        return lactoDetail;

      }catch (e){
        console.error(e);
      }

    },
    speciesAction : async (store) => {

      if(store.state.code === store.state.species.code) return;
      console.log("Call species api ", store.state.code)
      let respSpecies = await axios.get(`/v1/species/${store.state.code}`);
      if(respSpecies === null || respSpecies.data.code !== 200 ) alert("!서버오류");
      let speciesData =  await createSpeciesChartAndTableData(respSpecies.data.data)
      console.log("Commit !!!!! Species api ", store.state.code, speciesData);

      /*
            species.tableData = speciesData.tableData;
            species.indexChart = speciesData.indexChart;
            species.barLineChart = speciesData.barLineChart;
            species.code = respSpecies.data.sampleId ;
      */
      speciesData.code = respSpecies.data.sampleId;

      store.commit('setSpecies', speciesData);

      store.commit('setSpeciesIndexChart', speciesData.indexChart);
      store.commit('setSpeciesTableData', speciesData.tableData);
      store.commit('setSpeciesBarLineChart', speciesData.barLineChart);

    },
    getSample : async (store) => {
      console.log("#ex0Call getSample %s", store.state.code);
      store.commit("setOverlayLoading", true);

      if(store.state.code.length <= 0) return;
      let sampleData = await axios.get(`/v1/sample/${store.state.code}`);
      if(sampleData === null || sampleData.data.code !== 200 ) {
        alert("Sample 데이터가 없거나 오류가 있습니다. ");
        return;
      }

      store.commit('setSample', sampleData.data.data);
      //열린 팝업 닫기
      store.commit('setSummaryDialog', false);
      store.commit('setDoctorDialog', false);

      try{
        if (sampleData.data.data.age <= 18) store.commit('setAdult', false);
      }catch (e) {
        // 문진이 없는 경우 분석용 이므로 age 가 없기 때문에 예외 처리
        let sampleData = {
          code: store.state.code,
          name: "",
          age: 0,
          sex: "",
          disease : 5,
          comment : '',
          summary : '',
          tag: ""
        }
        store.commit('setSample', sampleData);
      }
      await store.dispatch('getTotalChart')
      store.dispatch('getGrowth').then( () => { console.log("[Done][getGrowth]") });
      store.dispatch('speciesAction').then( () => { console.log("[Done][getSpecies]") });
      store.dispatch('getPieChartPhylum').then( () => {
        console.log("[Done][getPieChartPhylum]")
      });
      store.dispatch('lactoDetail').then( () => {
        store.commit("setOverlayLoading", false);
        console.log("[Done][lactoDetail]")
      });

      store.dispatch('changeDiversity').then( () => {})
      // Phylum 과잉 지수
      store.dispatch('getPhylumExcessIndex').then( () => {} )

    },
    changeDiversity: async (store) => {
      // 나이별 다양성 분석
      store.dispatch('getByAgeDiversity').then( ()=> {});
      store.dispatch('getByAgeDiversityPhylum').then( ()=> {});
      store.dispatch('getByAgeDiversityGenus').then( ()=> {});
      store.dispatch('getByAgeDiversitySpecies' ).then( ()=> {});
    },
    /**
     * Genus, Phylum level 공통
     * @param store
     * @param level
     * @returns {Promise<{}>}
     */
    getPhylumChartData : async (store, level) => {
      console.log(level)
      let respData = await axios.get(`/v1/age/diversity/${level}/report`);
      console.log(respData)
      const resultObject = await Promise.all(
          respData.data.data.map(
              async (obj) => {
                let phylum = {
                  name : obj.name,
                };
                phylum.data = await getPhylumChartData(store, obj.data);
                return phylum;
              }
          ));

      let chartData = {};
      resultObject.reduce( (acc,key)=> {
        chartData[key.name] = key.data;
      }, {})

      if(level == "phylum"){
        store.commit('setReportPhylum', chartData);
      }else if(level == "genus"){
        store.commit('setReportGenus', chartData);
      }
    },
    setDoctorComment : async (store, html) => {
      try {
        const uri = `/v1/doctor/comment/${store.state.code}`;
        await axios.put(uri, { comment : html }).then();
        store.state.sample.comment = html;
        store.commit('setSample', store.state.sample);
      } catch (e) {
        alert("Comment 저장 실패");
      }
    },
    setSummary : async (store, payload) => {
      try {
        const uri = `/v1/bio/comment/${store.state.code}`;
        if (typeof payload === "string") {
          await axios.put(uri, { summary : payload }).then();
        } else {
          await axios.put(uri, { summary : payload.html, tag : payload.tag }).then();
        }
        store.state.sample.summary = payload.html;
        store.commit('setSample', store.state.sample);
      } catch (e) {
        alert("Summary 저장 실패");
      }
    },
    getBabyBalanceSummaryData : async (store) => {
      const uri = `/v1/report/baby/bs/${store.state.code}`
      let resp =  await axios.get(uri);
      return getBabyBSByType(store, resp.data.data)
    },
    getTotalChart : async (store) => {

      try {

        store.dispatch("getTotalPhylum").then( () => {
          console.log("[Done][getTotalPhylum]");
        })

        let resp = await axios.get(`/v1/total/${store.state.code}/false`)

        balanceChart(store, resp.data.data.retSpecies).then(() => {
          console.log("[Done][balanceChart]");
        })

        //aib2
        totalLegendPhylumChart(resp.data.data).then( (obj) => {
          store.commit("setAib2Chart", obj);
        })

        //phylum enterotype
        _updateBalanceIndex(resp.data.data).then( obj => {
          store.commit("setEnterotypePieChart", obj);
        })

        let aib1Data = await integrationChart(resp.data.data);
        store.commit("setAibTotal", aib1Data.aibTotal);
        store.commit("setAib1Chart", aib1Data);
        store.commit("setMicrobesPieChartData", aib1Data.pieChart);

      } catch (e) {
        console.error("[getTotalChart]", e)
      }

      //균형지수2 Balance Index (enterotype)
      async function _updateBalanceIndex (data){
        console.log("!!!!!!!", data, store.state.sample.name)


        let color = [  'rgb(164,198,21)', 'rgb(185,185,185)' , "rgb(54,188,218)"];
        let avgData  = {
          name: '평균',
          seriesData : [],
          color : color,
          legend : true,
          type : "avg"
        }

        let userData = {
          name: store.state.sample.name,
          seriesData : [],
          color : color,
          legend : true,
          type : 'user'
        }

        //Code Refactoring 필요
        //Genus 레벨의 Bacteroides, Prevotella, Ruminococcus 의 전체 평균값과 개별값 분류
        data.bar.reduce( (acc, k ) => {
          if(k.genus === 'Bacteroides' && k.type === 'avgData'){
            let obj = { value : k.value, name : k.genus };
            avgData.seriesData.push(obj);
            return acc;
          }
          if(k.genus === 'Bacteroides' && k.type === 'userData'){
            let obj = { value : k.value, name : k.genus };
            userData.seriesData.push(obj);
            return acc;
          }
          if(k.genus === 'Prevotella' && k.type === 'avgData'){
            let obj = { value : k.value, name : k.genus };
            avgData.seriesData.push(obj);
            return acc;
          }
          if(k.genus === 'Prevotella' && k.type === 'userData'){
            let obj = { value : k.value, name : k.genus };
            userData.seriesData.push(obj);
            return acc;
          }
          if(k.genus === 'Ruminococcus' && k.type === 'avgData'){
            let obj = { value : k.value, name : k.genus };
            avgData.seriesData.push(obj);
            return acc;
          }
          if(k.genus === 'Ruminococcus' && k.type === 'userData'){
            let obj = { value : k.value, name : k.genus };
            userData.seriesData.push(obj);
            return acc;
          }
          return acc;

        }, {}) ;

        let avgBindexChartData = _getChartDataForBi(avgData);
        let userBindexChartData = _getChartDataForBi(userData);

        return {
          avg : avgBindexChartData,
          user : userBindexChartData
        }

      } // end enterotype

      //get bi chart data
      function _getChartDataForBi(data) {
        let defaultColor = ['#006400','#497649', '#228B22', '#2E8B57', '#3CB371' ];
        let seriesData = [];
        data.seriesData.reduce( (acc, k) => {
          let obj = {
            value : k.value,
            name : k.name,
            label : {
              show: true,
              fontSize: 12,
              position: 'outside',
              formatter: function (v) {

                return `${v.name}\n  {b|${v.percent}%} `;
              },
              rich : {
                b: {
                  fontSize: 21,
                  color: '#000',
                  fontWeight: 'bold',
                  padding : [1, 0, 0,0],
                  borderColor: '#449933',
                }
              }

            }
          }
          if(obj.name === 'Bacteroides'){
            seriesData[0] = obj
          }else if(obj.name === 'Prevotella'){
            seriesData[1] = obj
          }else if(obj.name === 'Ruminococcus'){
            seriesData[2] = obj;
          }

          return [...acc, obj];
        }, [])

        return {
          legend: {
            show : (data.legend === undefined)?false : data.legend,
            orient: 'horizontal',
            bottom: '-1%'
          },
          grid: {
            left: '20%',
            top: '20%',
            right: '20%',
            bottom: '20%'
          },
          series: [
            {
              color : (data.color===undefined)?defaultColor:data.color,
              name: data.name,
              type: 'pie',
              radius: ['38%', '65%'],
              avoidLabelOverlap: true,
              label: {
                show: true,
                color: "#000",
                position: 'center',
                fontSize: (data.name.length > 6)?'21':'30',
                fontFamily : '고딕',
                fontWeight: 'bold',
                formatter : (data.type === 'avg')?'{b|AVERAGE}\n{a}':'{a}',
                rich : {
                  b: {
                    fontSize: 15,
                    color: '#000',
                    fontWeight: 'bold',
                    padding : [1, 2, 0,0],
                    borderColor: '#449933',
                  }
                }
              },
              labelLine: {
                show: true
              },
              data: [
                {},{}, //오브젝트가 두개여야 가운데와 아웃사이드 label이 동시 붙는다.

                ...seriesData
              ]
            }
          ]
        }
      } //end Chart

    },
    getByAgeDiversity : async (store) => {
      try {
        let uri = "/v1/age/diversity";
        let res = await axios.get(uri);
        //console.log("zzz res %s", JSON.stringify(res) );
        let ret = makeDiversityChartData(store, res.data.data, "byAgeDiversity",
            "freqDistributionList");
        //console.log("zzz ret k%s", JSON.stringify(ret) );


      }catch (e){
        console.error("[getByAgeDiversity]", e);
      }
    },
    getByAgeDiversityPhylumFromApi: async(store, phylumName) => {
      let uri = "/v1/age/diversity/phylum/" + phylumName || 'Actinobacteria';
      console.log("[getByAgeDiversityPhylumAxios] uri=%s", uri);
      try {
        let res = await axios.get(uri);
        return res;
      } catch (e){
        console.error("[getByAgeDiversityPhylumAxios]", e);
      }

      return false;
    },
    getSmileChartDataFromApi: async(store, parameters) => {

      let uri = `/v2/report/smile/${store.state.code}/${parameters.level}/`;
      console.log("[getSmileChartDataFromApi] uri=%s", uri);
      try {
        let res = await axios.get(uri, {
          params: {
            microbeName : parameters.microbeNameList,
            pLevel : parameters.pLevel || ''
          },
          paramsSerializer: params => {
            return qs.stringify(params)
          }
        } );
        return res;
      } catch (e){
        console.error("[getByAgeDiversityPhylumAxios]", e);
      }

      return false;
    },
    getByAgeDiversityPhylum: async (store, selectPhylum) => {
      if(!selectPhylum) selectPhylum = store.state.selectedDiversity.phylum;
      if(selectPhylum.length <= 0) return;
      try {

        let res = await store.dispatch('getByAgeDiversityPhylumFromApi', selectPhylum);
        if (res === false) {
          alert("[getByAgeDiversityPhylumAxios] 데이터를 가져오는데 실패 하였습니다.");
          return;
        }

        let ret = await makeDiversityChartData(store, res.data.data, "byAgeDiversityByPhylum");
        store.commit("setDiversitySelectPhylum", selectPhylum);
        store.commit("setByAgeDiversityByPhylum", ret.chartData);
        store.commit("setFreqDistributionListByPhylumChart", ret.freqDistributionChartData);

      }catch (e){
        console.error("[getByAgeDiversityPhylum]", e);
      }
    },
    getByAgeDiversityPhylumDataList: async (store, seletedPhylum) => {
      try {
        let uri = "/v1/age/diversity/phylum/" + seletedPhylum ;
        console.log("getByAgeDiversityPhylumDataList :: " + uri);
        let res = await axios.get(uri);
        return res.data.data;
      }catch (e){
        console.error("[getByAgeDiversityPhylumDataList]", e);
        alert("getByAgeDiversityPhylumDataList 정보를 가져오는데 실패 하였습니다.");
        return null;
      }
    },
    getByAgeDiversityPhylumChartData: async (store, { code = '', phylumDataList }) => {
      try {
         return await makeDiversityChartDataForCompare(code, phylumDataList, "byAgeDiversityByPhylum");
      }catch (e){
        console.error("[getByAgeDiversityPhylumChartData]", e);
        alert("getByAgeDiversityPhylumChartData 정보를 가져오는데 실패 하였습니다.");
        return null;
      }
    },
    getByAgeDiversityGenus: async (store, selectGenus) => {
      if(!selectGenus) selectGenus = store.state.selectedDiversity.genus;
      if(selectGenus.length <= 0) return;
      try {
        let uri = "/v1/age/diversity/genus/" + selectGenus;
        console.log(uri);
        let res = await axios.get(uri);
        let ret = await makeDiversityChartData(store, res.data.data, "byAgeDiversityByGenus");
        store.commit("setDiversitySelectGenus", selectGenus);
        store.commit("setByAgeDiversityByGenus", ret.chartData);
        store.commit("setFreqDistributionListByGenusChart", ret.freqDistributionChartData);

        return ret;

      }catch (e){
        console.error("[getByAgeDiversityPhylum]", e);
      }
    },
    getByAgeDiversitySpecies: async (store, selectSpecies) => {
      console.log(JSON.stringify(selectSpecies))
      if(!selectSpecies) selectSpecies = store.state.selectedDiversity.species;
      if(selectSpecies.length <= 0) return;
      try {

        let uri;
        if (typeof selectSpecies === "object" ) {
          uri = "/v2/age/diversity/species/" + selectSpecies.genus + "/" + selectSpecies.species;
        } else {
          uri = "/v1/age/diversity/species/" + selectSpecies;
        }
        console.log(uri);
        let res = await axios.get(uri);
        let ret = await makeDiversityChartData(store, res.data.data, "byAgeDiversityBySpecies");
        store.commit("setDiversitySelectSpecies", selectSpecies);
        store.commit("setByAgeDiversityBySpecies", ret.chartData);
        store.commit("setFreqDistributionListBySpeciesChart", ret.freqDistributionChartData);

      }catch (e){
        console.error("[getByAgeDiversityPhylum]", e);
      }
    },
    // genus 와 Species 결합 (이유: 각 종레벨에서 중복된 종이 있기 때문에 레벨 까지 구분 해야 함)
    // TODO 리펙토링 필요
    getByAgeDiversitySpeciesV2: async (store, params) => {
      try {
        let uri = "/v2/age/diversity/species/" + params.genus + "/" + params.species;
        let res = await axios.get(uri);
        let ret = await makeDiversityChartData(store, res.data.data, "byAgeDiversityBySpecies");
        return ret;

      }catch (e){
        console.log("[getByAgeDiversitySpeciesV2]", e);
      }
    },
    getSelectList: async (store) => {
      try {
        let url ="/v1/species";
        let res = await axios.get(url);
        let selectList = {
          phylumList : [],
          genusList : [],
          speciesList : []
        }

        if(res.data.code === 200) {
          console.log("#getSelectList /v1/species ");
          let data = res.data.data;

          data.speciesList.forEach((ele) => {
            selectList.speciesList.push({text: (ele.genus || "__") + (" " + ele.species), value: ele})
          })
          //this.selectSpecies = "acetethylicum";

          data.phylumList.forEach((ele) => {
            if(ele.phylum === null || ele.phylum === "__") return;
            selectList.phylumList.push({text: ele.phylum, value: ele.phylum})
          })
          //this.selectPhylum = "Actinobacteria";

          data.genusList.forEach((ele) => {
            selectList.genusList.push({text: ele.genus, value: ele.genus})
          })
          //this.selectGenus = "Abiotrophia";

          store.commit("selectList", selectList);
        }
      }catch (e){
        console.error("[getSelectList]", e);
      }
    },
    //질병 점수 변경 시 별 차트 다시 생성
    reloadStartChart: async (store) => {
      drawStarChart(1, store).then( obj => {
        store.commit("setStartChart", obj);
        console.log("[Done][reloadStartChart]");
      })
    },
    getGrowth: async (store) => {

      let uri = `/v1/growth/${store.state.code}`
      console.log("[Call][getGrowth] ", uri);
      axios.get(uri).then(async res => {

        if (res.data.code === 200) {

          //store.commit('setAdult', res.data.data.isAdult || true);

          // 키, 몸무게 성장 데이터 처리 후 Start 차트를
          let hw = await Promise.all( [heightChart(res.data.data), weightChart(res.data.data)] );
          store.commit("setHeightChartData", hw[0]);
          console.log("[Done][heightChart][%s][%s]", store.state.code, hw[0].heightPercentile);
          store.commit("setWeightChartData", hw[1]);
          console.log("[Done][WeightChart][%s][%s]", store.state.code, hw[1].weightPercentile);

          let survey = res.data.data.surveyList || [];

          // 문진 내용
          store.commit('setSurvey', survey);

          // *** 별 그래프를 그리기위한 점수 (4가지 항목이 우선 처리 된다. (비만(소아/성인 다름),질병, 식습관, 생활)
          // - 장미생물 연산은 하기 drawStarChart 에서 Balance Index 를 이용하여 연산 한다.
          // 즉, ToTal 차트에서 index 가 Commit 되고,
          store.commit('setStarSurveyScore', res.data.data.starChartScore || {} )

          //별그래프 테이터 생성
          // *** 키와 체중 성장 그래프가 호출 되어서 백분위수가 먼저 수행 되어야 한다.
          drawStarChart(0, store).then( obj => {
            store.commit("setStartChart", obj);
            console.log("[Done][DrawStartChart]");
          })

          menu(store, survey.like_adultfood||'').then( obj => {
            console.log("[Done][Menu] code=%s", store.state.code);
            //store.commit("setMenu", obj);
            store.commit("setMenuFoodTableList", obj.foodTableList);
            store.commit("setMenuInitialSelected", obj.initialSelected);
          })

          // 수면 차트
          sleepChart(res.data.data.sleepList).then( obj => {
            console.log("[Done][sleepChart]", store.state.code);
            store.commit("setSleep", obj);
          })

          try {
            // 선호 음식(식이 취향)
            preferredFood(survey).then( obj => {
              //console.log("[Done][preferredFood]", store.state.code, JSON.stringify(obj));
              console.log("[Done][preferredFood]", store.state.code);
              store.commit('setTastesList', obj);
            })

          }catch (e){
            console.log(e)
          }

        }else {

          store.commit('setAdult', false);
          store.commit("setHeightChartData", {});
          store.commit("setWeightChartData", {});
          store.commit('setSurvey', {});
          store.commit("setMenu", {});
          store.commit("setSleep", {});
          store.commit('setTastesList', {});

        }

      }).catch(e => {
        console.error(e)
      })
    },
    getTotalPhylum: async (store) => {
      let resp = await axios.get(`/v1/total/phylum/${store.state.code}`);
      if (resp.data.code === 200) {
        totalPhylumChart(resp.data.data).then(obj => {
          store.commit("setAib3Chart", obj);
        })
      }
    },
    getPieChartPhylum: async (store) => {
      let resp = await axios.get(`/v1/phylum/${store.state.code}`);
      if (resp.data.code === 200) {
        // Code -> 이름으로 변경
        resp.data.data.userData.name = store.state.sample.name;
        let p = await Promise.all( [
          getPiePhylumChartData(resp.data.data.avgData),
          getPiePhylumChartData(resp.data.data.userData)
        ])

        let piePhylumData = {
          avgPieData: p[0],
          userPieData: p[1]
        }

        store.commit("setPiePhylum", piePhylumData);
      }
    },
    getBalanceChartDataForReport : async (store) => {

      let balance = { ...store.state.balance };
      Object.keys(balance).reduce( (acc, key) => {
        balance[key].series[0].detail.formatter = " ";
        //balance[key].series[1].detail.formatter = " ";
        //if(key === 'totalBalanceGaugeData') balance[key].series[1].detail.formatter = " ";
        let centerPos = (key === 'totalBalanceGaugeData')?'60%':'75%';
        balance[key].series[0].center[1] = centerPos ;
        balance[key].series[1].center[1] = centerPos ;
        delete balance[key].toolbox;
      }, {})
      return balance;
    }

  },
  modules: {
  },
  getters
})

/**
 * GET Pie Phylum Chart
 * @param data
 */
async function getPiePhylumChartData(data) {
  let seriesData = data.seriesData.reduce( (acc, k) => {
    if(k.value <= 0 || k.value <= 0.001) return acc;
    let obj = {
      value : k.value,
      name : k.name,
      itemStyle: {
        barBorderColor: _getPiePhylumColor(k.name),
        color: _getPiePhylumColor(k.name),
      },
      label : {
        show: true,
        fontSize: 12,
        position: 'outside',
        formatter: function (v) {
          return `${v.name}\n ${v.percent}% `;
        },
        rich: data.rich

      }
    }

    return [...acc, obj];
  }, [])

  return {
    legend: {
      show : (data.legend === undefined)?false : data.legend,
      orient: 'horizontal',
      bottom: '1%'
    },
    series: [
      {
        color: ['#2F75B5' , '#FFFF00', '#7F5217', '#00B050', '#A9D08E', '#E42217', '#808080', '#808080', '#808080', '#808080'  ],
        // color : (data.color===undefined)?defaultColor:data.color,
        name: data.name ,
        type: 'pie',
        top: '30',
        radius: ['38%', '70%'],
        avoidLabelOverlap: true,
        label: {
          show: true,
          color: "#000",
          position: 'center',
          fontSize: (data.name.length > 6)?'21':'30',
          fontFamily : '고딕',
          fontWeight: 'bold',
          //formatter : (data.name === '평균')?'{b|AVERAGE}\n{a}':'{a}',
          formatter : (data.name === '평균')?'{b|AVERAGE}\n{a}':'{a}',
          rich : {
            b: {
              fontSize: 15,
              color: '#000',
              fontWeight: 'bold',
              padding : [1, 2, 0,0],
              borderColor: '#449933',
            }
          }
        },
        labelLine: {
          show: true
        },
        data: [
          {},{}, //오브젝트가 두개여야 가운데와 아웃사이드 label이 동시 붙는다.

          ...seriesData
        ]
      }
    ]
  } // return end


  /**
   * pie phylum 색상
   *
   * @param phylum
   * @returns {*}
   */
  function _getPiePhylumColor(phylum) {
    switch (phylum) {
      case 'Bacteroidetes' :
        return '#2F75B5'
      case 'Firmicutes' :
        return '#ffd400'
      case 'Proteobacteria' :
        return '#7F5217'
      case 'Actinobacteria' :
        return '#00B050'
      case 'Verrucomicrobia' :
        return '#A9D08E'
      case 'Fusobacteria' :
        return '#E42217'
      default :
        return '#808080'
    }
  }
}//end Chart

/**
 *  Aib3 Chart
 * @param data
 */
async function totalPhylumChart(data){

  let totalPhylum = {
    color: ['#4788e2' , '#f9ca32', '#8e89dd', '#27b688', 'rgb(180,219,154)', 'rgb(239,81,95)', 'rgb(149,160,170)'  ],
    //    color: ['#2f75b5' , '#FFFF00', '#7F5217', '#00B050', '#A9D08E', '#E42217', '#808080'  ],
    init : {
      width: window.innerWidth ,
      height : 500
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      }
    },
    axisPointer: {
      label : {
        precision : 'auto'
      }
    },
    xAxis: [],
    yAxis: [
      {
        type: 'log',
        min: 1,
        max: function (value) {
          return value.max + 100;
        },
        scale: false,
        axisLabel: {
          formatter: function(v){
            return v.toExponential(1);
          }
        }
      },

      {
        type: 'log',
        axisLabel: {
          formatter: '{value}'
        }
      }
    ],
    series: []
  } //end Total Phylum

  totalPhylum.xAxis = [];
  totalPhylum.xAxis.push({
    type: 'category',
    data: data.xAxis,
    axisPointer: {
      type: 'shadow'
    },
    axisTick: {
      alignWithLabel: true
    },
    axisLabel : {
      rotate : 30,
      align: "center",
      margin : 30,
      showMaxLabel: true,
      showMinLabel: true,
      fontSize : 9
    }
  })
  totalPhylum.legend = {};
  totalPhylum.series = [];

  totalPhylum.series = _dataToBarPhylum(data);
  totalPhylum.series.push({
    name: '평균',
    type: 'scatter',
    yAxisIndex: 0,
    symbol : 'image://data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAyCAYAAAAeP4ixAAAABmJLR0QA/wD/AP+gvaeTAAAAqklEQVRoge3VvWoCQRQF4G93wS5tCL6CmCZsntZnia1KniMhKSUpRQt/ig0EVoXZhfPBLYZpzjAcLhERERERERExRFXn/HSapkCWPnb4xFf34hXv2I9sNmjPj3jG7wBCXTs/mDdYYNb9ohGZYFphi4fCYW61rUsnuJcay9Ih7uAN5o6FKV3aW8p+6XiL9QBC9Z0VXvi7EB8xNY6F+IHv0kEiIiIiIiIi4n8HSgbGKkHV9kAAAAAASUVORK5CYII=',
    symbolSize : 25,
    data: data.line,
  });

  return totalPhylum;

  /**
   * Phylum Bar Data
   * @param rawData
   * @private
   */
  function _dataToBarPhylum(rawData) {

    let AVG_DATA = 'avgData';
    let barGap = '-100%';
    //Phylum 별 legend 를 구분 하기 위해...
    let series = [
      {
        name: 'Bacteroidetes',
        type: 'bar',
        barGap: barGap,
        data: []
      },
      {
        name: 'Firmicutes',
        type: 'bar',
        barGap: barGap,
        data: []
      },
      {
        name: 'Proteobacteria',
        type: 'bar',
        barGap: barGap,
        data: []
      },
      {
        name: 'Actinobacteria',
        type: 'bar',
        barGap: barGap,
        data: []
      },
      {
        name: 'Verrucomicrobia',
        type: 'bar',
        barGap: barGap,
        data: []
      },
      {
        name: 'Fusobacteria',
        type: 'bar',
        barGap: barGap,
        data: []
      },
      {
        name: '기타',
        type: 'bar',
        barGap: barGap,
        data: []
      },
    ]

    rawData.bar.forEach( (ele) => {

      if(ele.type === AVG_DATA) return;

      //type 이 userData 인 경우만 수행
      let obj = {
        value: [ele.phylum, ele.value.toExponential(0)],
        itemStyle: {
          barBorderColor: getPhylumColor(ele.phylum),
          color: getPhylumColor(ele.phylum),
          //borderWidth: '0'
        }
      }

      // 평균 보다 높으면... 이름 표시 @2021.11.28 요청 에 의해 제거
/*      if (ele.value > rawData.avgData[ele.phylum]) {
        obj.label = {
          show: true,
          color: getPhylumColor(ele.phylum),
          name: ele.phylum,
          position: 'top',
          formatter: '{b}'
        }
      }*/

      let barWidth = "30%";
      switch (ele.phylum) {
        case 'Bacteroidetes' :
          series[0].data.push(obj);
          series[0].barWidth = barWidth;
          break;
        case 'Firmicutes' :
          series[1].data.push(obj);
          series[1].barWidth = barWidth;
          break;
        case 'Proteobacteria' :
          series[2].data.push(obj);
          series[2].barWidth = barWidth;
          break;
        case 'Actinobacteria' :
          series[3].data.push(obj);
          series[3].barWidth = barWidth;
          break;
        case 'Verrucomicrobia' :
          series[4].data.push(obj);
          series[4].barWidth = barWidth;
          break;
        case 'Fusobacteria' :
          series[5].data.push(obj);
          series[5].barWidth = barWidth;
          break;
        default :
          series[6].data.push(obj);
          series[6].barWidth = barWidth;
          break;
      }

    });
    return series;
  }
}


/**
 * 통합 AIB 1 차트
 * - 해당 함수를 통하여 aib1 차트 데이터와 종균수, 개인별 균수, pie chart 를 추출 할 수 있다.
 * @param data
 */
async function integrationChart(data){

  let aib1 = {
    aibTotal : {
      avg : 0,
      user :0
    },
    pieChart:{
      avg: {},
      user: {}
    },
    color: ['rgb(255,215,2)' , 'rgb(64, 140, 255)', '#929292', 'rgb(239, 76, 93)', '#161B1F'],
    init : {
      width: window.innerWidth,
      height : 500
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      }
    },
    axisPointer: {
      label : {
        precision : 'auto'
      }
    },
    xAxis: [],
    yAxis: [
      {
        type: 'log',
        //name: 'bar',
        // min: 0,
        //cale : true,
        //max: 6000000,
        min: 1,
        max: 'dataMax',
        scale: false,
        axisLabel: {
          formatter: function(v){
            return v.toExponential(1);
          }
        }
      },
      {
        type: 'log',
        axisLabel: {
          formatter: '{value}'
        }
      }
    ],
    series: []
  }

  aib1.xAxis.push({
    type: 'category',
    data: data.xAxis,
    axisPointer: {
      type: 'shadow'
    },
    axisLabel : {
      rotate : 80,
      showMaxLabel: true,
      showMinLabel : true,
      fontSize : 7
    }
  })
  aib1.legend = { }
  aib1.aibTotal.avg = data.xAxis.length;
  console.log("통합 차트 총 균수 : ", aib1.aibTotal.avg);

  //균별 처리
  let byMicrobesAndPie = await dataToBarArrayAndPieChart(data, aib1);

  aib1.series = [];

  aib1.series.push( {
    name: '유산균',
    type: 'bar',
    data: byMicrobesAndPie.LAC//this.dataToBar(data.bar)
  })

  aib1.series.push({
    name: '유익균',
    type: 'bar',
    data: byMicrobesAndPie.BEB//this.dataToBar(data.bar)
  })

  aib1.series.push({
    name: '공생균',
    type: 'bar',
    data: byMicrobesAndPie.SYB//this.dataToBar(data.bar)
  })

  aib1.series.push({
    name: '유해균',
    type: 'bar',
    data: byMicrobesAndPie.bad
  })

  aib1.series.push({
    name: 'AVG',
    type: 'line',
    symbol : 'none',
    yAxisIndex: 0,
    smooth: 0.5,
    lineStyle: {
      //color: 'green',
      width: 2,
      type: 'dotted'

    },
    data: data.line,
  });

  return aib1;

}

// Bar 데이터는 Genus , Legend 는 Phylum 통합 차트
async function totalLegendPhylumChart(data){

  let totalLegendPhylum = {
    color: ['#4788e2' , '#f9ca32', '#8e89dd', '#27b688', 'rgb(180,219,154)', 'rgb(239,81,95)', 'rgb(149,160,170)'  ],
    init : {
      width: window.innerWidth ,
      height : 500
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
      }
    },
    axisPointer: {
      label : {
        precision : 'auto'
      }
    },
    xAxis: [],
    yAxis: [
      {
        type: 'log',
        min: 1,
        max: 'dataMax',
        scale: false,
        axisLabel: {
          formatter: function(v){
            return v.toExponential(1);
          }
        }
      },

      {
        type: 'log',
        axisLabel: {
          formatter: '{value}'
        }
      }
    ],
    series: []
  }

  totalLegendPhylum.xAxis =[ ];
  totalLegendPhylum.xAxis.push({
    type: 'category',
    data: data.xAxis,
    axisPointer: {
      type: 'shadow'
    },
    axisLabel : {
      rotate : 80,
      showMaxLabel: true,
      showMinLabel : true,
      fontSize : 7
    }
  })
  totalLegendPhylum.legend = { }
  totalLegendPhylum.series = [];

  totalLegendPhylum.series = dataToBarGenusLegendPhylum(data);

  totalLegendPhylum.series.push({
    name: 'AVG',
    type: 'line',
    symbol : 'none',
    yAxisIndex: 0,
    smooth: 0.5,
    lineStyle: {
      //color: 'green',
      width: 2,
      type: 'dotted'

    },
    data: data.line,
  });

  return totalLegendPhylum;
}

/**
 * Genus Legend Phylum Chart Data
 *
 * @param rawData
 */
function dataToBarGenusLegendPhylum(rawData) {

  let AVG_DATA = 'avgData';
  let barGap = '-100%';
  //Phylum 별 legend 를 구분 하기 위해...
  let series = [
    {
      name: 'Bacteroidetes',
      type: 'bar',
      barGap: barGap,
      data: [],
      excessIndex: 0
    },
    {
      name: 'Firmicutes',
      type: 'bar',
      barGap: barGap,
      data: [],
      excessIndex: 0
    },
    {
      name: 'Proteobacteria',
      type: 'bar',
      barGap: barGap,
      data: [],
      excessIndex: 0
    },
    {
      name: 'Actinobacteria',
      type: 'bar',
      barGap: barGap,
      data: [],
      excessIndex: 0
    },
    {
      name: 'Verrucomicrobia',
      type: 'bar',
      barGap: barGap,
      data: [],
      excessIndex: 0
    },
    {
      name: 'Fusobacteria',
      type: 'bar',
      barGap: barGap,
      data: [],
      excessIndex: 0
    },
    {
      name: '기타',
      type: 'bar',
      barGap: barGap,
      data: [],
      excessIndex: 0
    },
  ]

  rawData.bar.forEach( (ele) => {

    if(ele.type === AVG_DATA) return;

    //type 이 userData 인 경우만 수행
    let obj = {
      value: [ele.genus, ele.value.toExponential(0)],
      itemStyle: {
        barBorderColor: getPhylumColor(ele.phylum),
        color: getPhylumColor(ele.phylum),
        borderWidth: '5'
      }
    }

    let excessIndex = 0;
    // 평균 보다 높으면... 이름 표시 @2021.11.28 요청에 의해 이름 표시 x
    if (ele.value > rawData.avgData[ele.genus]) {
      excessIndex = (ele.value - rawData.avgData[ele.genus]);
      obj.label = {
        show: true,
        color: getPhylumColor(ele.phylum),
        name: ele.genus,
        position: 'top',
        formatter: '{b}'
      }
    }

    let barWidth = "1%";
    switch (ele.phylum) {
      case 'Bacteroidetes' :
        series[0].data.push(obj);
        series[0].barWidth = barWidth;
        series[0].excessIndex += excessIndex;
        break;
      case 'Firmicutes' :
        series[1].data.push(obj);
        series[1].barWidth = barWidth;
        series[1].excessIndex += excessIndex;
        break;
      case 'Proteobacteria' :
        series[2].data.push(obj);
        series[2].barWidth = barWidth;
        series[2].excessIndex += excessIndex;
        break;
      case 'Actinobacteria' :
        series[3].data.push(obj);
        series[3].barWidth = barWidth;
        series[3].excessIndex += excessIndex;
        break;
      case 'Verrucomicrobia' :
        series[4].data.push(obj);
        series[4].barWidth = barWidth;
        series[4].excessIndex += excessIndex;
        break;
      case 'Fusobacteria' :
        series[5].data.push(obj);
        series[5].barWidth = barWidth;
        series[5].excessIndex += excessIndex;
        break;
      default :
        series[6].data.push(obj);
        series[6].barWidth = barWidth;
        series[6].excessIndex += excessIndex;
        break;
    }

  });

  return series;
}

/**
 * phylum 색상
 *
 * @param phylum
 * @returns {*}
 */
function getPhylumColor(phylum) {
  switch (phylum) {
    case 'Bacteroidetes' :
      return '#4788e2'
    case 'Firmicutes' :
      return '#f9ca32'
    case 'Proteobacteria' :
      return '#8e89dd'
    case 'Actinobacteria' :
      return '#27b688'
    case 'Verrucomicrobia' :
      return 'rgb(180,219,154)'
    case 'Fusobacteria' :
      return 'rgb(239,81,95)'
    default :
      return 'rgb(149,160,170)'
  }
}

/**
 * Balance Chart 처리
 *
 * - 예외: Action 상위에서 store 에 Commit 하려 했으나 총 7개의 차트를 동기식으로는
 *        늦게 랜더링이 되기 때문에 예외로 store를 넘겨서 비동기 Commit을 수행함
 *
 * @param data
 * @returns {Promise<void>}
 */
async function balanceChart (store, data) {

  let totalIndex = 0
  let totalIndexCnt = 0

  //종분석 시작 :: 데이터 오브젝트 생성 -> 음수는 빨간색으로 처리 및 0 이하로 표현
  Object.keys(data).reduce((acc, k) => {

    console.log('#index ', k, data[k])
    // 2021-07-05(월) 요청으로 조건 수정
    //if (k == 'gut' || k == 'immunity' || k == 'obesity' || k == 'age') {
    if (k == 'gut' || k == 'immunity' ) {
      // 다양성은 2배 처리
      totalIndex = Number(totalIndex) + Number((k === 'age') ? data[k] * 2 : data[k])
      totalIndexCnt++
    }
    // 별차트 데이터 index 가 아닌 -> gauge 차트 데이터로 이전
    //this.$store.state.starScore.index[k] = data[k];
    balanceChartGauge(store, speciesToKr(k), data[k], k)

    return [...acc]

  }, [])

  //총합에서 아토피 와 기타를 제외한 평균으로 처리
  balanceChartGauge(store, speciesToKr('total'), totalIndex / totalIndexCnt)

}

/**
 * species to kor String
 * @param name
 * @returns {string}
 * @private
 */
function speciesToKr(name) {
  switch (name) {
    case 'gut':
      return "장건강";
    case 'immunity':
      return "장-면역";
    case 'obesity':
      return "당뇨 예방";
    case 'atopy':
      return "장-피부";
    case 'adult':
      return "장-간";
    case 'female':
      return "장-뇌";
    case 'etc':
      return "기타";
    case 'total':
      return "TOTAL";
    case 'age':
      return "다양성";
  }
}

/**
 * 게이지 차트
 * @param name
 * @param value
 * @param key string  레벨 데이터 키 "장, 면역, 비만, 당뇨, 나이 등"
 * @returns {string}
 */
async function balanceChartGauge (store, name, value, key){
  const WARNING = 0.20;
  const CAUTION = 0.40;
  const NORMAL = 0.60;
  const GOOD = 0.80;
  const BEST = 1;

  let color =  [
    [WARNING, '#d1d0d0'],
    [CAUTION, '#D1D0D0'],
    [NORMAL, '#D1D0D0'],
    [GOOD, '#D1D0D0'],
    [BEST, '#D1D0D0']
  ]

  // 영역은 0 ~ 1 로 백분율로 표시, tick 은 1 ~ 100 정수
  // 그렇기 때문에 각 연산에 신경 써야 하며, 해당 차트 tick 50 기준으로
  // 표현 값이 음수 일 경우는 tick 50 보다 작게 양수는 tick 50 보다 크게 표현을 해야 한다.
  // 구간 영역 색 표기를 위한 값 연산
  let gaugeValue = 0;
  let pointerValue = 0;

  console.log("#index2-b ", key, value);
  if(key === "age"){
    if(Math.sign(value) < 0){ // 음수 인 경우
      value = ( 50 - Math.abs(value/2) );
    }else {
      value = ( (value/2) + 50);
    }
  }
  console.log("#index2 ", key, value);

  //pointerValue = gaugeValue;
  pointerValue = value; // 포인터는 0 ~ 100
  //gaugeValue = gaugeValue / 100;
  gaugeValue = value / 100; // 영역 표시는 0 ~ 1


  if(Math.sign(value) < 0){ // 음수 인 경우
    gaugeValue = 0;
  }

  console.log("#jay2 ", name, value, pointerValue, gaugeValue )

  //예외(직접 접근하여 처리)
  store.state.starScore.index[key] = gaugeValue;

  let resultStr = '';
  let levelGroup = {
    level : 0,
    cssClass: "grade_3"
  }
  switch(true){
    case (gaugeValue <= WARNING) :
      color[0] = [WARNING, '#db2228'];
      resultStr = "경고";
      levelGroup.level = -2;
      levelGroup.cssClass = "grade_5";
      break;
    case (gaugeValue <= CAUTION) :
      color[1] = [CAUTION, '#f7941d'];
      resultStr = "주의";
      levelGroup.level = -1;
      levelGroup.cssClass = "grade_4";
      break;
    case (gaugeValue <= NORMAL) :
      color[2] = [NORMAL, '#ffcb05'];
      resultStr = "보통";
      levelGroup.level = 0;
      levelGroup.cssClass = "grade_3";
      break;
    case (gaugeValue <= GOOD) :
      color[3] = [GOOD, '#9bca3c'];
      resultStr = "양호";
      levelGroup.level = 1;
      levelGroup.cssClass = "grade_2";
      break;
    case (gaugeValue <= BEST) :
      color[4] = [BEST, '#009f4f'];
      resultStr = "우수";
      levelGroup.level = 2;
      levelGroup.cssClass = "grade_1";
      break;
  }

  let levelImgSize = (name === 'TOTAL')?70:55;

  let gaugeData = {
    levelGroup : levelGroup,
    resultStr : resultStr,
    series: [{
      name: 'Balance Index',
      type: 'gauge',
      center: ['50%', '65%'],
      zlevel: 999,
      radius: '85%',
      splitNumber: 100,
      startAngle : 180,
      endAngle: 0,
      axisLine: {
        lineStyle: {
          shadowColor: 'rgba(0, 0, 0, 0.1)',
          shadowBlur: 10,
          color: color,
          width: 150  // 150
        }
      },
      axisTick: {
        splitNumber: 30,
        length: 0,
        lineStyle: {
          color: 'auto'
        }
      },
      axisLabel: {
        zlevel: 999999,
        height : '10%',
        length: 100,
        fontSize : 23,
        fontFamily: 'NanumSquareRound',
        fontWeight : 900,
        textStyle: {
          color: '#FFF'
        },
        rich: {
          m2: {
            //align: 'right',
            //verticalAlign: "top",
            lineHeight: 70,
            backgroundColor: {
              image: './img/level-2.png'
            },
            height: levelImgSize
          },
          m1: {
            lineHeight: 50,
            backgroundColor: {
              image: './img/level-1.png'
            },
            height: levelImgSize
          },
          0: {
            lineHeight: 100,
            backgroundColor: {
              image: './img/level0.png'
            },
            height: levelImgSize
          },
          1: {
            lineHeight: 50,
            backgroundColor: {
              image: './img/level1.png'
            },
            height: levelImgSize
          },
          2: {
            lineHeight: 70,
            backgroundColor: {
              image: './img/level2.png'
            },
            height: levelImgSize
          },

        },
        formatter(v){
          //console.log("VVVVVVVVVVVVVVVVVV", v)
          //return (v % 5=== 0)?v:'';
          if(v===14) return '{m2|}';
          if(v===29) return '{m1|}';
          if(v===50) return "{0|}";
          if(v===71) return "{1|}";
          if(v===85) return "{2|}";
        },
      },

      splitLine: {
        show: true,
        length: 1,
        lineStyle: {
          color: 'auto'
        }
      },
      pointer: {
        width: '20',
        length: '80%'

      },
      itemStyle : {
        color : "#626161"
      },

      detail: {
        zlevel : 1000,
        formatter: name,
        fontFamily: "NanumSquareRound",
        fontWeight: '900',
        textStyle: {
          color: '#000'
        }

      },
      data: [{
        value: pointerValue,
        name: ''
      }]
    },{
      name: 'Balance Index',
      type: 'gauge',
      zlevel: 1,
      center: ['50%', '65%'],
      radius: '90%',
      splitNumber: 100,
      startAngle : 180,
      endAngle: 0,
      detail: {
        zlevel : 0,
        formatter: "",
      },
      axisLine: {
        lineStyle: {
          shadowColor: 'rgba(0, 0, 0, 0.2)',
          shadowBlur: 8,
          color: [
            [1, '#fff']
          ],
          width: 170
        }
      },

      axisLabel: {
        zlevel: 99999,
        fontSize : 12,
        padding: [6, 0, 0, 0],
        textStyle: {
          color: '#000'
        },
        formatter(){
        }
      },
      splitLine: {
        show: true,
        length: 1,
        lineStyle: {
          color: 'auto'
        }
      },
    }
    ]
  };

  //자주 변경 되므로 우선 코드 나열
  //TODO 데이터 처리 구조 개선 필요
  if (name === speciesToKr('gut')){
    store.state.balance.gutBalanceGaugeData = gaugeData;
    return;
  }

  if (name === speciesToKr('immunity')){
    store.state.balance.immunityBalanceGaugeData = gaugeData;
    return;
  }

  if (name === speciesToKr('obesity')){
    store.state.balance.obesityBalanceGaugeData = gaugeData;
    return;
  }

  if (name === speciesToKr('atopy')){
    store.state.balance.atopyBalanceGaugeData = gaugeData;
    return;
  }

  if (name === speciesToKr('adult')){
    store.state.balance.adultBalanceGaugeData = gaugeData;
    return;
  }

  if (name === speciesToKr('female')){
    store.state.balance.femaleBalanceGaugeData = gaugeData;
    return;
  }

  if (name === speciesToKr('etc')){
    store.state.balance.etcBalanceGaugeData = gaugeData;
    return;
  }

  if (name === speciesToKr('age')){
    store.state.balance.ageBalanceGaugeData = gaugeData;
    return;
  }

  if (name === 'TOTAL'){
    store.state.balance.totalBalanceGaugeData = gaugeData;
    return;
  }

} // end balance Gauge

/**
 * 차트 균별 처리 및 Pie Chart Data 생성
 *
 * @param rawData
 * @returns {Promise<{all: *[], bad: *[], SYB: *[], BEB: *[], LAC: *[]}>}
 */
async function dataToBarArrayAndPieChart(rawData, aib1) {

  let ret = {
    bad : [],  //유해균
    all : [],
    LAC : [],
    BEB : [],  //유익균
    SYB : []
  }

  let AVG_DATA = 'avgData';

  let pieChartData = {
    avg : {
      bad : 0,
      lac : 0,
      syb : 0,
      beb : 0,
      seriesData : []
    },
    user : {
      bad : 0,
      lac : 0,
      syb : 0,
      beb : 0,
      seriesData : []
    }
  }

  rawData.bar.forEach( (ele) => {

    //pie chart 분리 TODO :: 코드 반복, 레펙토리 필요 (평균, 개인)
    if(ele.type === AVG_DATA){
      if (ele.microbes === 'BAD') {
        pieChartData.avg.bad += ele.value;
      } else if (ele.microbes === 'LAC') {
        pieChartData.avg.lac += ele.value;
      } else if (ele.microbes === 'SYB') {
        pieChartData.avg.syb += ele.value;
      } else if (ele.microbes === 'BEB') {
        pieChartData.avg.beb += ele.value;
      }
      return;
    }

    //type 이 userData 인 경우만 수행
    let obj = {
      value: [ele.genus, ele.value.toExponential(0)],
      itemStyle: {
        barBorderColor: getMicrobesColor(ele.microbes),
        color: getMicrobesColor(ele.microbes),
        borderWidth: 5
      }
    }

    // 유져 균수 카운트
    if( ele.value > 0 ) aib1.aibTotal.user = aib1.aibTotal.user + 1;

    // 평균 보다 높으면... 이름 표시 @2021.11.28 요청에 의해 이름 표시 주석 처리
    if (ele.value > rawData.avgData[ele.genus]) {
      obj.label = {
        show: true,
        color: getMicrobesColor(ele.microbes),
        name: ele.genus,
        position: 'top',
        formatter: '{b}'
      }
    }

    if (ele.microbes === 'BAD') {
      ret.bad.push(obj);
      pieChartData.user.bad += ele.value;
    } else if (ele.microbes === 'LAC') {
      ret.LAC.push(obj);
      pieChartData.user.lac += ele.value;
    } else if (ele.microbes === 'SYB') {
      ret.SYB.push(obj);
      pieChartData.user.syb += ele.value;
    } else if (ele.microbes === 'BEB') {
      ret.BEB.push(obj);
      pieChartData.user.beb += ele.value;
    } else {
      console.error("[dataToBarArray] " + obj)
    }

  })

  _integrationPieChart(pieChartData);

  aib1.pieChart.avg = _getChartData({ name:"평균", seriesData: pieChartData.avg.seriesData });
  aib1.pieChart.user = _getChartData({ name: rawData.code , seriesData: pieChartData.user.seriesData });

  return ret;

  /* 내부 함수 */
  //통합 파이 차트를 위한 데이터 분리
  function _integrationPieChart(data){
    Object.keys(data).reduce( (acc, key) => {

      Object.keys(data[key]).reduce( (a, micro) => {
        if(micro.length <= 3){
          data[key].seriesData.push( { value: data[key][micro], name : __getMicroCodeToStr(micro) } )
        }
      }, {})

    }, {})

    //미생물 분류 코드에서 String 으로 변환
    function  __getMicroCodeToStr(micro){
      switch(micro){
        case "bad" :
          return "유해균";
        case "lac" :
          return "유산균";
        case "beb" :
          return "유익균";
        case "syb" :
          return "공생균";
        default :
          return "noname";
      }
    }
  }

  /**
   * aib 차트의 파이 차트
   *
   * @param data
   * @private
   */
  function _getChartData(data) {
    let defaultColor = ['rgb(64, 140, 255)' , 'rgb(255,215,2)', 'rgb(239, 76, 93)', 'rgb(255,215,2)', 'rgb(146,146,146)'];
    let seriesData = data.seriesData.reduce( (acc, k) => {
      let obj = {
        value : k.value,
        name : k.name,
        label : {
          show: true,
          fontSize: 12,
          position: 'outside',
          formatter: function (v) {

            return `${v.name}\n ${v.percent}% `;
          },
          rich: data.rich

        }
      }

      return [...acc, obj];
    }, [])

    return {
      //color: (data.color===undefined)?defaultColor:data.color,
      /*                    tooltip: {
                              trigger: 'item',
                              formatter: '{a} <br/>{b}: {c} ({d}%)'
                          },*/
      legend: {
        show : (data.legend === undefined)?false : data.legend,
        orient: 'horizontal',
        bottom: '1%'
      },
      /*
                      toolbox: {
                          feature: {
                              saveAsImage: { show: true, name : 'Phylum 분석차트', title : '이미지 다운로드' }
                          }
                      },
      */
      series: [
        {
          color : (data.color===undefined)?defaultColor:data.color,
          name: data.name,
          type: 'pie',
          radius: ['38%', '70%'],
          avoidLabelOverlap: true,
          label: {
            show: true,
            color: "#000",
            position: 'center',
            fontSize: (data.name.length > 6)?'21':'30',
            fontFamily : '고딕',
            fontWeight: 'bold',
            formatter : (data.name === '평균')?'{b|AVERAGE}\n{a}':'{a}',
            rich : {
              b: {
                fontSize: 15,
                color: '#000',
                fontWeight: 'bold',
                padding : [1, 2, 0,0],
                borderColor: '#449933',
              }
            }
          },
          labelLine: {
            show: true
          },
          data: [
            {},{}, //오브젝트가 두개여야 가운데와 아웃사이드 label이 동시 붙는다.

            ...seriesData
          ]
        }
      ]
    }
  }//end Chart ,
}

/**
 * 미생물 색상 구분
 * @param microbes : 미생물 구분 코드
 * @returns {string}
 */
function getMicrobesColor (microbes) {
  switch (microbes) {
    case 'SYB' : //공생균
      //return this.totalColor.SYB
      return 'rgb(146,146,146)'
    case 'LAC' : //유산균
      //return this.totalColor.LAC
      return 'rgb(255,215,2)'
    case 'BEB' : //유익균
      //return this.totalColor.BEB
      return 'rgb(64, 140, 255)'
    case 'BAD' : //유해균
      //return this.totalColor.BAD
      return 'rgb(239, 76, 93)'
    default :
      return '#808080'
  }
}

/**
 * 별차트 생성
 * //TODO 발렌스 차트 부터 개발 되어야 해당 데이터 처리를 완료 할 수 있음.
 * @param type 1: 일때 질병 점수 입력 처리 후 차트 데이터 생성
 * @param store
 */
async function drawStarChart(type = 0, store) {
  let chart = {};
  let bgChart = {};

  //질병 점수는 growth API(설문조사, 공식에 의해 사전 연산 된 데이터)가 먼저 호출 된 후 처리 되어야 한다. ! 우선순위 주위
  let disease = Number(store.state.sample.disease) || 0;

  //질병 점수 저장
  if(type === 1){
    if( disease < 0 || disease > 10) return;
    let uri = `/v1/disease/${store.state.code}/${disease}`;
    axios.put(uri).then( () => console.log("[Save][StarChart][Disease]"));
  }

  /**
   * 장내 미생물 처리가 먼저 되어야 함
   * Balance Chart 생성시 해당 데이터가 생성 됨 (해당 데이터는 AIB Total 차트 호출 시 이미 FE로 가져옴)
   */
  let index = store.state.starScore.index;
  //let diversity = this.$store.state.starScore.diversity;

  let gutMicroScore =  ( Number(index.gut)  + Number(index.immunity) + Number(index.obesity)  + Number(index.age) ) * 3;
  //let gutMicroScore = (index.gut/100 || 0) + (index.immunity/100 || 0) + (index.obesity/100 || 0) * 3 + (diversity.userScore/diversity.maxScore || 0) * 3;
  //console.log("starScore : ", JSON.stringify(this.$store.state.starScore), this.starChartData.score);

  let obesity = 4; //최소 점수
  let heightPercentile = Number(store.state.heightChartData.heightPercentile);
  console.log("#star heightPercentile %s ", store.state.heightChartData.heightPercentile );

  let weightPercentile = Number(store.state.weightChartData.weightPercentile);
  console.log("#star weightPercentile %s ", store.state.weightChartData.weightPercentile );

  console.log("isAdult %s", store.state.isAdult);
  //성장과 비만
  if(!store.state.isAdult) { // 소아, 청소년기는 별도 로직
    console.log("#star percentile  %d %d", heightPercentile, weightPercentile);
    let heightSore =  3 + 3 * ( Number(heightPercentile)/ 100);
    let weightScore =  (5 - Math.abs(5- ( Number(weightPercentile) /100) * 10 ));
    console.log("#start %s %s", heightSore, weightScore );
    if(weightScore < 0) weightScore = 0;
    if(weightScore > 4) weightScore = 4;

    obesity = Math.round(heightSore + weightScore);
    console.log("#star obesity %s ", heightSore + weightScore );

    /*
        obesity = ((heightPercentile / 100) * 5 +
            (5 - Math.abs((weightPercentile / 100) - 0.5) * 10)).toFixed(0) || 0;

        console.log("#[obesity] ", obesity, heightPercentile, weightPercentile)
        obesity = (obesity < 4)?4:obesity;
    */

  }else {
    obesity = _checkDefaultValue(Number(store.state.starScore.surveyScore.obesity));
  }
  let eatingHabit = _checkDefaultValue(Number(store.state.starScore.surveyScore.eatingHabit));
  disease = _checkDefaultValue(disease);
  let life = _checkDefaultValue(Number(store.state.starScore.surveyScore.life));

  gutMicroScore = _checkDefaultValue(Number(gutMicroScore));
  //TODO 장내 미생물을 Commit 처리 해야 할지는 고민해 봐야 함. (안티패턴)
  store.state.starScore.surveyScore.gutMicroScore = gutMicroScore;

  let nameListScore = [], nameList = [];
  let seriesData = [];
  let starScore = [];

  seriesData.push(obesity);
  starScore.push(Number(obesity));
  if(store.state.isAdult){
    nameList.push("● 비만");
  }else {
    nameList.push("● 성장과 비만");
  }
  nameListScore.push(addSpace(obesity));

  seriesData.push(store.state.starScore.defaultValue);

  seriesData.push(life);
  starScore.push(life);
  nameList.push("● 생활습관");
  nameListScore.push(addSpace(life));

  seriesData.push(store.state.starScore.defaultValue);

  seriesData.push(gutMicroScore);
  starScore.push(gutMicroScore);
  nameList.push("● 장미생물");
  nameListScore.push(addSpace(gutMicroScore));

  seriesData.push(store.state.starScore.defaultValue);

  seriesData.push(eatingHabit);
  starScore.push(eatingHabit);
  nameList.push("● 식습관");
  nameListScore.push(addSpace(eatingHabit));

  seriesData.push(store.state.starScore.defaultValue);

  seriesData.push(disease);
  starScore.push(disease);
  nameList.push("● 질병");
  nameListScore.push(addSpace(disease));

  seriesData.push(store.state.starScore.defaultValue);

  chart = getStarChartData("NONE", seriesData, store.state.isAdult);
  bgChart = getStarChartData("BG", seriesData, store.state.isAdult);

  bgChart.adjustedStarScore = starScore;
  // TODO 이미 있는 데이터 인데 왜 ??? 레포트에서 확인 후 처리
  //this.starChartData.bgChart.adjustedStarScore = starScore;

  //this.$store.commit('setStartChartData', this.starChartData.bgChart);
  //TODO 이미 상위에서 기록을 하는데 왜 다시 Commit 을?
  //this.$store.commit('setDisease', disease);
  //console.log("!!!!!star ", JSON.stringify(this.starChartData.bgChart))

  return {
    chart : chart,
    bgChart : bgChart
  }

  function addSpace(score){
    let str = score + "";
    if(str.length < 2){
      return " "+score;
    }

    return score;
  }

  /**
   * 별차트 값이 음수 또는 4보다 작을 경우 기본 값으로 처리
   * @param value
   * @returns {number|*}
   */
  function _checkDefaultValue(value){
    let val = Math.sign(value || 0);
    if(val >= 0 ){
      if(value === 0 || value <= store.state.starScore.defaultValue ) return store.state.starScore.defaultValue;
      value = value + 1; //가중치
      return (value > 10)? 10: Math.round(value);
    }
    return store.state.starScore.defaultValue;  // 별 차트 디폴트 값
  }

  /**
   * 별차트 데이터 생성(내부 함수)
   * @param type : "BG"(배경이 있은 차트), "NONE" (배경이 없는 차트)
   * @param seriesData
   * @returns {{radar: [{indicator: [], splitArea: {show: boolean}, axisLine: {show: boolean}, center: [string, string], splitLine: {lineStyle: {color: [string], type: string, opacity: number}, show: boolean}, splitNumber: number, radius: string}], series: [{axisLabel: {show: boolean}, symbol: string, areaStyle: {color: string, opacity: number}, lineStyle: {color: string, width: string}, data: [{name: string, value: *}], tooltip: {trigger: string}, type: string}], toolbox: {feature: {saveAsImage: {show: boolean, name: string, title: string}}}, title: {text: string}, graphic: [{top: string, left: string, z: number, style: {text: string, fill: string, font: string}, type: string}]}}
   */
  function getStarChartData (type, seriesData, isAdult){

    let indicatorColor = '#000';
    let areaColor = 'rgb(64, 140, 255)';
    let lineColor = 'rgb(188, 188, 188)';
    let graphic = [];

    //default graphic
    graphic.push({
          type: 'text',
          z: 100,
          left: '310px',
          top: '259px',
          style: {
            fill: 'rgb(99,111,112)',
            text: [
              '10  9  8  7  6  5  4  3  2  1   0',

            ].join('\n'),
            font: 'bolder 16px Microsoft YaHei'
          }
        }
    )

    if(type === "BG"){
      indicatorColor = '#FFF';
      areaColor = 'rgb(255, 228, 2)';
      lineColor = 'rgb(231, 231, 231)';
      graphic.push(
          {
            type: 'image',
            id: 'logo',
            right: 20,
            z: 0,
            bounding: 'raw',
            origin: [75, 75],
            style: {
              image: './img/starbg_dark.jpg',
              width: 1100,
              height: 500,
              opacity: 1
            }
          },
          {
            "type": "text",
            "z": 100,
            "left": "80%",
            "top": "32.5%",
            "style": {
              "fill": "rgb( 238, 238, 238)",
              "text": nameList.join("\n\n\n"),
              //"font": "15px  Microsoft YaHei"
            }
          },
          {
            "type": "text",
            "z": 100,
            "left": "89%",
            "top": "32.5%",
            "style": {
              "fill": "rgb( 161, 161, 161)",
              "text": nameListScore.join("\n\n\n"),
              "font": "15px"
            }
          },
      )
    }


    return {
      title: {
        text: ' '
      },
      /*
                    toolbox: {
                      feature: {
                        saveAsImage: { show: true, name : '별차트', title : '이미지 다운로드' }
                      }
                    },
      */
      radar: [
        {
          indicator: (function (){
            let res = [];
            for (let i = 1; i <= 10; i++) {

              switch(i){
                case 1:
                  if(isAdult){
                    res.push({text: '비만', max: 10, color: indicatorColor});
                  }else{
                    res.push({text: '성장과 비만', max: 10, color: indicatorColor});
                  }
                  continue;
                case 3:
                  res.push({text: '생활습관', max: 10, color: indicatorColor});
                  continue;
                case 5:
                  res.push({text: '장미생물', max: 10, color: indicatorColor});
                  continue;
                case 7:
                  res.push({text: '식습관', max: 10, color: indicatorColor});
                  continue;
                case 9:
                  res.push({text: '질병', max: 10, color: indicatorColor});
                  continue;
                default :
                  res.push({text: '', max: 10, color: indicatorColor});
              }

            }
            return res;
          })(),
          center: ["48%", "53%"],
          radius: '82%',
          splitArea: {
            show: false
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: [lineColor],
              type : 'dotted',
              opacity : 0.8
            }
          },
          axisLine: {
            show: false
          },
          textStyle : {
            fontSize: 15
          },
          splitNumber: 10,

        },

      ],
      graphic: graphic,
      series: [
        {
          type: 'radar',
          symbol: "none",
          tooltip: {
            trigger: 'item'
          },


          axisLabel: {
            show : false
          },
          areaStyle: {
            color : areaColor,
            opacity: 0.3
          },
          lineStyle: {
            color : areaColor,
            width : '3',

          },
          data: [
            {
              value: seriesData,
              name: ''
            }
          ]
        }
      ]
    };
  }
}

/**
 * 식이 취향(식재료) 집계
 *
 * @param foodStr
 * @param taste
 */
function tasteCnt(foodStr, taste){

  switch (foodStr) {
    case '고기':
      taste.meatCnt++
      break
    case '햄버거':
      taste.meatCnt++
      break
    case '소세지':
      taste.meatCnt++
      break
    case '돈까스':
      taste.meatCnt++
      taste.oilCnt++
      break
    case '햄':
      taste.meatCnt++
      break
    case '떡복이':
      taste.carbohydrateCnt++
      break
    case '파스타':
      taste.carbohydrateCnt++
      break
    case '라면':
      taste.carbohydrateCnt++
      break
    case '고구마':
      taste.carbohydrateCnt++
      break
    case '쌀국수':
      taste.carbohydrateCnt++
      break
    case '채소':
      taste.vfCnt++
      break
    case '과일':
      taste.vfCnt++
      break
    case '김치':
      taste.vfCnt++
      taste.dairyCnt++
      break
    case '사과':
      taste.vfCnt++
      break
    case '양송이스프':
      taste.vfCnt++
      break
    case '시리얼':
      taste.vfCnt++
      break
    case '김밥':
      taste.vfCnt++
      break
    case '치즈':
      taste.dairyCnt++
      break
    case '요구르트':
      taste.dairyCnt++
      break
    case '구운요리':
      taste.oilCnt++
      break
    case '기름진음식':
      taste.oilCnt++
      break
    case '감자튀김':
      taste.oilCnt++
      break
    case '치킨':
      taste.oilCnt++
      break
    case '콜라':
      taste.sweetlyCnt++
      break
    case '마끼아또':
      taste.sweetlyCnt++
      break
    case '팬케이크':
      taste.sweetlyCnt++
      break
    case '케이크':
      taste.sweetlyCnt++
      break
    case '이온음료':
      taste.sweetlyCnt++
      break
    case '찜요리':
      taste.saltyCnt++
      break
    case '순두부':
      taste.saltyCnt++
      break
    case '국물만':
      taste.saltyCnt++
      break
  }
}


// 식이취향(식재료) 차트 데이터 설정
async function setTastesChartData(isFirst, markPointDataList){
  //let maxCallback = ( acc, cur ) => Math.max( acc, cur );
  //let max = markPointDataList.reduce( maxCallback, 0);
  //let imageName = `식이취향_${this.code}${(isFirst)?"_1":"_2"}`;

  return {
    grid : {
      left: "12%"
    },
    xAxis: {
      type: 'category',
      data: (isFirst)?['육류', '탄수화물', '채소과일', '발효유제품']:['기름지게', '달게', '짜게'],
      axisTick: {
        show : false
      }
    },
    yAxis: {
      type: 'value',
      splitLine: {show: false},
      max: function () {
        //return value.max + 30;
        return 120;
      },
      axisLabel: {
        formatter: function(v){
          return v+' %';
        }
      },
      axisTick: {
        show : false
      }
    },
    /*            toolbox: {
                  feature: {
                    saveAsImage: { show: true, name : imageName , title : '이미지 다운로드' }
                  }
                },*/
    series: [{
      data: (isFirst)?[40, 30, 50, 50]:[20,0,0],
      stack: "food",
      type: 'bar',
      name: '1',
      itemStyle: {
        barBorderColor: 'rgba(0,0,0,0)',
        color: 'rgba(0,0,0,0)'
      }
    },
      {
        data: (isFirst)?[40, 30, 50, 50]:[30,30,30],
        stack: "food",
        type: 'bar',
        name: '2',
        zlevel: 0,
        z: 0,
        itemStyle: {
          barBorderColor: '#9bca3c',
          color: '#9bca3c'
        },
        markPoint: {
          data: markPointDataList
        }
      }
    ]
  };
}

/**
 * 선호 식품(식이 취향)
 *
 * @param survey
 */
async function preferredFood(survey) {

  let taste = {
    meatCnt: 0, // 육류
    carbohydrateCnt: 0, // 탄수 화물
    vfCnt: 0, // 채소 과일
    dairyCnt: 0, //유제품
    oilCnt: 0,  // 기름 지게
    sweetlyCnt: 0, // 달게
    sweetAlpha: 0, // 달게 가중치
    saltyCnt: 0, // 짜게
    saltyAlpha: 0, // 짜게 가중치

  }

  //설문 조사 식이 취향 집계
  Object.keys(survey).reduce( (acc, key) => {
    //선호 음식
    if(key.startsWith("likefood")){
      //console.log("likefood", key, survey[key])
      tasteCnt(survey[key], taste);
    }

    //달게먹는편
    if(key === "taste2" && survey[key] === "예") {
      taste.sweetAlpha = 0.2; //달게 가중치
    }
    //짜게먹는편 가중치
    if(key === "taste3" && survey[key] === "예") taste.saltyAlpha = 0.2;

  }, 0)
  //console.log("!!!JJJ", JSON.stringify(taste)

  let markPointDataList = [];
  let markPointDataList2 = Object.keys(taste).reduce((acc, key) => {

    if(key === 'saltyAlpha' || key === 'sweetAlpha') return [...acc||[]];

    let axisObj = getAxisObj(key, taste[key]);

    let obj = {
      name: '1',
      xAxis: axisObj.xAxis,
      yAxis: Number(axisObj.yAxis.toFixed(0)),
      symbolSize: 20,
      symbol: 'circle',
      itemStyle: {
        color: '#ff0000',
      }
    }

    if(key === 'oilCnt' || key === 'sweetlyCnt' || key === 'saltyCnt') {
      return [...acc||[], obj];
    }

    markPointDataList.push(obj);
  }, [])

  return {
    tastesList1 : await setTastesChartData(true, markPointDataList),
    tastesList2 : await setTastesChartData(false, markPointDataList2)
  }

  /**
   * 식이 재료 Axis 데이터 (내부 함수)
   * @param key : 식이재료 이름
   * @param value : Count
   * @returns {{yAxis: number, xAxis: string}|string}
   */
  function getAxisObj (key, value) {
    let retObj = {
      xAxis: '',
      yAxis: 0,
    }
    switch (key) {
      case 'meatCnt':
        retObj.xAxis = "육류";
        retObj.yAxis = (value / 5) * 100;
        return retObj;
      case 'carbohydrateCnt':
        retObj.xAxis = "탄수화물";
        retObj.yAxis = (value / 5) * 100;
        return retObj;
      case 'vfCnt':
        retObj.xAxis = "채소과일";
        retObj.yAxis = (value / 7) * 100;
        return retObj;
      case 'dairyCnt':
        retObj.xAxis = "발효유제품";
        retObj.yAxis = (value / 3) * 100;
        return retObj;
      case 'oilCnt':
        retObj.xAxis = "기름지게";
        retObj.yAxis = (value / 5) * 100;
        return retObj;
      case 'sweetlyCnt':
        retObj.xAxis = "달게";
        retObj.yAxis = ((value / 5) * 100) + taste.sweetAlpha;
        return retObj;
      case 'saltyCnt':
        retObj.xAxis = "짜게";
        retObj.yAxis = ((value / 5) * 100) + taste.saltyAlpha;
        return retObj;
    }
  }
}

async function sleepChart(sleepList){

  if(sleepList.length <= 0) return {};

  let markPointDataList = [];
  sleepList.reduce( (acc, key) => {
    let age = 0;
    if(key.age <= 1){
      age = (key.age * 10).toFixed(0);
      age = age + "개월";
    }else if(key.age > 1 && key.age <= 1.5){
      age = 12 + "개월";  //12 개월로 처리

    }else if(key.age > 16){
      age = "성인";
    }else {
      age = key.age.toFixed(0);
      age = age + "세";
    }

    let obj = {
      name: "sleep",
      xAxis: age,  // String 처리로 되어여 함
      yAxis: key.sleep,
      symbolSize: 15,
      itemStyle: {
        color: '#ff0000',
      }
    }

    markPointDataList.push(obj);

  }, 0)

  let label = {
    padding: [0, 0, 0, 0],
    show: true,
    position: "top",
    color: "#000"
  }

  return {
    /*
                toolbox: {
                  feature: {
                    saveAsImage: { show: true, name : '수면차트_'+ this.code, title : '이미지 다운로드' }
                  }
                },
    */
    xAxis: {
      type: 'category',
      data: ['1개월','2개월','4개월','5개월','7개월','9개월','11개월','12개월','2세','3세','4세','5세', '6세','7세', '8세','10세','12세','14세','16세', '성인'],
      axisLabel: {
        rotate : 30,
        align: "center",
        margin : 20,
        showMaxLabel: true,
        showMinLabel: true,
        fontSize : 9
      },
      axisTick : {
        show : false
      }
    },
    yAxis: {
      type: 'value',
      min: 6
    },
    series: [
      {
        data: [14, 13.5, 12, 11.8, 11.6, 11.4, 11.2, 11, 10.5, 10, 9.7, 9.3, 9, 8.9, 8.8, 8.5, 8.3, 8, 7.5, 7.3, 7.2, 7.1, 7, 7, 7, 7, 7,7],
        type: 'line',
        smooth: true,
        itemStyle: {
          color: "rgb(203,20,110)",
        },
        markArea : {
          silent: true,
          data: [
            [
              {
                name: '영아기',
                label: label,
                coord: ['4개월', 18],
                itemStyle: {
                  color: "rgb(236, 244, 250)",
                  //"opacity": 0.5

                }
              },
              {
                coord: [-1, 0],
                itemStyle: {
                  color: "rgb(236, 244, 250)",
                  //"opacity": 0.5
                }
              }
            ],
            [
              {
                name: '유아기',
                label: label,
                coord: ['4개월', 18],
                itemStyle: {
                  color: "rgb(236, 255, 250)",
                  //"opacity": 0.5

                }
              },
              {
                coord: ['12개월', 0],
                itemStyle: {
                  color: "rgb(236, 244, 250)",
                  //"opacity": 0.5
                }
              }
            ],
            [
              {
                name: '소아기',
                label: label,
                coord: ['12개월', 18],
                itemStyle: {
                  color: "rgb(236, 255, 200)",
                  //"opacity": 0.5

                }
              },
              {
                coord: ['8세', 0]
              }
            ],
            [
              {
                name: '청소년기',
                label: label,
                coord: ['8세', 18],
                itemStyle: {
                  color: "rgb(236, 255, 220)",
                  //"opacity": 0.5

                }
              },
              {
                coord: ['14세', 0]
              }
            ],
            [
              {
                name: '성인기',
                label: label,
                coord: ['14세', 18],
                itemStyle: {
                  color: "rgb(226, 255, 230)",
                  //"opacity": 0.5

                }
              },
              {
                coord: ['성인', 0]
              }
            ],

          ]}
      },
      {
        data: [17, 16.4, 15.2, 15.1, 14.8, 14.5, 14.2, 14, 13.5, 13, 12.3, 11.7, 11.5, 11, 10.8, 10.5, 10.3, 10,9.7,9.4,9,8.8,8.5,8.3,8,8,8,8],
        type: 'line',
        itemStyle: {
          color: "rgb(52,85,184)",
        },
        smooth: true,
        markPoint: {
          data: markPointDataList,
          symbol: "circle"
        }

      }
    ]
  };
}

/**
 * 식단
 * @param store
 * @param likeFoodStr
 */
async function menu(store, likeFoodStr) {

  let likeAdultFoodList = likeFoodStr.split("♡");

  let foodTableList = [];

  // 초기 이미지 리스트
  let initialSelected = [];

  // foodList 고정 되어 있음.
  store.state.menu.foodList.forEach( foodName => {
    let str = foodName;
    if(foodName ==="햄_소세지_볶음") {
      str = "햄,소세지볶음";
    }else if(foodName === "간장_와사비"){
      str = "와사비/간장";
    }

    let id = 0;
    for (let index in likeAdultFoodList){
      if (foodName === likeAdultFoodList[index].trim() || str === likeAdultFoodList[index].trim()){
        id = 1;
        initialSelected.push(
            {
              id: id,
              src: store.state.FOOD_URL + foodName + '.jpg',
              alt: str
            }
        )
        break;
      }
    }

    foodTableList.push({
      id: id,
      src: store.state.FOOD_URL+foodName+'.jpg',
      alt: str,
    })

  })

  return {
    foodTableList : foodTableList,
    initialSelected : initialSelected
  }

}


async function heightChart(respData){

  let height = {
    p3: [],
    p50: [],
    p97: [],
  }

  let xAxisData = [];
  let isMarkPoint = false;

  //let resDataGrowHeightLen = res.data.data.growHeightList.length || 0;
  //샘플(개인) 키 성장 처리 마지막 데이터는 다르게 ...(마크 포인트)
  // mark point height
  let growHeightList = [];
  respData.growHeightList.reduce((acc, key) => {

    let obj = {
      name: key.height,
      xAxis: (key.month > 226)?"227" : key.month + '',
      yAxis: key.height,
      value: key.height,
      symbol: "pin",
      symbolSize: 25,//(resDataGrowHeightLen === (index + 1))?15: 10,
      itemStyle: {
        color: "rgb(255,0,161)"//(resDataGrowHeightLen === (index + 1))?"rgb(47,0,255)":"rgb(0,134,28)"
      }
    }
    growHeightList.push(obj);

  }, 0)

  //2021-04-03 데이터 구조 변경, 질병관리청 도표 처리 기준으로 처리함.
  //TODO 해당 부분은 Code 별 공통 이므로 캐시 처리 필요
  //https://knhanes.cdc.go.kr/knhanes/sub08/sub08_03.do
  let growHeightPercentile = 0;
  respData.heightList.reduce((acc, key) => {

    if (respData.growHeightList[0] && respData.growHeightList[0].month ===
        key.month) {
      growHeightPercentile = percentile(respData.growHeightList[0],
          key.L, key.M, key.S);
      console.log("!jj height percentile !!!!!!!!!!!!",
          respData.growHeightList[0], growHeightPercentile,
          key.L, key.M, key.S);
    }

    xAxisData.push(key.month)

    // 키 시리즈 데이터 생성
    Object.keys(key).reduce((acc, keyName) => {
      switch (keyName) {
        case 'p3':
          height[keyName].push(key[keyName].toFixed(2));
          break;
        case 'p50':
          height[keyName].push(key[keyName].toFixed(2));
          break;
        case 'p97':
          height[keyName].push(key[keyName].toFixed(2));
          break;
      }

    }, {})
  }, {});

  // 키 시리즈 멀티 라인 생성
  let series = [];
  Object.keys(height).reduce((acc, key) => {

    let obj = {
      name: key,
      type: 'line',
      data: height[key],
      symbol: "circle",
      itemStyle: {
        color: "rgba(128, 128, 128, 1)"
      }
    }

    if (key === "p3") obj.itemStyle.color = "rgb(232,168,49)";
    if (key === "p50") obj.itemStyle.color = "rgb(22,172,22)";
    if (key === "p97") obj.itemStyle.color = "rgb(252,52,52)";

    //console.log("@sur" , survey.age * 12, survey.height);

    if (!isMarkPoint) {
      obj.markPoint = {
        label: {
          show: true,
          fontStyle: "italic",
          fontSize: 8
        },
        data: growHeightList,
      }
      isMarkPoint = true;
    }

    series.push(obj);
  }, {});

  return {
    isGrowth : true,
    heightPercentile : growHeightPercentile,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      //formatter: '{b0}: 111 {c0}<br />{b1}: {c1}'
      /*
                        formatter(obj){
                          o
                          return obj.name;
                        }
      */
    },
    grid: {
      left: '1%',
      right: '10%',
      bottom: '3%',
      top: '10%',
      containLabel: true
    },
    /*            toolbox: {
                  feature: {
                    saveAsImage: { show: true, name : '키성장', title : '이미지 다운로드' }
                  }
                },*/
    axisPointer: {
      label: {
        precision: 'auto'
      }
    },
    emphasis: {},
    xAxis: {
      type: 'category',
      //boundaryGap: false,
      //nameLocation: 'right',
      data: xAxisData,
      interval: 5,
      name: "개월"

    },
    yAxis: {
      type: 'value',
      max: 190,
      min: 30,
      //max: parseInt(((yAxisMax+(yAxisMax* 0.10))/10).toFixed(0) + 0) || 190,
      //min: parseInt(((yAxisMin-(yAxisMin* 0.10))/10).toFixed(0) + 0) || 30,
      interval: 10,
      name: "신장(cm)"

    },
    series: series
  }

  //this.$store.state.heightChartData = this.heightChartData;
  //this.isGrowth = true;
}


// 체중 성장 도표
async function weightChart(respData){

  let weight = {
    p5: [],
    p50: [],
    p95: [],
  }

  let isWeightMarkPoint = false;
  let xWeightAxisData = [];

  // mark point weight
  let growWeightList = [];
  let maxWeight = 0;
  respData.growWeightList.reduce( (acc, key)=> {
    maxWeight = (maxWeight < key.weight)?key.weight:maxWeight;
    let obj = {
      name: key.weight,
      xAxis: key.month +'',
      yAxis: key.weight,
      value : key.weight,
      symbol: "pin",
      symbolSize: 25,//(resDataGrowHeightLen === (index + 1))?15: 10,
      itemStyle: {
        color: "rgb(255,0,161)"
      }
    }
    growWeightList.push(obj);

  }, 0)

  let weightMaxValue = (respData.growWeightList.length === 0)?190:respData.growWeightList[respData.growWeightList.length - 1];
  let growWeightPercentile = 0;
  let sex = 'f';
  respData.weightList.reduce( (acc, key) => {

    // 체중 백분위수
    if( respData.growWeightList[0] && respData.growWeightList[0].month === key.month){
      growWeightPercentile = percentile(respData.growWeightList[0], key.L, key.M, key.S);
      console.log("!jj Weight percentile !!!!!", respData.growWeightList[0], growWeightPercentile,
          key.L, key.M, key.S);
    }

    if( Number(weightMaxValue.month) > 45 ){ //month 가 체중
      // 성장 데이터 보다 표준 데이터가 더 크면 제외
      //if( (Number(weightMaxValue.month) < (key.month - 20)) ) return;  // 데이터 제한
    }

    sex = key.sex;
    xWeightAxisData.push(key.month)
    // 체중 시리즈 데이터 생성
    Object.keys(key).reduce( (acc, keyName) =>{
      switch(keyName){
        case 'p5':
          weight[keyName].push(key[keyName].toFixed(2));
          break;
        case 'p50':
          weight[keyName].push(key[keyName].toFixed(2));
          break;
        case 'p95':
          weight[keyName].push(key[keyName].toFixed(2));
          break;
      }

    }, {})
  }, {});


  //let minValue = (growWeightList.length === 0)?150:growWeightList[growWeightList.length - 1];
  //채중 멀티 시리즈 생성
  let wSeries = [];
  Object.keys(weight).reduce( (acc, key) => {

    let obj  = {
      name: key,
      type: 'line',
      data: weight[key],
      symbol : "circle",
      itemStyle: {
        color: "rgba(128, 128, 128, 1)"
      }
    }

    if(key === "p5") obj.itemStyle.color = "rgb(232,168,49)";
    if(key === "p50") obj.itemStyle.color = "rgb(22,172,22)";
    if(key === "p95") obj.itemStyle.color = "rgb(252,52,52)";

    //console.log("@sur" , survey.age * 12, survey.height);


    if(!isWeightMarkPoint){
      obj.markPoint = {
        show: true,
        fontStyle: "italic",
        fontSize: 8,
        data: growWeightList,
      }
      isWeightMarkPoint = true;
    }

    wSeries.push(obj) ;
  }, {});


  //console.log( "jjj max " , (weightMaxValue.weight > 70)?90: (weightMaxValue.weight === 190)? 90 :( weightMaxValue.weight + 30) ) ;
  return {
    isGrowth : true,
    weightPercentile : growWeightPercentile,
    tooltip : {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      }
      /*
                        formatter(obj){
                          o
                          return obj.name;
                        }
      */
    },
    grid: {
      left: '1%',
      right: '10%',
      bottom: '3%',
      top: '10%',
      containLabel: true
    },
    /*            toolbox: {
                  feature: {
                    saveAsImage: { show: true, name : '체중', title : '이미지 다운로드' }
                  }
                },*/
    axisPointer: {
      label : {
        precision : 'auto'
      }
    },
    emphasis: {},
    xAxis: {
      type: 'category',
      //boundaryGap: false,
      //nameLocation: 'right',
      data : xWeightAxisData,
      interval: 10,
      name : "키(cm)"

    },
    yAxis: {
      type: 'value',
      //max: (weightMaxValue.weight > 70)?90: (weightMaxValue.weight === 190)? 90 : (weightMaxValue.month < 45)?90:( weightMaxValue.weight + 10),
      max: (maxWeight <= 0)?90:Math.round((maxWeight + 20)/10)* 10,//(sex === 'f')?80:90,
      min: 0,
      //max: parseInt(((yAxisMax+(yAxisMax* 0.10))/10).toFixed(0) + 0) || 190,
      //min: parseInt(((yAxisMin-(yAxisMin* 0.10))/10).toFixed(0) + 0) || 30,
      interval: 10,
      name: "체중(kg)"

    },
    series: wSeries
  }

}

/**
 * 백분위수
 *
 * @param obj
 * @param l
 * @param m
 * @param s
 * @returns {number}
 */
function percentile (obj, l, m, s){

  let value  = obj.height || obj.weight || 0;
  let ret = 0;

  if(l == 0){
    ret =  (Math.log(value/m) / s);
  }else{
    ret = (Math.pow((value/m), l)-1)/(l*s);
  }
  return Number((normsdist(ret.toFixed(4))*100).toFixed(1));

  function normsdist(data) {
    let Z=data;
    let M=0;
    let SD=1;
    let Prob;
    if (SD<0) {
      alert("The standard deviation must be nonnegative.")
    } else if (SD==0) {
      if (Z<M){
        Prob=0
      } else {
        Prob=1
      }
    } else {
      Prob=normalcdf((Z-M)/SD);
      Prob=Math.round(100000*Prob)/100000;
    }
    return  Prob;
  }

  function normalcdf(X){   //HASTINGS.  MAX ERROR = .000001
    let T=1/(1+.2316419*Math.abs(X));
    let D=.3989423*Math.exp(-X*X/2);
    let Prob=D*T*(.3193815+T*(-.3565638+T*(1.781478+T*(-1.821256+T*1.330274))));
    if (X>0) {
      Prob=1-Prob
    }
    return Prob
  }

}

/**
 * Trand 분석 > 샘플 비교 분석 시 사용
 *
 * Base 데이터 기준은 같은데 차트 데이터 생성 시  Code 별 개인 데이터 Serise 가 같이 포함 되어야 함
 * @param store
 * @param data
 * @param diversityKey
 * @returns {Promise<{chartData: {absenceRatio: number, yAxis: {scale: boolean}, xAxis: {scale: boolean}, series: [{data: *[], symbolSize: number, zlevel: number, type: string}, {data, type: string}], tooltip: {formatter: ((function(*): (string|undefined|*))|*), triggerOn: string, trigger: string}, absenceTotalCnt: number, title: {subtext: string, left: string}, absenceZeroCnt: number}, name, freqDistributionChartData: *}>}
 */
async function makeDiversityChartDataForCompare(code = '', data, diversityKey) {

  let codeDataList = [];
  let retObj = [];
  let codeValue = 0;
  let distributionList = [];// 도수 분포 처리를 위한 List

  let valueZeroCnt = 0;
  let valueTotalCnt = 0;
  let seriesData = data.reduce((acc, k) => {

    if (k.code === code) {
      if (k.value > 0) codeDataList.push([k.age, k.value, k.code]); //mark 을 위한 선별
      codeValue = k.value;
    }

    if (k.age >= 1) {
      valueTotalCnt++;
      if (k.value > 0) {
        distributionList.push(k.value);
        return [...acc, [k.age, k.value, k.code]]
      } else {
        valueZeroCnt = valueZeroCnt + 1;
      }
    }

    return acc;

  }, []);

  //총 sample 중에서 Value가 0인 sample의 확률
  let absenceRatio = 0;
  try {
    absenceRatio = ((valueZeroCnt / valueTotalCnt) * 100).toFixed(2);
  } catch (e) {
    console.log(e);
  }

  console.log("valueZeroCnt=%d, sampleTotal=%s, absenceRatio=%s", valueZeroCnt,
      valueTotalCnt, absenceRatio);

  let freqDistributionChartData = await _procFreqDistribution(10,
      distributionList, codeValue)

  retObj = {
    absenceRatio: absenceRatio,
    absenceTotalCnt: valueTotalCnt,
    absenceZeroCnt: valueZeroCnt,
    title: {
      subtext: `Absence Ratio: ${absenceRatio}%`,
      //top: 'bottom',
      left: 'center',
      //padding: [10, 0, 0, 0],
    },
    xAxis: {
      scale: true
    },
    yAxis: {
      scale: true
    },
    tooltip: {
      trigger: 'item',
      triggerOn: 'click',
      formatter: (p) => {
        if (p.data[2] === undefined) return 'none';
        if (store.state.focusPageNm === "report") return;
        if (store.state.code === p.data[2]) return p.data[2] || 'none';
        store.state.oldCode = store.state.code;
        store.state.code = p.data[2] || 0;

        console.log("Diversity tooltip CAll !!!!! %s", store.state.code)
        //EventBus.$emit('mainChartUpdate', this.$store.state.code);

        store.dispatch('getSample');

        return p.data[2] || 'none'
      }
    },
    series: [
      {
        type: 'effectScatter',
        symbolSize: 20,
        zlevel: 999,
        data: codeDataList
      }, {
        type: 'scatter',
        data: seriesData
      }]
  };

  return {
    name: diversityKey,
    chartData: retObj,
    freqDistributionChartData: freqDistributionChartData
  }

  /**
   * 도수 분포
   *
   * - 리포트 데이터는 별도로 Commit 처리
   *
   * @param section
   * @param distributionList Array
   * @param codeValue
   * @returns {{yAxis: {axisLabel: {formatter: string, verticalAlign: string}, axisLine: {lineStyle: {symbol: string, width: number, type: string}}, axisTick: {show: boolean}, type: string}, xAxis: {data: *[], axisTick: {show: boolean}, type: string}, color: string[], series: [{data: *[], type: string, markPoint: {data: [{coord: *[], name: string}], itemStyle: {color: string}}}]}}
   */
  async function _procFreqDistribution (section = 10, distributionList, codeValue) {

    if (distributionList.length <= 0) {
      console.log("[freqDistributionList] size zero")
      return {}
    }

    let max = Math.max(...distributionList);
    let min = Math.min(...distributionList);
    const interval = Number(((max - min) / section).toFixed(2));

    let ret = {}; //결과

    let isOnce = true; // 최초 원본 리스트 처리는 한번 만 ...
    let classList = []; //Reduce 구간 데이터 처리 버퍼

    for (let i = 1; i < (section + 1); i++) {
      if (isOnce) {
        classList = _proc(distributionList, i);
        isOnce = false;
      } else {
        classList = _proc(classList, i);
      }
    }

    //console.log("debug max=%s, min=%s, interval=%s", max, min, interval);
    //console.log("debug codeValue=%s", codeValue);


    //시리즈 데이터 생성
    let xAxisData = [];
    let seriesData = [];
    let markPoint = [];
    Object.keys(ret).reduce((acc, k, index) => {
      xAxisData.push(k);
      //console.log('[procFreqDistribution] section=%d, key=%s, value=%s, codeValue=%s, max=%d', section, k, ret[k], codeValue, max)

      let maxValue = Math.max(...ret[k]);
      let minValue = Math.min(...ret[k]);
      //console.log('debug key=%s, minValue=%d, maxValue=%d, codeValue=%s, list=%s', k, minValue, maxValue, codeValue, JSON.stringify(ret[k]))
      if ( codeValue >= minValue && codeValue <= maxValue ){
        if (codeValue !=0)markPoint = [index, ret[k].length];
      }

      if (section === 2){
        let len = Object.keys(ret).length;
        let value = max / len;
        console.log("[procFreqDistribution] value=%d, codeValue=%d", value, codeValue);
        if(codeValue >= value) markPoint = [index, ret[k].length];
      }
      //if (codeValue === 0 && index === 0) markPoint = [index, ret[k].length];
      seriesData.push({name: k, value: ret[k].length || 0});

    }, "")


    //console.log("seriesData %s", JSON.stringify(seriesData ) );

    return {
      color: ['rgb(64,140,255)'],
      title: {
        subtext: '※ 1세 이하 제외'
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
        axisTick: {
          show: false,
        },

      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: '',
          verticalAlign: 'middle',
        },
        axisLine: {
          lineStyle: {
            type: 'solid',
            width: 0,
            symbol: 'arrow',
          },
        },
        axisTick: {
          show: false,
        },

      },
      /*                    toolbox: {
                              feature: {
                                  saveAsImage: {show: true, name: 'Lukin Index', title: '이미지 다운로드'},
                              },
                          },*/
      series: [
        {
          data: seriesData,
          type: 'bar',
          markPoint: {
            data: [
              {
                name: 'coordinate',
                coord: markPoint,
              },
            ],
            itemStyle: {
              color: 'rgb(255, 0, 0, 0.7)',
            },
          },
        }],
    }

    function _proc (list, index) {
      // 구간별 최대 값
      let intervalMax = (min + (interval * index)).toFixed(1);
      if(!ret[intervalMax]) ret[intervalMax] = []; // 구간 값에 대한 키가 없으면 생성

      let cond = intervalMax;
      //if(index === 10) cond = intervalMax + min; //마지막 구간의 간격은 "간격 값 +

      return list.reduce((acc, k) => {
        if (k <= cond) {
          ret[intervalMax].push(k); // 범위는 해당 Array 에 Min/Max 산출 하면 됨.
        } else {
          return [...acc, k];
        }

        return acc;
      }, []);
    }}
}



// TODO 리턴 받던지 또는 Commit 하던지 처리 필요
// TODO 기본 통계 데이터는 동일 API 최초 1회 통신 방식으로 성능 개선 필요
async function makeDiversityChartData(store, data, diversityKey) {

  let codeDataList = [];
  let retObj = [];
  let codeValue = 0;
  let distributionList = [];// 도수 분포 처리를 위한 List

  // 별 그래프 차트에서 사용
  let starDiversity = {
    userScore: 0,
    maxScore: 0,
    minScore: 0
  }

  let valueZeroCnt = 0;
  let valueTotalCnt = 0;
  let seriesData = data.reduce((acc, k) => {

    if (k.code === store.state.code) {

      if(k.value > 0) codeDataList.push([k.age, k.value, k.code]); //mark 을 위한 선별
      codeValue = k.value;

      // 별차트 장미생물 연산을 위한 개인 값 저장
      if (diversityKey ===
          "byAgeDiversity") starDiversity.userScore = k.value;

    }

    starDiversity.maxScore = (starDiversity.maxScore > k.value)
        ? starDiversity.maxScore
        : k.value;
    if (starDiversity.minScore === 0) starDiversity.minScore = k.value;
    starDiversity.minScore = (starDiversity.minScore < k.value)
        ? starDiversity.minScore
        : k.value;

    if (k.age >= 1){
      valueTotalCnt++;
      if(k.value > 0){
        distributionList.push(k.value);
        return [...acc, [k.age, k.value, k.code]]
      }else {
        valueZeroCnt = valueZeroCnt + 1;
      }
    }

    return acc;

  }, []);

  //총 sample 중에서 Value가 0인 sample의 확률
  let absenceRatio = 0;
  try {
    absenceRatio = ((valueZeroCnt / valueTotalCnt) * 100).toFixed(2);
  }catch (e){
    console.log(e);
  }

  console.log("valueZeroCnt=%d, sampleTotal=%s, absenceRatio=%s", valueZeroCnt, valueTotalCnt, absenceRatio);

  // 여기 까지 작업 , 도수 분포 도 작업 진행 필요

  // 도수 분포 데이터 처리 TODO 리턴 받아서 도수 분포 차트 Commit 필요
  let freqDistributionChartData = await procFreqDistribution(10, distributionList, codeValue)

  retObj = {
    absenceRatio : absenceRatio,
    absenceTotalCnt : valueTotalCnt,
    absenceZeroCnt : valueZeroCnt,
    title: {
      subtext: `Absence Ratio: ${absenceRatio}%`,
      //top: 'bottom',
      left : 'center',
      //padding: [10, 0, 0, 0],
    },
    xAxis: {
      scale: true
    },
    yAxis: {
      scale: true
    },
    tooltip: {
      trigger: 'item',
      triggerOn: 'click',
      formatter: (p, t) => {
        console.log(p)
        if (p.data[2] === undefined) return 'none';
        if (store.state.focusPageNm === "report") return;
        if (store.state.code === p.data[2]) return p.data[2] || 'none';
        store.state.oldCode = store.state.code;
        store.state.code = p.data[2] || 0;

        console.log("Diversity tooltip CAll !!!!! %s", store.state.code)
        //EventBus.$emit('mainChartUpdate', this.$store.state.code);

        store.dispatch('getSample');

        return `${p.data[1].toFixed(2)}, ${p.data[2]}` || 'none'
      }
    },
    /*
                        toolbox: {
                            feature: {
                                saveAsImage: { show: true, name : '나이별 다양성 분포', title : '이미지 다운로드' }
                            }
                        },
    */
    series: [
      {
        type: 'effectScatter',
        symbolSize: 20,
        zlevel : 999,
        data: codeDataList
      }, {
        type: 'scatter',
        data: seriesData,
      }]
  };

  // 나이별 다양성 일때 Report 와 별차트 로우 데이터 필요로 별도 Commit 처리
  //console.log("zzz res key=%s", diversityKey );
  if (diversityKey === "byAgeDiversity") {
    //console.log("zzz res ret=%s", JSON.stringify(retObj));
    store.commit("setStarDiversity", starDiversity);
    store.commit("setByAgeDiversity", retObj)
    store.commit("setFreqDistributionListChart", freqDistributionChartData)
  }

  return {
    name: diversityKey,
    chartData: retObj,
    freqDistributionChartData : freqDistributionChartData,
    rawData : data,
    codeDataList : codeDataList
  }

  /**
   * 도수 분포
   *
   * - 리포트 데이터는 별도로 Commit 처리
   *
   * @param section
   * @param distributionList Array
   * @param codeValue
   * @returns {{yAxis: {axisLabel: {formatter: string, verticalAlign: string}, axisLine: {lineStyle: {symbol: string, width: number, type: string}}, axisTick: {show: boolean}, type: string}, xAxis: {data: *[], axisTick: {show: boolean}, type: string}, color: string[], series: [{data: *[], type: string, markPoint: {data: [{coord: *[], name: string}], itemStyle: {color: string}}}]}}
   */
  async function procFreqDistribution (section = 10, distributionList, codeValue) {

    if (distributionList.length <= 0) {
      console.log("[freqDistributionList] size zero")
      return {}
    }

    let max = Math.max(...distributionList);
    let min = Math.min(...distributionList);
    const interval = Number(((max - min) / section).toFixed(2));

    let ret = {}; //결과

    let isOnce = true; // 최초 원본 리스트 처리는 한번 만 ...
    let classList = []; //Reduce 구간 데이터 처리 버퍼

    for (let i = 1; i < (section + 1); i++) {
      if (isOnce) {
        classList = proc(distributionList, i);
        isOnce = false;
      } else {
        classList = proc(classList, i);
      }
    }

    //console.log("debug max=%s, min=%s, interval=%s", max, min, interval);
    //console.log("debug codeValue=%s", codeValue);


    //시리즈 데이터 생성
    let xAxisData = [];
    let seriesData = [];
    let markPoint = [];
    Object.keys(ret).reduce((acc, k, index) => {
      xAxisData.push(k);
      //console.log('[procFreqDistribution] section=%d, key=%s, value=%s, codeValue=%s, max=%d', section, k, ret[k], codeValue, max)

      let maxValue = Math.max(...ret[k]);
      let minValue = Math.min(...ret[k]);
      //console.log('debug key=%s, minValue=%d, maxValue=%d, codeValue=%s, list=%s', k, minValue, maxValue, codeValue, JSON.stringify(ret[k]))
      if ( codeValue >= minValue && codeValue <= maxValue ) {
          if (codeValue !=0) markPoint = [index, ret[k].length];
      }

      if (section === 2) {
        let len = Object.keys(ret).length;
        let value = max / len;
        console.log("[procFreqDistribution] value=%d, codeValue=%d", value, codeValue);
        if (codeValue >= value) markPoint = [index, ret[k].length];
      }
      //if (codeValue === 0 && index === 0) markPoint = [index, ret[k].length];
      seriesData.push({name: k, value: ret[k].length || 0});

    }, "")


    //console.log("seriesData %s", JSON.stringify(seriesData ) );

    return {
      color: ['rgb(64,140,255)'],
      title: {
        subtext: '※ 1세 이하 제외'
      },
      xAxis: {
        type: 'category',
        data: xAxisData,
        axisTick: {
          show: false,
        },

      },
      yAxis: {
        type: 'value',
        axisLabel: {
          formatter: '',
          verticalAlign: 'middle',
        },
        axisLine: {
          lineStyle: {
            type: 'solid',
            width: 0,
            symbol: 'arrow',
          },
        },
        axisTick: {
          show: false,
        },

      },
      /*                    toolbox: {
                              feature: {
                                  saveAsImage: {show: true, name: 'Lukin Index', title: '이미지 다운로드'},
                              },
                          },*/
      series: [
        {
          data: seriesData,
          type: 'bar',
          markPoint: {
            label : {
              show : true
            },
            data: [
              {
                symbolSize: 80,
                value : codeValue.toFixed(2),
                coord: markPoint,
              },
            ],
            itemStyle: {
              color: 'rgb(255, 0, 0, 0.7)',
            },
          },
        }],
    }

    function proc (list, index) {
      // 구간별 최대 값
      let intervalMax = (min + (interval * index)).toFixed(1);
      if(!ret[intervalMax]) ret[intervalMax] = []; // 구간 값에 대한 키가 없으면 생성

      let cond = intervalMax;
      //if(index === 10) cond = intervalMax + min; //마지막 구간의 간격은 "간격 값 +

      return list.reduce((acc, k) => {
        if (k <= cond) {
          ret[intervalMax].push(k); // 범위는 해당 Array 에 Min/Max 산출 하면 됨.
        } else {
          return [...acc, k];
        }

        return acc;
      }, []);
    }}
}



/**
 * 리포트::아기, 차트 데이터 생성
 *
 */
async function getBabyBSByType(store, data){

  let diversity = {
    min : store.state.starScore.diversity.minScore || 0,
    max : store.state.starScore.diversity.maxScore || 0,
    value : store.state.starScore.diversity.userScore || 0,
    name : "다양성"
  }

  console.log("#baby diversity>> ", diversity)

  let ret = {
    milk: getBabyBSChartData(data.milk),
    beneficial: getBabyBSChartData(data.beneficial),
    chance: getBabyBSChartData(data.chance),
    diversityByAge: getBabyBSChartData(diversity)
  }

  console.log("#baby >> ", ret)

  return ret;
}

/**
 * Report Baby Balance Summary Chart Data
 *
 * @param data
 */
function getBabyBSChartData(data){
  console.log("#baby ", (data.min + data.max) * 1.20) ;
  return {
    grid : {
      left: '15%'
    },
    xAxis: {
      type: 'category',
      data: ' '
    },
    yAxis: {
      type: 'value',
      splitLine: {show: false},
      /*
            axisLabel: {
              formatter: function(v){
                return v+' %';
              }
            }
      */
    },
    series: [{
      data: [data.min],
      stack: "food",
      type: 'bar',
      name: '1',
      itemStyle: {
        barBorderColor: 'rgba(0,0,0,0)',
        color: 'rgba(0,0,0,0)'
      }
    },
      {
        data: [data.max],
        stack: "food",
        type: 'bar',
        name: '2',
        itemStyle: {
          barBorderColor: '#9bca3c',
          color: '#9bca3c'
        },
        markPoint: {
          data: [
            {
              name: '1',
              xAxis: ' ',
              yAxis: data.value,
              symbolSize: 20,
              symbol: "diamond",
              itemStyle : {
                color: '#ff0000'
              }
            }
          ]
        }
      }
    ]
  };

}

/**
 * Phylum 분석
 *
 * TODO Now
 *
 * @param store
 * @param data
 * @returns {Promise<{yAxis: {scale: boolean}, xAxis: {scale: boolean}, series: [{data: [], symbolSize: number, type: string}, {data: *, type: string}], tooltip: {triggerOn: string, trigger: string}}>}
 */
async function getPhylumChartData(store, data) {
  let codeData = [];
  let maxValue = 0;
  let seriesData = data.reduce( (acc, k) => {
    if(k.code === store.state.code ){
      codeData.push([k.age, k.value, k.code]); //mark 을 위한 선별
    }
    maxValue = (maxValue > k.value)?maxValue:k.value;
    if(k.age >= 1) return [...acc, [k.age, k.value, k.code]]

    return acc;

  }, []);

  return {
    grid : {
      zlevel: 10,
      top: '8%',
      right: '8%',
      bottom: '10%',
    },
    xAxis: {
      scale: true
    },
    yAxis: {
      scale: true
    },
    tooltip : {
      trigger: 'item',
      triggerOn : 'click',
      formatter : (p) => {
        if(p.data[2] === undefined) return 'none';
        if(this.$store.state.focusPageNm === "report") return ;
        if(this.$store.state.code === p.data[2]) return p.data[2] || 'none';
        this.$store.state.oldCode = this.$store.state.code;
        this.$store.state.code = p.data[2] || 0;

        console.log("Diversity tooltip CAll !!!!! %s", this.$store.state.code)
        EventBus.$emit('mainChartUpdate', this.$store.state.code);

        return p.data[2] || 'none'
      }
    },
    series: [{
      type: 'effectScatter',
      symbolSize: 8,
      zlevel: 999,
      data: codeData
    }, {
      type: 'scatter',
      symbolSize: 5,
      data: seriesData
    }]
  };

}

/**
 * Genus 레벨의 나이별 다양성 스케터 차트 데이터로 변환
 *
 * @param code
 * @param data
 * @returns {{yAxis: {scale: boolean}, xAxis: {scale: boolean}, series: [{data: [], symbolSize: number, type: string}, {data: *, type: string}], tooltip: {formatter: (function(*): (string|*)), triggerOn: string, trigger: string}, toolbox: {feature: {saveAsImage: {show: boolean, name: string, title: string}}}}}
 */
async function getLactoGenusChartData(store, data){

  let codeData = [];
  let maxValue = 0;

  let seriesData = data.reduce( (acc, k) => {

    if(k.code === store.state.code ){
      codeData.push([k.age, k.value, k.code]); //mark 을 위한 선별
    }

    maxValue = (maxValue > k.value)?maxValue:k.value;
    if(k.age >= 1) return [...acc, [k.age, k.value, k.code]]

    return acc;

  }, []);

  return {
    grid: {
      top: '2%',
      left: '10%',
      right: '10%',
      bottom: '15%'
    },
    xAxis: {
      scale: true
    },
    yAxis: {
      scale: true
    },
    tooltip : {
      trigger: 'item',
      triggerOn : 'click',
      formatter : (p) => {
        if(p.data[2] === undefined) return 'none';
        if(store.state.code === p.data[2]) return p.data[2] || 'none';
        store.commit('setCode', p.data[2]);

        return p.data[2] || 'none'
      }
    },
    toolbox: {
      feature: {
        saveAsImage: { show: true, name : '나이별 다양성 분포', title : '이미지 다운로드' }
      }
    },
    series: [{
      type: 'effectScatter',
      symbolSize: 20,
      data: codeData
    }, {
      type: 'scatter',
      data: seriesData,
      itemStyle: {
        color: "rgba(0, 0, 0, 1)"
      }
    }]
  };
}

/**
 * 유산균 Species 분석 데이터 차트 데이터 생성
 *
 * @param speciesData
 * @returns {Promise<{yAxis: {splitArea: {show: boolean}, scale: boolean}, xAxis: {axisLabel: {rotate: number, fontSize: number}, min: string, data: [], axisLine: {onZero: boolean}, splitLine: {show: boolean}, scale: boolean, type: string, boundaryGap: boolean}, series: [{data: [], symbolSize: number, z: number, type: string}, {barWidth: string, data: [], itemStyle: {borderColor: string, borderColor0: string, color0: string, color: string}, type: string}], title: {left: number}}>}
 */
async function getLactoSpeciesDetailChart(speciesData, genusName){

  let color = (genusName ==='bifi')?'#c8ff02':'#FFD702';
  let upColor = color;
  let upBorderColor = 'rgba(255,255,255, 0)';
  let downColor = color;
  let downBorderColor = 'rgba(255,255,255, 0)';

  //max，min，lowest，highest
  let data0 = splitData(speciesData);

  //console.log("getLactoSpeciesDetailChart :" , JSON.stringify(data0));
  return {
    title: {
      left: 0
    },
    grid: {
      top: '2%',
      left: '3%',
      right: '2%',
      bottom: '16%'
    },
    xAxis: {
      type: 'category',
      data: data0.categoryData,
      scale: true,
      boundaryGap: true,
      axisLine: {onZero: false},
      splitLine: {show: false},
      //splitNumber: 20,
      min: 'dataMin',
      axisLabel : {
        rotate : 30,
        fontSize : 11
      },
      axisTick : {
        alignWithLabel : true
      }

    },
    yAxis: {
      scale: false,
      max : 10,
      min: 'dataMin',
      splitArea: {
        show: false
      }
    },

    series: [
      {
        type: 'effectScatter',
        symbolSize: 8,
        z: 999,
        data: data0.userData
      },
      {
        type: 'candlestick',
        data: data0.values,
        barWidth: '50%',
        itemStyle: {
          color: upColor,
          color0: downColor,
          borderColor: upBorderColor,
          borderColor0: downBorderColor
        },

      },


    ]
  };

  function splitData(rawData) {

   //console.log("splitData ", JSON.stringify(rawData) );
    let userDataList = rawData.userDataList;
    let minMaxList = rawData.minMaxList;
    let categoryData = [];
    let userCodeData = [];
    let values = [];
    for (let i = 0; i < minMaxList.length; i++) {
      categoryData.push(minMaxList[i].species);
      values.push([  minMaxList[i].max, minMaxList[i].min, 0, 0]);
    }

    for(let j= 0; j< userDataList.length; j++){
      if(userDataList[j].value <= 0) continue;
      userCodeData.push([ userDataList[j].species, userDataList[j].value ])
    }
    console.log(userCodeData)

    return {
      categoryData: categoryData,
      values: values,
      userData: userCodeData

    };
  }
}

/**
 * 종분석 데이터
 *
 * @param data
 * @returns {Promise<{tableData: {}}>}
 */
async function createSpeciesChartAndTableData(data){
  let speciesData = {
    tableData : {},
    indexChart : {},
    barLineChart : {}
  }

  let indexAvgData = data.indexData;
  // Index chart 데이터 생성 및 평균 Series 데이터 생성, 개인 데이터는 하위에서 처리
  Object.keys(indexAvgData).reduce((acc, key) => {

    let indexChartObj = getIndexChartData()
    //console.log(JSON.stringify(indexAvgData))

    indexChartObj.series[0].data.push(getIndexSeriesObj(indexAvgData[key].good))
    indexChartObj.series[1].data.push(getIndexSeriesObj(indexAvgData[key].bad))

    speciesData.indexChart[key] = indexChartObj
    //console.log("index", key);

  }, {})

  console.log("#report species ", speciesData )

  //종별 bar line chart data 및 테이블 데이터 생성
  Object.keys(data).reduce( (acc, key) => {

    //응답 데이터 규격에서 indexData는 Pass
    if(key === "indexData") return;

    let barLineChartData = getChartData(data[key]);
    speciesData.barLineChart[key] = barLineChartData
    speciesData.indexChart[key].series[0].data.push(getIndexSeriesObj(barLineChartData.goodIndex) ) ;
    speciesData.indexChart[key].series[1].data.push(getIndexSeriesObj(barLineChartData.badIndex)) ;

    speciesData.tableData[key] = [];
    let obj = data[key];

    //avgList 기준(평균 데이터 기준이며, 아래 for 문에서는 개인 데이터비교)
    obj.avgList.reduce( (acc, akey) => {
      //console.log(akey.orgSpecies);

      let tableObj = { Phylum: '', Genus:'', Specice : '', comment : '', bindo : 0, result : ''}
      tableObj.Phylum = akey.phylum;
      tableObj.Genus =  akey.genus;
      tableObj.Specice = akey.orgSpecies;
      tableObj.comment = akey.comment;
      tableObj.microbes = akey.microbes;
      tableObj.valueLog = akey.valueLog;
      tableObj.bindo = Math.round(akey.bindo) + '%';
      tableObj.isBad = false;
      tableObj.result ="결핍";

      // 음수 인 경우 유해균 으로 처리
      let signRet = Math.sign(akey.scoring) || 0;
      if(signRet < 0){
        tableObj.isBad = true;
        tableObj.result = "없음";
      }

      //개인 데이터에서 species 비교
      for(let i=0; i < obj.bar.length; i++){
        if(akey.orgSpecies === obj.bar[i].orgSpecies){

          let per = (obj.bar[i].value / akey.value) * 100  ;
          per = per.toFixed(2);

          //평균 대기 개인 데이터 비교 결과
          if(akey.value > obj.bar[i].value){ //개인데이터가 낮은 경우
            // 개인데이터가 낮을때 평균 보다 몇 퍼센트 낮은지 ...
            per = (100-per).toFixed(2);
          }

          //2020.10.28  유익균/유해균 기준이 scoring 음수 기준으로 바뀜 .
          if(tableObj.isBad){
            tableObj.result = getBadResultStr(per);
          }else {
            tableObj.result = getGoodResultStr(per);
          }

          //tableObj.result = (akey.value > obj.bar[i].value)? `부족(${(100-per).toFixed(2)}%)`: `높음(${per}%)`;
          break;
        }
      }

      speciesData.tableData[key].push(tableObj);


    }, {});

    //this.$refs[key].reload();
    //speciesBebAndBadChart(key, this.speciesTable.tableData[key]).then( () => {});
  }, {});
  console.log("#report return ", speciesData )
  return speciesData;

  function getBadResultStr(value) {
    switch(true) {
      case (value > 100) :
        return `과잉(${value}%)`;
      case ( value > 80 && value <= 120 ):
        return `주의(${value}%)`;
      case ( value <= 80 ):
        return `보통(${value}%)`;
    }
  }

  function getGoodResultStr(value) {
    switch(true) {
      case (value > 120) :
        return `과잉(${value}%)`;
      case ( value > 80 && value <= 120 ):
        return `적정(${value}%)`;
      case ( value <= 80 ):
        return `부족 (${value}%)`;
    }
  }

  /**
   * Bar Line 차트 데이터 생성
   * @param data
   * @returns {{badIndex: number, yAxis: [{axisLabel: {formatter: function(*): *}, min: number, max: string}], xAxis: [], color: [string, string, string], legend: {}, series: [], tooltip: {axisPointer: {crossStyle: {color: string}, type: string}, trigger: string}, toolbox: {feature: {saveAsImage: {show: boolean, title: string}}}, goodIndex: number}}
   */
  function getChartData(data){
    let barGoodList = [];
    let barBadList = [];
    let goodIndex = 0;
    let badIndex = 0 ;
    //console.log("!!!" , data);
    data.bar.forEach( ele => {
      let isGood = (Math.sign(ele.scoring) > 0)?true:false;
      let obj = {
        value: [ele.species, ele.value],
        itemStyle: {
          // barBorderColor: this.getMicrobesColor(ele.microbes),
          color: (isGood)?'rgb(64, 140, 255)' : 'rgb(239, 76, 93)',
          borderWidth: 2
        },
        label : {
          show: true,
          //color: this.getMicrobesColor(ele.microbes),
          name: ele.species,
          position: 'top',
          formatter: '{b}'
        }
      }

      if(isGood){
        barGoodList.push(obj);
        goodIndex += ele.valueLog;
      }else {
        barBadList.push(obj);
        badIndex += (ele.valueLog * -1);
      }
    })

    let xAxis = [];
    xAxis.push({
      type: 'category',
      data: data.xAxis,
      axisPointer: {
        type: 'shadow'
      },
      axisLabel : {
        show : false,
        rotate : 80,
        showMaxLabel: true,
        showMinLabel : true,
        fontSize : 7
      }
    })

    let series = [];
    series.push({
      name: 'GOOD',
      type: 'bar',
      data: barGoodList,
    })

    series.push({
      name: 'BAD',
      type: 'bar',
      data: barBadList,
    })

    series.push({
      name: 'AVG',
      type: 'line',
      yAxisIndex: 0,
      smooth: 0.6,
      itemStyle: {
        borderWidth: 1,
      },
      data: data.line,
    })

    return {
      grid : {
        left: '15%',
        right: '5%'
      },
      color: ['rgb(64, 140, 255)', 'rgb(239, 76, 93)', 'rgb(0, 0, 0)'],
      tooltip: {
        trigger: 'axis',
        axisPointer: {
          type: 'cross',
          crossStyle: {
            color: '#999'
          }
        }
      },
      /*
              toolbox: {
                feature: {
                  //magicType: {show: true, type: ['line', 'bar'], title: ""},
                  saveAsImage: {show: true, title: "다운로드"}
                }
              },
      */
      legend: {

      },
      yAxis: [
        {
          //type: 'log',
          //name: '',
          min: 1,
          max: 'dataMax',
          //interval: 50,
          axisLabel: {
            formatter: function(v){
              return v.toExponential(0);
            }
          }
        }
      ],
      series : series,
      xAxis : xAxis,
      badIndex : badIndex,
      goodIndex : goodIndex
    }
  }

  /**
   * Index Chart Data 생성
   *
   * @returns {{barWidth: string, yAxis: {axisLabel: {formatter: string, verticalAlign: string}, axisLine: {lineStyle: {symbol: string, width: number, type: string}}, show: boolean, axisTick: {show: boolean}, scale: boolean, splitNumber: number, type: string}, xAxis: [{axisLabel: {}, data: [string, string], axisTick: {show: boolean}, type: string}], color: [string, string, string], legend: {data: [string, string]}, series: [{data: [], barGap: number, name: string, type: string}, {data: [], name: string, type: string}], toolbox: {feature: {saveAsImage: {show: boolean, name: string, title: string}}}, label: {formatter: (function(*): *), show: boolean, position: string}}}
   */
  function getIndexChartData(){
    return {
      color: ['rgb(64, 140, 255)', 'rgb(239, 76, 93)', 'rgb(0, 0, 0)'],
      barWidth : '30%',
      xAxis: [ {
        type: 'category',
        data: ["전체 평균", "나의 농도"],
        axisTick : {
          show : false
        },
        axisLabel:{

        },

      } ],
      yAxis: {
        show : true,
        type: 'value',
        /*                      min:  function (value) {
                                  console.log(this)
                                  return value.min ;
                              },
                              max:  function (value) {
                                  let max = parseFloat(value.max) + parseFloat(value.max * 0.3);
                                  return (parseFloat(max) + parseFloat( (10 - (max % 10)).toFixed(1) )) ;
                              },*/
        scale : false,
        splitNumber : 5,
        //interval : si0,
        axisLabel:{
          formatter: '',
          verticalAlign : 'middle'
        },
        axisLine : {
          lineStyle : {
            type : 'solid',
            width : 0,
            symbol: 'arrow'
          }
        },
        axisTick : {
          show : false
        }

      },
      /*      toolbox: {
              feature: {
                saveAsImage: { show: true, name : 'Lukin Index', title : '이미지 다운로드' }
              }
            },*/
      legend: {
        data: ['GOOD', 'BAD']
      },
      label :  {
        show: true,
        formatter: (a) => {

          return a.value.toFixed(2);
        } ,
        position: 'top'
      },
      series: [
        {
          name : 'GOOD',
          data: [],
          barGap: 0,
          type: 'bar'
        },
        {
          name : 'BAD',
          data: [],
          type: 'bar'
        },

      ],
    }
  }


  /**
   *  Index Chart Series 생성
   * @param value
   * @returns {{itemStyle: {color: string, borderWidth: number, barBorderRadius: number[]}, label: {position: string}, value: *}}
   */
  function getIndexSeriesObj(value){
    let obj =  {
      value: value,
      itemStyle : {
        color : "rgb(239, 76, 93)",
        borderWidth: 0,
        barBorderRadius: [5, 5, 0, 0]
      },
      label : { position :'top' }
    }

    if(Math.sign(value ) > 0){
      obj.name = "good";
      obj.itemStyle.color = "rgb(64, 140, 255)";
    }else {
      obj.name = "bad";
      obj.label.position = 'bottom';
      obj.itemStyle.barBorderRadius = [0, 0, 5, 5]
    }

    return obj;

  }
}